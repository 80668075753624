
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './EmployeeComponent.css'; // Ensure this file has the correct styles
import { useNavigate } from 'react-router-dom';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';

function Permission({ userId }) {
  const [permissions, setPermissions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedModule, setExpandedModule] = useState(null); // Track which module is expanded
  const navigate = useNavigate();

  const handleCancel = () => {
    console.log('Cancel button clicked');
    navigate(-1); // Navigate to the last screen
  };


  useEffect(() => {
    const fetchPermissions = async () => {
      const token = localStorage.getItem('authToken'); // Get the token from local storage

      if (!token) {
        setError('No token found. Please log in.');
        return;
      }

      const apiUrl = `https://api.myntask.com/rolepermission/get_permission_by_user/${userId}`; // The API URL with the user ID
      console.log('Fetched userId:', userId); // Log userId to ensure it's passed correctly

      try {
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });


        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        console.log('API Response:', data); // Log the response data for debugging
        setPermissions(data); // Save the fetched data in the state
        setIsLoading(false); // Set the loading state to false
      } catch (error) {
        console.error('Error fetching permissions:', error);
        setError(error.message);
        setIsLoading(false); // Set the loading state to false
      }
    };




    fetchPermissions();
  }, [userId]); // Dependency array includes userId to refetch data when the userId changes




  // Function to handle dropdown changes and update state
  const handlePermissionChange = (moduleKey, action, selectedValue, subAction = null) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = { ...prevPermissions };




      // If subAction is provided, handle the "more" section
      if (subAction) {
        if (
          updatedPermissions[moduleKey] &&
          updatedPermissions[moduleKey].more &&
          updatedPermissions[moduleKey].more[subAction]
        ) {
          updatedPermissions[moduleKey].more[subAction].selected = selectedValue;
        } else {
          console.error(`Unable to update permission: ${moduleKey} -> more -> ${subAction}`);
        }
      } else {
        // Regular permission handling
        if (updatedPermissions[moduleKey] && updatedPermissions[moduleKey][action]) {
          updatedPermissions[moduleKey][action].selected = selectedValue;
        } else {
          console.error(`Unable to update permission: ${moduleKey} -> ${action}`);
        }
      }

      return updatedPermissions;
    });
  };




  const handleSave = async () => {
    console.log('Save button clicked');
    const token = localStorage.getItem('authToken'); // Get the token from local storage

    if (!token) {
      setError('No token found. Please log in.');
      toast.error('No token found. Please log in.');
      return;
    }

    // Prepare the permissions array for the API request
    const permissionsArray = Object.keys(permissions).flatMap((moduleKey) => {
      const mainPermissions = ['add', 'view', 'update', 'delete'].map((action) => ({
        permission_id: permissions[moduleKey][action]?.permission_id,
        permission_type_id: permissions[moduleKey][action]?.selected,
      }));


      // Collect permissions from the "more" section
      const morePermissions = permissions[moduleKey]?.more
        ? Object.keys(permissions[moduleKey].more).map((subAction) => ({
          permission_id: permissions[moduleKey].more[subAction]?.permission_id,
          permission_type_id: permissions[moduleKey].more[subAction]?.selected,
        }))
        : [];


      return [...mainPermissions, ...morePermissions];
    });


    const payload = {
      user_id: userId, // Pass userId
      permissions: permissionsArray.filter((p) => p.permission_id != null), // Filter out null permission_id
    };


    console.log('Saving permissions payload:', payload);
    console.log('API Endpoint:', 'https://api.myntask.com/rolepermission/user_wise_permission_save');
    setIsLoading(true); // Set loading state to true while saving permissions


    try {
      const response = await fetch(
        'https://api.myntask.com/rolepermission/user_wise_permission_save',
        {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );


      if (!response.ok) {
        throw new Error(`API Error: ${response.status}`);
      }


      const data = await response.json();
      console.log('Save Response:', data); // Log the response for debugging


      // Show success toast notification
      if (data.success) {
        toast.success(data.message || 'Permissions saved successfully');
      } else {
        toast.error(data.message || 'Failed to save permissions');
      }
      setIsLoading(false); // Set loading state to false after saving permissions
    } catch (error) {
      console.error('Error saving permissions:', error);
      setError(error.message);
      toast.error('Failed to save permissions. Please try again.');
    }
  };



  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }


  // Extract keys and values from the permissions object
  const permissionKeys = Object.keys(permissions);




  const toggleExpand = (module) => {
    setExpandedModule(expandedModule === module ? null : module); // Toggle expansion
  };




  return (
    <div className="permissions-container">




      <table className="permissions-table">
        <thead>
          <tr>
            <th>Module</th>
            <th>Add</th>
            <th>View</th>
            <th>Update</th>
            <th>Delete</th>
            <th>More</th>
          </tr>
        </thead>
        <tbody>
          {permissionKeys.map((key) => (
            <React.Fragment key={key}>
              <tr>
                <td>{key.charAt(0).toUpperCase() + key.slice(1)}</td>
                {['add', 'view', 'update', 'delete'].map((action) => (
                  <td key={action}>
                    {permissions[key][action] && (
                      <select
                        value={permissions[key][action].selected}
                        onChange={(e) => handlePermissionChange(key, action, e.target.value)}
                      >
                        {Object.entries(permissions[key][action].permissions).map(([optionKey, optionValue]) => (
                          <option key={optionKey} value={optionValue}>
                            {optionKey.charAt(0).toUpperCase() + optionKey.slice(1)}
                          </option>
                        ))}
                      </select>
                    )}
                  </td>
                ))}
                <td>
                  <button className="more-button" onClick={() => toggleExpand(key)}>
                    {expandedModule === key ? 'Less' : 'More'}
                  </button>
                </td>
              </tr>
              {expandedModule === key && permissions[key]?.more && (
                <tr>
                  <td colSpan="6">
                    <div className="expanded-content">
                      {Object.entries(permissions[key].more).map(([subAction, options], index) => (
                        <div key={index} className="expanded-row">
                          <strong>{subAction}:</strong>
                          <select
                            value={options.selected}
                            onChange={(e) => handlePermissionChange(key, null, e.target.value, subAction)}
                          >
                            {Object.entries(options.permissions).map(([optionKey, optionValue]) => (
                              <option key={optionKey} value={optionValue}>
                                {optionKey.charAt(0).toUpperCase() + optionKey.slice(1)}
                              </option>
                            ))}
                          </select>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>





      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <button style={buttonStyle} onClick={handleSave}>
          Save
        </button>
        <button style={buttonStyle} onClick={handleCancel}>
          Cancel
        </button>
      </div>




      {/* Toast Container */}
      <ToastContainer />
    </div>
  );
}




export default Permission;




const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '16px',
};












