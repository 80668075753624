import React, { useState, useEffect } from 'react';
import './css/MessageScreen.css';
import iconImage from '../assets/Icon.png';
import './css/Designation.css';
import { Box, Modal, Stack, Typography } from '@mui/material';
import { Vortex } from 'react-loader-spinner';


function Message() {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState('');
  const [messageText, setMessageText] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [members, setMembers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);



  // Fetch conversation list and set up auto-refresh
  useEffect(() => {
    const fetchConversations = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error('No auth token found');
        return;
      }
      try {
        const response = await fetch('https://api.myntask.com/message/conversation_list', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch conversations');
        }
        const data = await response.json();
        setConversations(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching conversations:', error);
        setError("Failed to Fetch Conversations");
        setIsLoading(false);
      }
    };




    fetchConversations();




    // Set up an interval to refresh the conversation list every 5 seconds
    const conversationIntervalId = setInterval(() => {
      fetchConversations();
    }, 1000);




    // Cleanup interval on component unmount
    return () => clearInterval(conversationIntervalId);
  }, []);




  // Fetch user chat messages when a conversation is selected and set up auto-refresh
  useEffect(() => {
    if (selectedConversation) {
      const fetchUserChat = async () => {
        const token = localStorage.getItem('authToken');
        if (!token) {
          console.error('No auth token found');
          return;
        }




        try {
          console.log(`Fetching chat for user with ID: ${selectedConversation.id}`);




          const response = await fetch(`https://api.myntask.com/message/user_chat/${selectedConversation.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });




          if (!response.ok) {
            throw new Error('Failed to fetch user chat');
          }




          const data = await response.json();
          console.log('Chat data:', data); // Log the chat data to verify




          setMessages(data); // Assuming `data` contains the messages array
        } catch (error) {
          console.error('Error fetching user chat:', error);
        }
      };




      fetchUserChat();




      // Set up an interval to refresh the user chat every 5 seconds
      const chatIntervalId = setInterval(() => {
        fetchUserChat();
      }, 1000);




      // Cleanup interval on component unmount or when the selected conversation changes
      return () => clearInterval(chatIntervalId);
    }
  }, [selectedConversation]);




  const handleConversationClick = (conversation) => {
    setSelectedConversation(conversation);
  };




  const handleSendMessage = async () => {
    if (newMessage.trim() === '' && !selectedFile) {
      console.error('Message or file is required to send');
      return;
    }




    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error('No auth token found');
      return;
    }




    const formData = new FormData();
    formData.append('member_id', selectedConversation.id); // Use the selected conversation's ID
    formData.append('message', newMessage);
    if (selectedFile) {
      formData.append('file', selectedFile);
    }




    try {
      const response = await fetch('https://api.myntask.com/message/conversation_save', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });




      if (!response.ok) {
        throw new Error('Failed to send message');
      }




      const result = await response.json();
      console.log('Message sent:', result);




      // Optionally, add the new message to the messages list
      setMessages([
        ...messages,
        { id: result.id, text: newMessage, sender: 'me', file: result.file }
      ]);




      // Clear input fields after sending
      setNewMessage('');
      setSelectedFile(null);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };




  const stripHtmlTags = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  };




  const handleNewConversation = async () => {
    setIsModalOpen(true);
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error('No auth token found');
      return;
    }
    try {
      const response = await fetch('https://api.myntask.com/employee/member', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch members');
      }
      const data = await response.json();
      console.log('API response:', data); // Debugging: log the API response
      setMembers(data);
      console.log('Members state:', data); // Debugging: log the state after setting it
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };




  const handleModalClose = () => {
    setIsModalOpen(false);
  };




  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };




  const handleFormSubmit = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error('No auth token found');
      return;
    }




    const formData = new FormData();
    formData.append('member_id', selectedMember);
    formData.append('message', messageText);
    if (selectedFile) {
      formData.append('file', selectedFile);
    }




    try {
      const response = await fetch('https://api.myntask.com/message/conversation_save', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });




      if (!response.ok) {
        throw new Error('Failed to save conversation');
      }




      const result = await response.json();
      console.log('Save response:', result); // Log the result of the save operation




      // Close modal after submission
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error saving conversation:', error);
    }
  };




  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }





  return (
    <div className="chat-screen">
      <div className="conversation-list">
        <input
          type="text"
          placeholder="Type to search contact"
          className="search-input"
        />
        {conversations.map((conversation) => (
          <div
            key={conversation.id}
            className={`conversation-item ${selectedConversation?.id === conversation.id ? "active" : ""
              }`}
            onClick={() => handleConversationClick(conversation)}
          >
            <img
              src={conversation.user_image || "default-avatar.png"}
              alt={conversation.name}
              className="conversation-avatar"
            />
            <div className="conversation-info">
              <div className="conversation-name">{conversation.name}</div>
              <div className="conversation-message">
                {stripHtmlTags(conversation.message)}
              </div>
            </div>
            <div className="conversation-time">1 hour ago</div>
          </div>
        ))}
      </div>




      <div className="messages-container">
        <div className="message-header">
          {selectedConversation ? (
            <>
              <img
                src={selectedConversation.user_image || "default-avatar.png"}
                alt={selectedConversation.name}
                className="selected-avatar"
              />
              <span>{selectedConversation.name}</span>
            </>
          ) : (
            <span>- Select a conversation to send a message -</span>
          )}
          <button
            className="new-conversation-button"
            onClick={handleNewConversation}
          >
            + New Conversation
          </button>
        </div>




        {selectedConversation ? (
          <>
            <div className="messages-list">
              {messages.map((message) => (
                <div
                  key={message.chat_id}
                  className={`message ${message.name === "me" ? "my-message" : "other-message"
                    }`}
                  style={{ marginBottom: "20px" }} // Add margin for spacing between messages
                >
                  {/* Display name on top */}
                  <p style={{ fontWeight: "bold", margin: 0 }}>
                    {message.name}
                  </p>




                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "10px",
                      marginTop: '5px'
                    }}
                  >
                    {/* Display user image before the message content */}
                    <img
                      src={message.user_image}
                      alt={message.name}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                      }}
                    />




                    {/* Message content with conditional attachment link */}
                    <div style={{ flex: 1 }}>
                      <p style={{ margin: 0 }}
                        dangerouslySetInnerHTML={{ __html: message.message }}
                      />
                      {message.file && (
                        <a
                          href={message.file}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Attachment
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>




            <div className="input-container">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Write your message here"
                className='input'
              />
              <div className="send-options">
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="upload-file-input"
                />
                <button onClick={handleSendMessage}>Send</button>
              </div>
            </div>
          </>
        ) : (
          <div className="no-conversation-selected">
            - Select a conversation to send a message -
          </div>
        )}
      </div>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box className="designation-modal">
          {/* Modal Header */}
          <div className="designation-header">
            <h1 className="designation-title">New Conversation</h1>
            <img
              src={iconImage} // Replace with the correct image path
              alt="Close"
              className="designation-close"
              onClick={() => setIsModalOpen(false)}
            />
          </div>

          {/* Form Fields */}
          <div className="designation-form">
            {/* Choose Member Field */}
            <label className="designation-label">Choose Member *</label>
            <select
              value={selectedMember}
              onChange={(e) => setSelectedMember(e.target.value)}
              className="designation-input"
            >
              <option value="">--</option>
              {members && members.length > 0 ? (
                members.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.member_name}
                  </option>
                ))
              ) : (
                <option value="">No members available</option>
              )}
            </select>

            {/* Message Field */}
            <label className="designation-label">Message *</label>
            <textarea
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
              placeholder="Enter your message"
              className="designation-input"
            ></textarea>

            {/* Add File Field */}
            <label className="designation-label">Add File</label>
            <input
              type="file"
              onChange={handleFileChange}
              className="designation-input"
            />
          </div>

          <button className="designation-save" onClick={handleFormSubmit}>
            Send
          </button>

        </Box>
      </Modal>

      {/* {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>New Conversation</h2>
            <div className="form-group">
              <label>Choose Member *</label>
              <select
                value={selectedMember}
                onChange={(e) => setSelectedMember(e.target.value)}
              >
                <option value="">--</option>
                {members && members.length > 0 ? (
                  members.map((member) => (
                    <option key={member.id} value={member.id}>
                      {member.member_name}
                    </option>
                  ))
                ) : (
                  <option value="">No members available</option>
                )}
              </select>
            </div>
            <div className="form-group">
              <label>Message *</label>
              <textarea
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
              ></textarea>
            </div>
            <div className="form-group">
              <label>Add File</label>
              <input type="file" onChange={handleFileChange} />
            </div>
            <div className="modal-actions">
              <button onClick={handleModalClose}>Cancel</button>
              <button onClick={handleFormSubmit}>Send</button>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
}




export default Message;














