import React, { useState, useEffect } from 'react';
import { Pie, Doughnut, Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';


const statusColors = {
  'in progress': '#42a5f5', // Blue for 'in progress'
  completed: '#4caf50', // Green for 'completed'
  delayed: '#f44336', // Red for 'delayed'
};

// Reusable component for card-like containers
const CardContainer = ({ children, title, reducedHeight }) => (
  <div
    style={{
      padding: reducedHeight ? '16px' : '24px',
      borderRadius: '8px',
      textAlign: 'center',
      height: reducedHeight ? '100%' : 'auto',
      minHeight: reducedHeight ? '250px' : '350px', // Adjusted height for compact view
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      backgroundColor: 'white',
      marginBottom: '16px',
    }}
  >
    {title && (
      <h3 style={{ marginBottom: '16px', fontWeight: 'bold', color: '#333', fontSize: '1.75rem' }}>
        {title}
      </h3>
    )}
    {children}
  </div>
);

const ProjectStatus = ({ currentStatus }) => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '16px' }}>
    <div
      style={{
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: statusColors[currentStatus],
        marginRight: '8px',
      }}
    />
    <span style={{ color: statusColors[currentStatus], fontWeight: 'bold' }}>
      {currentStatus.charAt(0).toUpperCase() + currentStatus.slice(1)}
    </span>
  </div>
);

const Overview = ({ projectId, projectName }) => {
  const [overviewData, setOverviewData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOverviewData = async () => {
      const token = localStorage.getItem('authToken');
      const url = `https://api.myntask.com/project/overview/${projectId}`;

      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch overview data');
        }

        const data = await response.json();
        setOverviewData(data[0]);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchOverviewData();
  }, [projectId]);



  //..........Loading Spinner ..........................................

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  const taskData = {
    labels: ['Incomplete', 'To Do', 'Doing', 'Completed'],
    datasets: [
      {
        data: [
          overviewData.task_status_wise.incomplete,
          overviewData.task_status_wise.to_do,
          overviewData.task_status_wise.doing,
          overviewData.task_status_wise.completed,
        ],
        backgroundColor: ['#f44336', '#ffeb3b', '#2196f3', '#4caf50'],
        hoverBackgroundColor: ['#d32f2f', '#fbc02d', '#1976d2', '#388e3c'],
      },
    ],
  };

  const budgetData = {
    labels: ['Planned', 'Actual'],
    datasets: [
      {
        label: 'Project Budget',
        data: [overviewData.project_budget, overviewData.expenses],
        backgroundColor: ['#4caf50', '#f44336'],
      },
    ],
  };

  return (
    <div style={{ padding: '10px 30px' }}>
      {/* Breadcrumb and Title */}

      <div className="top-controls">
        <h1 className='title'>{projectName} - Overview
          <div className="underline"></div>
        </h1>
      </div>
      {/* <div style={{ marginTop: '8px' }}>
          <a href="/" style={{ textDecoration: 'none', color: '#1976d2' }}>Home</a> &gt; 
          <a href="/project" style={{ textDecoration: 'none', color: '#1976d2', marginLeft: '8px' }}>Projects</a> &gt;
          <span style={{ color: '#333', marginLeft: '8px' }}>{overviewData.project_name}</span>
        </div> */}


      <div>
        {/* Project Status */}
        <ProjectStatus currentStatus={overviewData.status} />

        {/* Grid Layout */}
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
          {/* Project Progress */}
          <div style={{ flex: '1 1 calc(50% - 16px)' }}>
            <CardContainer title="Project Progress" reducedHeight>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '8px' }}>
                <Doughnut
                  data={{
                    labels: ['Progress'],
                    datasets: [
                      {
                        data: [overviewData.completion_percentage, 100 - overviewData.completion_percentage],
                        backgroundColor: ['#4caf50', '#e0e0e0'],
                      },
                    ],
                  }}
                  options={{
                    cutout: '60%',
                    aspectRatio: 2, // Adjusted aspect ratio for a smaller chart
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    maintainAspectRatio: false,
                  }}
                  style={{
                    height: '150px', // Reduced height
                    width: '150px',  // Reduced width
                  }}
                />
              </div>
              <p style={{ fontWeight: 'bold' }}>Completion: {overviewData.completion_percentage}%</p>
              <p>Start Date: {overviewData.start_date}</p>
              <p>Deadline: {overviewData.deadline !== "Invalid date" ? overviewData.deadline : "No deadline set"}</p>
            </CardContainer>

          </div>

          {/* Client Information */}
          <div style={{ flex: '1 1 calc(50% - 16px)' }}>
            <CardContainer title="Client" reducedHeight>
              <img
                src={overviewData.clients[0].client_image}
                alt={overviewData.clients[0].client_name}
                style={{ width: '56px', height: '56px', borderRadius: '50%', margin: '10px auto' }}
              />
              <h4 style={{ marginTop: '8px', fontWeight: 'bold' }}>{overviewData.clients[0].client_name}</h4>
              <p>Rajasthan, India</p>
              <button style={{ marginTop: '16px', padding: '8px 16px', borderRadius: '4px', border: '1px solid #1976d2', color: '#1976d2', backgroundColor: 'transparent', cursor: 'pointer' }}>Message</button>
            </CardContainer>
          </div>

          {/* Task Distribution */}
          <div style={{ flex: '1 1 calc(50% - 16px)' }}>
            <CardContainer title="Tasks">
              <Pie data={taskData} style={{ height: '200px', width: '200px', margin: '0 auto' }} />
            </CardContainer>
          </div>

          {/* Statistics */}
          <div style={{ flex: '1 1 calc(50% - 16px)' }}>
            <CardContainer title="Statistics">
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                {['Project Budget', 'Hours Logged', 'Earnings', 'Expenses', 'Profit'].map((stat, index) => (
                  <div key={index} style={{ flex: '1 1 calc(50% - 8px)' }}>
                    <p style={{ fontWeight: 'bold', color: '#555' }}>{stat}</p>
                    <h4 style={{ color: '#1976d2', fontWeight: '700' }}>
                      ₹{
                        stat === 'Project Budget' ? overviewData.project_budget :
                          stat === 'Hours Logged' ? overviewData.hours_logged :
                            stat === 'Earnings' ? overviewData.earnings :
                              stat === 'Expenses' ? overviewData.expenses :
                                overviewData.profit
                      }
                    </h4>
                  </div>
                ))}
              </div>
            </CardContainer>
          </div>

          {/* Hours Logged and Project Budget Charts */}
          <div style={{ flex: '1 1 calc(50% - 16px)' }}>
            <CardContainer title="Hours Logged">
              <Bar
                data={{
                  labels: ['Planned', 'Actual'],
                  datasets: [
                    {
                      label: 'Hours',
                      data: [5, overviewData.hours_logged],
                      backgroundColor: ['#4caf50', '#f44336'],
                    },
                  ],
                }}
                options={{
                  scales: {
                    y: {
                      beginAtZero: true,
                      max: 5,
                    },
                  },
                }}
              />
            </CardContainer>
          </div>

          <div style={{ flex: '1 1 calc(50% - 16px)' }}>
            <CardContainer title="Project Budget">
              <Bar
                data={budgetData}
                options={{
                  scales: {
                    y: {
                      beginAtZero: true,
                    },
                  },
                }}
              />
            </CardContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
