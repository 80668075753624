import React, { useState, useEffect } from "react";
import { Vortex } from 'react-loader-spinner';
import {
  Box,
  Stack,
} from '@mui/material';




const UserPerformance = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(null); // Track the open dropdown


  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("authToken");
      const url = "https://api.myntask.com/home/all_member_task_points";


      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });


        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }


        const result = await response.json();
        setData(result);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };


    fetchData();
  }, []);


  const toggleDropdown = (userId) => {
    setDropdownOpen((prev) => (prev === userId ? null : userId));
  };


  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  return (
    <div className="main-container">
      <h1>User Performance</h1>


      <table >
        <thead>
          <tr>
            <th >User</th>
            <th >
              Total Points
            </th>
            <th >
              Earned Points
            </th>
            <th >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((user) => (
            <React.Fragment key={user.user_id}>
              <tr>
                <td >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={user.image}
                      alt={user.user_name}
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                    />
                    {user.user_name}
                  </div>
                </td>
                <td >
                  {user.total_task_points}
                </td>
                <td >
                  {user.earned_task_points}
                </td>
                <td >
                  <button
                    onClick={() => toggleDropdown(user.user_id)}
                    style={{
                      padding: "5px 10px",
                      background: "#007bff",
                      color: "#fff",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    Task List
                  </button>
                </td>
              </tr>
              {dropdownOpen === user.user_id && (
                <tr>
                  <td
                    colSpan={4}
                    style={{
                      border: "1px solid #ccc",
                      padding: "10px",
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <div
                      style={{
                        maxHeight: "300px",
                        overflowY: "auto",
                        border: "1px solid #ddd",
                        padding: "10px",
                      }}
                    >
                      <table

                      >
                        <thead>
                          <tr>
                            <th
                            >
                              Task Name
                            </th>
                            <th
                            >
                              Start Date
                            </th>
                            <th
                            >
                              Due Date
                            </th>
                            <th
                            >
                              Points
                            </th>
                            <th
                            >
                              Overdue
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {user.tasks.map((task, index) => (
                            <tr key={index}>
                              <td >
                                {task.name}
                              </td>
                              <td >
                                {task.start_date}
                              </td>
                              <td >
                                {task.due_date}
                              </td>
                              <td >
                                {task.points || "N/A"}
                              </td>
                              <td >
                                {task.is_overdue ? "Yes" : "No"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};


export default UserPerformance;





