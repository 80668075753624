import React, { useState, useEffect } from 'react';
import './EstimateForm.css';
import Swal from 'sweetalert2';
import { Box, Modal, Stack, Typography } from "@mui/material";
import { Vortex } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

const EstimateForm = () => {
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [clients, setClients] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubcategory, setSelectedSubcategory] = useState("");
    const [selectedProduct, setSelectedProduct] = useState("");
    const [selectedClient, setSelectedClient] = useState("");
    const [rows, setRows] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalTax, setTotalTax] = useState(0);
    const [productDetails, setProductDetails] = useState(null);
    const [loadingProduct, setLoadingProduct] = useState(false);
    const [estimateNumber, setEstimateNumber] = useState('');


    const token = localStorage.getItem('authToken');
    const navigate = useNavigate();
    useEffect(() => {
        const fetchCategories = async () => {
            console.log('Fetching categories...');
            try {
                const response = await fetch('https://api.myntask.com/product/category', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const data = await response.json();
                console.log('Categories fetched:', data);
                setCategories(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, [token]);

    useEffect(() => {
        const fetchSubcategories = async () => {
            console.log('Fetching subcategories for category:', selectedCategory);
            if (selectedCategory) {
                try {
                    const response = await fetch(
                        `https://api.myntask.com/product/sub_category/${selectedCategory}`,
                        { headers: { Authorization: `Bearer ${token}` } }
                    );
                    const data = await response.json();
                    console.log('Subcategories fetched:', data);
                    setSubcategories(data);
                } catch (error) {
                    console.error('Error fetching subcategories:', error);
                }
            }
        };
        fetchSubcategories();
    }, [selectedCategory, token]);

    useEffect(() => {
        const fetchProducts = async () => {
            console.log('Fetching products for category and subcategory:', selectedCategory, selectedSubcategory);
            if (selectedCategory && selectedSubcategory) {
                try {
                    const response = await fetch(
                        `https://api.myntask.com/product/product_dropdown?category_id=${selectedCategory}&subcategory_id=${selectedSubcategory}`,
                        { headers: { Authorization: `Bearer ${token}` } }
                    );
                    const data = await response.json();
                    console.log('Products fetched:', data);
                    setProducts(data);
                } catch (error) {
                    console.error('Error fetching products:', error);
                }
            }
        };
        fetchProducts();
    }, [selectedCategory, selectedSubcategory, token]);

    useEffect(() => {
        const fetchClients = async () => {
            console.log('Fetching clients...');
            try {
                const response = await fetch('https://api.myntask.com/project/client', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const data = await response.json();
                console.log('Clients fetched:', data);
                setClients(data);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };
        fetchClients();
    }, [token]);

    const fetchProductDetails = async (productId) => {
        console.log('Fetching product details for product ID:', productId);
        setLoadingProduct(true);
        try {
            const response = await fetch(
                `https://api.myntask.com/purchase_order/product_dropdown_info/${productId}`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            const data = await response.json();
            console.log('Product details fetched:', data);

            if (data && data.length > 0) {
                setProductDetails(data[0]);
            } else {
                console.error('No product data available');
            }
        } catch (error) {
            console.error('Error fetching product details:', error);
        } finally {
            setLoadingProduct(false); // Stop loading
        }
    };

    const handleProductSelect = (productId) => {
        console.log('Product selected:', productId);
        setSelectedProduct(productId);
        fetchProductDetails(productId);
    };

    const addProductRow = () => {
        console.log('Adding product row with details:', productDetails);
        if (!productDetails) {
            alert('Please select a product.');
            return;
        }

        const unitPrice = parseFloat(productDetails.purchase_price) || 0;
        const qty = 1;
        const taxPercentage = productDetails.taxes && productDetails.taxes.length > 0
            ? parseFloat(productDetails.taxes[0].split('@')[1])
            : 0;

        const newRow = {
            id: rows.length + 1,
            product: productDetails.name,
            description: productDetails.description || '',
            qty,
            unitPrice,
            discount: 0,
            tax: taxPercentage,
            taxAmount: (qty * unitPrice * (taxPercentage / 100)),
            totalAmount: (qty * unitPrice) + (qty * unitPrice * (taxPercentage / 100)),
        };
        setRows([...rows, newRow]);
        calculateSummary([...rows, newRow]);
        setSelectedProduct('');
        setProductDetails(null);
    };

    const addEmptyRow = () => {
        console.log('Adding empty row...');
        const newRow = {
            id: rows.length + 1,
            product: '',
            description: '',
            qty: 1,
            unitPrice: 0.0,
            discount: 0,
            tax: 0,
            taxAmount: 0,
            totalAmount: 0,
        };
        setRows([...rows, newRow]);
    };

    const deleteRow = (id) => {
        console.log('Deleting row with ID:', id);
        const updatedRows = rows.filter((row) => row.id !== id).map((row, index) => ({
            ...row,
            id: index + 1,
        }));
        setRows(updatedRows);
        // Recalculate the summary
        calculateSummary(updatedRows);
    };

    // Handle Input Changes
    const handleRowChange = (id, field, value) => {
        const updatedRows = rows.map((row) => {
            if (row.id === id) {
                // Safely parse the input fields and assign defaults
                const qty = field === "qty" ? parseFloat(value) || 0 : parseFloat(row.qty) || 0;
                const unitPrice = field === "unitPrice" ? parseFloat(value) || 0 : parseFloat(row.unitPrice) || 0;
                const discount = field === "discount" ? parseFloat(value) || 0 : parseFloat(row.discount) || 0;
                // const tax = parseFloat(row.tax) || 0; // Default to 0 if not a number
                const tax = field === "tax" ? parseFloat(value) || 0 : parseFloat(row.tax) || 0;

                // Calculate the discounted price per item
                const discountedPrice = unitPrice * (1 - discount / 100);

                // Calculate the tax amount
                const taxAmount = (tax / 100) * (discountedPrice * qty);

                // Calculate the total amount
                const totalAmount = discountedPrice * qty + taxAmount;

                return {
                    ...row,
                    [field]: value, // Update the specific field
                    taxAmount: Number.isFinite(taxAmount) ? taxAmount : 0, // Ensure taxAmount is a number
                    totalAmount: Number.isFinite(totalAmount) ? totalAmount : 0, // Ensure totalAmount is a number
                };
            }
            return row; // No changes for other rows
        });

        setRows(updatedRows);
        calculateSummary(updatedRows); // Update summary totals
    };



    const calculateSummary = (rowsData) => {
        console.log('Calculating summary for rows:', rowsData);
        const total = rowsData.reduce((sum, row) => sum + row.totalAmount, 0);
        const tax = rowsData.reduce((sum, row) => sum + row.taxAmount, 0);
        setTotalAmount(total);
        setTotalTax(tax);
    };

    const handleSave = async () => {
        console.log('Saving estimate...');
        const items = rows.map((row) => ({
            item_name: row.product || '',
            quantity: parseFloat(row.qty) || 0,
            product_id: selectedProduct || null,
            hsn_code: 'HSN001',
            unit_price: parseFloat(row.unitPrice) || 0,
            unit_id: 3,
            item_summary: row.description || '',
            amount: parseFloat(row.totalAmount) || 0,
            tax_id: ['1', '2'],
        }));

        const estimateData = {
            estimate_number: estimateNumber,
            currency_id: 33,
            client_id: parseInt(selectedClient) || null,
            valid_till: '2024-07-29',
            description: 'Estimate Description',
            calculate_tax: 'after_discount',
            note: 'Additional notes',
            sub_total: rows.reduce((sum, row) => sum + parseFloat(row.unitPrice) * parseFloat(row.qty), 0),
            total: totalAmount,
            discount: '1.00',
            discount_type: 'percent',
            items,
        };

        // Log each field (for debugging purposes)
        console.log('Estimate Number:', estimateData.estimate_number);
        console.log('Currency ID:', estimateData.currency_id);
        console.log('Client ID:', estimateData.client_id);
        console.log('Valid Till:', estimateData.valid_till);
        console.log('Description:', estimateData.description);
        console.log('Calculate Tax:', estimateData.calculate_tax);
        console.log('Note:', estimateData.note);
        console.log('Sub Total:', estimateData.sub_total);
        console.log('Total:', estimateData.total);
        console.log('Discount:', estimateData.discount);
        console.log('Discount Type:', estimateData.discount_type);
        console.log('Items:', estimateData.items);


        try {
            const response = await fetch('https://api.myntask.com/estimate/save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(estimateData),
            });

            if (response.ok) {
                console.log('Estimate saved successfully.');
                Swal.fire('Success', 'Estimate saved successfully!', 'success');
                navigate('/estimates');
                resetForm();

            } else {
                console.error('Failed to save estimate.');
                Swal.fire('Error', 'Failed to save estimate.', 'error');
            }
        } catch (error) {
            console.error('Error saving estimate:', error);
            Swal.fire('Error', 'An error occurred while saving.', 'error');
        }
    };

    const resetForm = () => {
        console.log('Resetting form...');
        setSelectedClient('');
        setSelectedCategory('');
        setSelectedSubcategory('');
        setSelectedProduct('');
        setRows([]);
        setTotalAmount(0);
        setTotalTax(0);
        setProductDetails(null);
    };

    return (
        <div className="add-order-screen">
            <h2>Estimate Page</h2>
            <form>
                {/* <div className="form-container"> */}
                <div className="form-row">
                    <div className="formgroup">
                        <label>Estimate No:</label>
                        <input className='expenseInput' type="text" value={estimateNumber} onChange={(e) => setEstimateNumber(e.target.value)} />
                    </div>
                    <div className="formgroup">
                        <label>Client Name:</label>
                        <select onChange={(e) => setSelectedClient(e.target.value)} value={selectedClient} className='expenseInput'>
                            <option value="">Select Client</option>
                            {clients.map((client) => (
                                <option key={client.client_id} value={client.client_id}>
                                    {client.client_name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="form-row">
                    <div className="formgroup">
                        <label>Billing Address:</label>
                        <textarea rows="3">123 Sample Street, City, State</textarea>
                    </div>
                    <div className="formgroup">
                        <label>Date:</label>
                        <input className='expenseInput' type="date" defaultValue={new Date().toISOString().split("T")[0]} />
                    </div>
                </div>

                <div className="form-row">
                    <div className="formgroup">
                        <label>Category:</label>
                        <select className='expenseInput' onChange={(e) => setSelectedCategory(e.target.value)} value={selectedCategory}>
                            <option value="">Select Category</option>
                            {categories.map((cat) => (
                                <option key={cat.id} value={cat.id}>
                                    {cat.category_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="formgroup">
                        <label>Subcategory:</label>
                        <select className='expenseInput' onChange={(e) => setSelectedSubcategory(e.target.value)} value={selectedSubcategory}>
                            <option value="">Select Subcategory</option>
                            {subcategories.map((sub) => (
                                <option key={sub.id} value={sub.id}>
                                    {sub.category_name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="form-row">
                    <div className="formgroup">
                        <label>Product:</label>
                        <select className='expenseInput' onChange={(e) => handleProductSelect(e.target.value)} value={selectedProduct}>
                            <option value="">Select Product</option>
                            {products.map((prod) => (
                                <option key={prod.id} value={prod.id}>
                                    {prod.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* </div> */}
                <div className="est-actions">
                    <button
                        className='color-button'
                        type="button"
                        onClick={addProductRow}
                        disabled={!productDetails || loadingProduct} // Disable button during loading
                    >
                        {loadingProduct ? "Loading..." : "Add Product"}
                    </button>
                    <button type="button" className='color-button' onClick={addEmptyRow}>
                        + Add Empty Row
                    </button>
                </div>
            </form>

            <table className='estimate-table'>
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Item Name</th>
                        <th>Description</th>
                        <th>Qty</th>
                        <th>Unit Price</th>
                        <th>Discount (%)</th>
                        <th>Tax (%)</th>
                        <th>Tax Amount</th>
                        <th>Amount</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody className='estimate-body'>
                    {rows.map((row) => (
                        <tr key={row.id}>
                            <td>{row.id}</td>
                            <td contentEditable={row.product === ""}>{row.product}</td>
                            <td>
                                <textarea
                                    className='estimateInput'
                                    rows="1"
                                    value={row.description}
                                    onChange={(e) => handleRowChange(row.id, "description", e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    className='estimateInput'
                                    type="number"
                                    value={row.qty}
                                    onChange={(e) => handleRowChange(row.id, "qty", parseFloat(e.target.value))}
                                />
                            </td>
                            <td>
                                <input
                                    className='estimateInput'
                                    type="number"
                                    value={row.unitPrice}
                                    onChange={(e) => handleRowChange(row.id, "unitPrice", parseFloat(e.target.value))}
                                />
                            </td>
                            <td>
                                <input
                                    className='estimateInput'
                                    type="number"
                                    value={row.discount}
                                    onChange={(e) => handleRowChange(row.id, "discount", parseFloat(e.target.value))}
                                />
                            </td>
                            <td>
                                <input
                                    className='estimateInput'
                                    type="number"
                                    value={row.tax} // Display the tax percentage
                                    onChange={(e) => handleRowChange(row.id, "tax", parseFloat(e.target.value))}
                                />
                            </td>
                            <td>{row.taxAmount.toFixed(2)}</td>
                            <td>{row.totalAmount.toFixed(2)}</td>
                            <td>
                                <button onClick={() => deleteRow(row.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="estimate-summary">
                <div>
                    <label>Total Tax :</label> {totalTax.toFixed(2)}
                </div>
                <div>
                    <label>Total Amount :</label> {totalAmount.toFixed(2)}
                </div>
            </div>


            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '40px', gap: '20px' }}>
                <button type="button" className='color-button' onClick={resetForm}>
                    Cancel
                </button>
                <button type="button" className='color-button' onClick={handleSave}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default EstimateForm;
