
import React, { useEffect, useRef, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import { useNavigate } from 'react-router-dom';
import TaskDetail from '../Components/TaskDetail';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import './../Components/Main.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './../Components/Main.css';
import { Avatar, Tooltip } from '@mui/material';
import defaultAvatar from '../assets/vector.jpeg';
import Swal from 'sweetalert2';
import { Box, Modal, Stack } from '@mui/material';
import designationImage from '../assets/designation.png';
import iconImage from '../assets/Icon.png';
import './css/Designation.css';
import { Vortex } from 'react-loader-spinner';
import zIndex from '@mui/material/styles/zIndex';


const PAGE_SIZE = 15; // Number of tasks per page

const Task = () => {
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [action, setAction] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [actionMenuVisible, setActionMenuVisible] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchData, setFetchData] = useState(false); // State to trigger useEffect
  const [isOpen, setIsOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [userProfile, setUserProfile] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [fetchMyTasks, setFetchMyTasks] = useState(false); // New state to track My Task button
  const [startDate, setStartDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const actionMenuRef = useRef(null);
  const [holdModalOpen, setHoldModalOpen] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const [currentTaskId, setCurrentTaskId] = React.useState(null);


  useEffect(() => {
    const storedProfile = JSON.parse(localStorage.getItem('userProfile')) || {};
    setUserProfile(storedProfile);
  }, []);


  const userId = userProfile.user_id;

  const handleDownloadPdf = () => {
    const doc = new jsPDF();
    const tableColumn = ['Project', 'Task', 'Start Date', 'Due Date', 'Status', 'Points', 'Assigned To'];
    const tableRows = [];
    tasks.forEach(task => {
      const assignedUsers = task.assigned_users.map(user => user.name).join(', ');
      const taskData = [
        task.project_name || 'N/A',
        truncateText(task.task, 6) || 'N/A',  // Use truncateText here
        task.start_date || 'N/A',
        task.due_date || 'N/A',
        task.status || 'N/A',
        task.task_points || 'N/A',
        assignedUsers || 'N/A',
      ];
      tableRows.push(taskData);
    });
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });
    doc.save('tasks_table.pdf');
  };

  // Function to handle click on 'Hold' status and open the modal
  const handleHoldClick = (taskId) => {
    setHoldModalOpen(true); // Set the state to open the modal
    setCurrentTaskId(taskId); // Store the task ID to use it in the modal
  };

  // Function to close the modal
  const handleHoldModalClose = () => {
    setHoldModalOpen(false); // Set the state to close the modal
    setCurrentTaskId(null); // Clear the task ID when closing the modal
  };

  // Function to save the reason
  const handleSaveHoldDetails = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'No token found. Please log in.',
        timer: 1500,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });
      return;
    }

    try {
      // Send hold reason to the API
      const holdReasonResponse = await fetch('https://api.myntask.com/task/hold_reason', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          task_id: currentTaskId,
          hold_reason: reason,
        }),
      });

      if (!holdReasonResponse.ok) {
        throw new Error('Failed to save hold reason');
      }

      // Send status update to the API
      const statusOption = statusOptions.find(option => option.status === 'Hold');
      if (!statusOption) {
        throw new Error('Invalid hold status');
      }

      const statusResponse = await fetch('https://api.myntask.com/task/task_status_update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          task_id: currentTaskId,
          status_id: statusOption.id,
        }),
      });

      if (!statusResponse.ok) {
        throw new Error('Failed to update task status');
      }

      const updatedTasks = tasks.map(task =>
        task.task_id === currentTaskId ? { ...task, status: 'Hold' } : task
      );
      setTasks(updatedTasks);
      setFilteredTasks(updatedTasks);

      Swal.fire({
        icon: 'success',
        title: 'Hold Details Saved',
        text: 'Hold reason and status successfully updated.',
        timer: 1500,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });

      // Close the modal
      handleHoldModalClose();
    } catch (error) {
      console.error('Error saving hold details:', error);
      setError(error.message);
      Swal.fire({
        icon: 'error',
        title: 'Save Failed',
        text: 'Failed to save hold details. Please try again.',
        timer: 1500,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });
    }
  };

  // Fetch project list from the API
  useEffect(() => {
    const fetchProject = async () => {
      setLoading(true);
      setError(null);
      try {
        // Retrieve the token from local storage
        const token = localStorage.getItem('authToken');
        // Check if the token is available
        if (!token) {
          throw new Error('Authorization token is missing');
        }
        const response = await fetch('https://api.myntask.com/task/project_list', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        // Handle the response
        if (!response.ok) {
          throw new Error(`Failed to fetch project list: ${response.statusText}`);
        }
        const data = await response.json();
        setProjects(data);
        console.log('Project List Data**********************************:', data);
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        console.error('Error fetching project list:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, []); // Run only once on component mount


  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };


  const handleSubmit = async () => {
    if (!selectedFile || !selectedProject) {
      Swal.fire({
        icon: 'error',
        title: 'Missing Information',
        text: 'Please select a file and a project before submitting.',
        timer: 1500,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
        didOpen: () => {
          // Append SweetAlert to the body (outside of modal)
          const swalContainer = document.querySelector('.swal2-container');
          if (swalContainer) {
            document.body.appendChild(swalContainer);
            swalContainer.style.zIndex = '2000'; // Ensure it's above modal
          }
        },
      });
      return;
    }
    const token = localStorage.getItem('authToken'); // Replace with your token key
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('project_id', selectedProject);
    // Log the data being sent
    console.log('Data being sent:', {
      file: selectedFile.name, // Log the file name for clarity
      project_id: selectedProject,
    });
    try {
      const response = await fetch('https://api.myntask.com/project/task-import', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      const responseData = await response.json();
      console.log('Response from server:', responseData);
      if (!response.ok) {
        throw new Error(`Error: ${responseData.message || 'Upload failed'}`);
      }
      // Show SweetAlert success notification
      Swal.fire({
        icon: 'success',
        title: 'Upload Successful',
        text: 'The file was successfully uploaded.',
        timer: 1500,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });
      close(); // Close the modal after submission
    } catch (error) {
      console.error('Error during file upload:', error);
      // Show SweetAlert error notification
      Swal.fire({
        icon: 'error',
        title: 'Upload Failed',
        text: error.message || 'An error occurred while uploading the file.',
        timer: 1500,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });
    }
  };

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
    setSelectedFile(null); // Reset file selection
    setSelectedProject(''); // Reset project selection
  };

  const fetchTaskTemplate = async () => {
    if (fetchData) {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch('https://api.myntask.com/project/task-template', {
          method: 'GET',
          headers: {
            // Add any required headers here if necessary
          }
        });
        if (!response.ok) {
          throw new Error('Failed to download file');
        }
        // Create a blob from the response
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        // Set the download attribute with the correct file extension for Excel
        link.setAttribute('download', 'task-template.xlsx'); // Use .xls if it's an older Excel file
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url); // Clean up the URL objec
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };


  useEffect(() => {
    fetchTaskTemplate();
  }, [fetchData]); // Effect runs when fetchData changes


  const handleDownloadClick = () => {
    setFetchData(true); // Set the flag to trigger the useEffect
  };


  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(' ') + '...';
  };


  // Fetch tasks with filters
  useEffect(() => {
    const fetchTasks = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }
      console.log('status id is ', selectedStatus);

      let apiUrl = `https://api.myntask.com/task/list?start_date=${startDate}&due_date=${dueDate}&status_id=${selectedStatus}`;
      // Add userId parameter if fetching "My Tasks"
      if (fetchMyTasks) {
        apiUrl = `https://api.myntask.com/task/list/${userId}?start_date=${startDate}&due_date=${dueDate}&status_id=${selectedStatus}`; // Ensure userId is appended when fetching My Tasks
      }
      // Log the API URL to check if the parameters are correct
      console.log("Fetching tasks from:", apiUrl);
      try {
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch tasks');
        }
        const data = await response.json();
        // Log the response to ensure you are getting the data you expect
        console.log("Fetched data*************************************:", data);
        setTasks(data);
        setFilteredTasks(data); // Ensure `filteredTasks` is updated here
        setTotalPages(Math.ceil(data.length / PAGE_SIZE));
      } catch (error) {
        console.error('Error fetching tasks:', error);
        setError(error.message);
      }
    };
    fetchTasks();
  }, [startDate, dueDate, selectedStatus, fetchMyTasks, userId]); // Refetch when filters change


  // Toggle "My Task" state
  const handleMyTaskClick = () => {
    setFetchMyTasks(!fetchMyTasks); // Toggle between all tasks and my tasks
  };


  useEffect(() => {
    const fetchStatusOptions = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }
      try {
        const response = await fetch('https://api.myntask.com/task/task_status', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch status options');
        }
        const data = await response.json();
        setStatusOptions(data);
      } catch (error) {
        console.error('Error fetching status options:', error);
        setError(error.message);
      }
    };

    fetchStatusOptions();
  }, []);


  const handleStatusChange = async (taskId, newStatus) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'No token found. Please log in.',
        timer: 1500,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });
      return;
    }
    const statusOption = statusOptions.find(option => option.status === newStatus);
    if (!statusOption) {
      setError('Invalid status selected');
      Swal.fire({
        icon: 'error',
        title: 'Invalid Status',
        text: 'The selected status is not valid.',
        timer: 1500,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });
      return;
    }

    if (newStatus === 'Hold') {
      handleHoldClick(taskId);
      return;
    }

    try {
      const response = await fetch('https://api.myntask.com/task/task_status_update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          task_id: taskId,
          status_id: statusOption.id,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to update status');
      }
      const updatedTasks = tasks.map(task =>
        task.task_id === taskId ? { ...task, status: newStatus } : task
      );
      setTasks(updatedTasks);
      setFilteredTasks(updatedTasks);
      // Show SweetAlert success notification
      Swal.fire({
        icon: 'success',
        title: 'Status Updated',
        text: `Task status successfully updated to "${newStatus}".`,
        timer: 1500,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });
    } catch (error) {
      console.error('Error updating status:', error);
      setError(error.message);
      // Show SweetAlert error notification
      Swal.fire({
        icon: 'error',
        title: 'Update Failed',
        text: 'Failed to update the task status. Please try again.',
        timer: 1500,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });
    }
  };


  const handleAddTaskClick = () => {
    // console.log("Add Task button clicked");
    navigate('/new-task'); // Navigate to New Task screen
  };


  const handleEditTaskClick = () => {
    if (selectedTasks.length === 1) {
      const taskId = selectedTasks[0];
      navigate(`/edit-task/${taskId}`);
    } else {
      setError('Please select exactly one task to edit');
    }
  };
  // Updated delete function to handle both single and multiple task IDs
  const handleDeleteTask = async (taskIdOrIds) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'No token found',
      });
      return;
    }
    // Determine if input is a single ID or an array of IDs
    const taskIds = Array.isArray(taskIdOrIds) ? taskIdOrIds : [taskIdOrIds];
    // SweetAlert confirmation dialog
    const confirmDelete = await Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    });
    if (!confirmDelete.isConfirmed) return;
    try {
      for (const taskId of taskIds) {
        const response = await fetch('https://api.myntask.com/task/delete', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ task_id: taskId }),
        });
        if (!response.ok) {
          throw new Error(`Failed to delete task with ID ${taskId}`);
        }
      }
      // Remove the deleted tasks from the list
      const updatedTasks = tasks.filter((task) => !taskIds.includes(task.task_id));
      setTasks(updatedTasks);
      setFilteredTasks(updatedTasks);
      setSelectedTasks([]);
      setTotalPages(Math.ceil(updatedTasks.length / PAGE_SIZE));

      // SweetAlert success notification
      Swal.fire({
        title: "Deleted!",
        text: "Your task have been deleted.",
        icon: "success",
      });
    } catch (error) {
      console.error('Error deleting tasks:', error);
      setError(error.message);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to delete task.',
      });
    }
  };


  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = tasks.filter(task =>
      (task.code?.toLowerCase() || '').includes(query) ||
      (task.task?.toLowerCase() || '').includes(query) ||
      (task.start_date?.toLowerCase() || '').includes(query) ||
      (task.due_date?.toLowerCase() || '').includes(query) ||
      (task.status?.toLowerCase() || '').includes(query) ||
      (task.task_points?.toString().toLowerCase() || '').includes(query) ||
      task.assigned_users?.some(user =>
        (user.name?.toLowerCase() || '').includes(query)
      )
    );
    setFilteredTasks(filtered);
    setCurrentPage(1);
    setTotalPages(Math.ceil(filtered.length / PAGE_SIZE));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleActionChange = (event) => {
    setAction(event.target.value);
  };

  // Handle bulk action
  const applyAction = async () => {
    if (action === 'edit') {
      handleEditTaskClick();
    } else if (action === 'delete') {
      if (selectedTasks.length > 0) {
        await handleDeleteTask(selectedTasks); // Pass array of taskIds to delete function
      } else {
        setError('Please select at least one task to delete');
      }
    }
  };


  // Handle checkbox selection
  const handleCheckboxChange = (taskId) => {
    setSelectedTasks((prevState) =>
      prevState.includes(taskId)
        ? prevState.filter((id) => id !== taskId)
        : [...prevState, taskId]
    );
  };


  const paginatedTasks = filteredTasks.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );
  // Ensure the total pages are calculated after filtering
  useEffect(() => {
    setTotalPages(Math.ceil(filteredTasks.length / PAGE_SIZE));
  }, [filteredTasks]);

  // Handle the case where the currentPage becomes greater than the total pages
  useEffect(() => {
    if (currentPage > totalPages && totalPages > 0) {
      setCurrentPage(totalPages);
    }
  }, [totalPages, currentPage]);
  // console.log('Paginated tasks:', paginatedTasks);

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTaskId(null);
  };

  // Navigate to the task detail page with the task ID
  const handleTaskNameClick = (taskId) => {
    navigate(`/task-detail/${taskId}`);
  };


  // Toggle the action menu visibility for a specific task
  const toggleActionMenu = (taskId) => {
    setActionMenuVisible((prevState) => ({
      ...prevState,
      [taskId]: !prevState[taskId],
    }));
  };

  // Handle action menu clicks for Edit and Delete actions
  const handleActionMenuClick = (actionType, taskId) => {
    if (actionType === 'edit') {
      setActionMenuVisible((prevState) => ({
        ...prevState,
        [taskId]: false,
      }));
      navigate(`/edit-task/${taskId}`);
    } else if (actionType === 'delete') {
      setActionMenuVisible((prevState) => ({
        ...prevState,
        [taskId]: false,
      }));
      handleDeleteTask(taskId); // Pass single taskId to delete function
    }
    setActionMenuVisible((prevState) => ({
      ...prevState,
      [taskId]: false,
    }));
  };


  // Close action menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the action menu
      if (actionMenuRef.current && !actionMenuRef.current.contains(event.target)) {
        setActionMenuVisible({});
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [actionMenuRef]);


  const statusColors = {
    "Incomplete": "red",
    "To Do": "blue",
    "Doing": "orange",
    "Completed": "green",
    "Hold": "yellow"
  };


  const capitalizeFirstLetter = (str) => {
    return str ? str.toLowerCase().charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '';
  };


  const getPriorityColor = (priority) => {
    switch (priority.toLowerCase()) {
      case 'high':
        return 'green'; // High = Green
      case 'medium':
        return '#f5a810'; // Medium = Yellow
      case 'low':
        return 'red'; // Low = Red
      default:
        return '#e0e0e0'; // Default = light gray if no priority is set
    }
  };

  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }


  return (
    <div className='main-container'>
      <div className="top-controls">
        <h1 className='title'>Task List
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          {selectedTasks.length > 0 && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <select onChange={handleActionChange} value={action} className="color-button">
                <option value="">Select Action</option>
                <option value="edit">Edit</option>
                <option value="delete">Delete</option>
              </select>
              <button onClick={applyAction} className="color-button">
                Apply
              </button>
            </div>
          )}
          <button className="color-button" onClick={handleMyTaskClick}>
            {fetchMyTasks ? "Show All Tasks" : "My Task"}
          </button>
          <button className="color-button" onClick={handleAddTaskClick}>
            Add Task
          </button>
          <button className="color-button" onClick={open}>
            Import
          </button>
          <button className="color-button" onClick={handleDownloadPdf}>
            Export
          </button>
        </div>
      </div>
      {/* First Row: Filters and Search Bar */}
      <div className="top-controls">
        <div className="filters-and-search">
          <button className="border-button">
            Clear Filters
          </button>

          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="searchbar"
          />
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Code</th>
            <th>Task</th>
            <th> <div style={{ display: 'flex' }}><span style={{ alignSelf: 'center' }}>Start</span>
              <input
                type="date"
                className="header-filter"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Start Date"
              />
            </div>
            </th>
            <th><div style={{ display: 'flex' }}><span style={{ alignSelf: 'center' }}>Due</span>
              <input
                type="date"
                className="header-filter"


                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
                placeholder="Due Date"
              /></div>
            </th>
            <th><div>
              <select
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                className="header-filter"
              >
                <option value="">All Statuses</option>
                {statusOptions.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.status}
                  </option>
                ))}
              </select></div></th>
            <th>Points</th>
            <th>Assigned Users</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedTasks.map((task) => {
            // Find the dependent task object in the tasks list
            const dependentTask = paginatedTasks.find(
              (t) => t.task === task.dependent_task
            );
            // Determine if the current task should be disabled
            const isDisabled =
              dependentTask && dependentTask.status !== 'Completed'; // Disable if dependent task exists and is not completed
            // Tooltip message for dependent tasks
            const tooltipMessage = isDisabled
              ? `This task is dependent on: ${task.dependent_task}`
              : '';

            return (
              <tr
                key={task.task_id}
                style={{
                  backgroundColor: task.overdue_status === 'yes' ? '#fac3ca' : 'transparent', // Highlight row if overdue
                  opacity: isDisabled ? 0.5 : 1, // Dim the row if disabled
                }}
              >
                <td>
                  <Tooltip title={tooltipMessage} arrow disableHoverListener={!isDisabled}>
                    <span>
                      <input
                        type="checkbox"
                        checked={selectedTasks.includes(task.task_id)}
                        onChange={() => handleCheckboxChange(task.task_id)}
                        disabled={isDisabled} // Disable checkbox if the task is dependent and not completed
                      />
                    </span>
                  </Tooltip>
                </td>
                <td style={{ textAlign: 'center' }}>{task.code}</td>
                <td
                  style={{
                    color: isDisabled ? 'gray' : 'blue',
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                  }}
                  onClick={!isDisabled ? () => handleTaskNameClick(task.task_id) : undefined} // Prevent navigation if disabled
                >
                  <Tooltip title={tooltipMessage} arrow disableHoverListener={!isDisabled}>
                    <span>
                      {capitalizeFirstLetter(truncateText(task.task, 6))} <br />
                      <span style={{ color: '#555' }}>{capitalizeFirstLetter(task.project_name)}</span>
                      {task.priority && (
                        <span
                          style={{
                            backgroundColor: getPriorityColor(task.priority),
                            color: '#fff',
                            padding: '2px 8px',
                            borderRadius: '4px',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            marginLeft: '10px',
                          }}
                        >
                          {capitalizeFirstLetter(task.priority)}
                        </span>
                      )}
                      {task.is_private === 1 && (
                        <span
                          style={{
                            backgroundColor: '#333',
                            color: '#fff',
                            padding: '2px 8px',
                            borderRadius: '4px',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            marginLeft: '10px',
                          }}
                        >
                          Private
                        </span>
                      )}
                      {task.repeat === 1 && (
                        <span
                          style={{
                            backgroundColor: 'orange',
                            color: '#fff',
                            padding: '2px 8px',
                            borderRadius: '4px',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            marginLeft: '10px',
                          }}
                        >
                          Repeat
                        </span>
                      )}
                    </span>
                  </Tooltip>
                </td>
                <td>{capitalizeFirstLetter(task.start_date)}</td>
                <td>{capitalizeFirstLetter(task.due_date)}</td>
                <td style={{ width: '120px' }}>
                  <div
                    className="custom-select-container"
                    style={{ position: 'relative', width: '100%' }}
                  >
                    <div
                      className="selected-item"
                      style={{
                        padding: '4px',
                        border: '1px solid #ccc',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          display: 'inline-block',
                          width: '10px',
                          height: '10px',
                          backgroundColor: statusColors[task.status],
                          borderRadius: '50%',
                          marginRight: '8px',
                        }}
                      />
                      {capitalizeFirstLetter(task.status)}
                    </div>
                    <select
                      value={task.status}
                      onChange={(e) => handleStatusChange(task.task_id, e.target.value)}
                      disabled={isDisabled} // Disable status change if the task is dependent and not completed
                      style={{
                        width: '100%',
                        padding: '5px',
                        border: 'none',
                        cursor: isDisabled ? 'not-allowed' : 'pointer',
                        backgroundColor: 'transparent',
                        appearance: 'none', // Hide native dropdown arrow
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        height: '100%',
                        opacity: 0, // Invisible select
                      }}
                    >
                      {statusOptions.map((option) => (
                        <option key={option.id} value={option.status}>
                          {capitalizeFirstLetter(option.status)}
                        </option>
                      ))}
                    </select>
                  </div>
                </td>
                <td style={{ textAlign: 'center' }}>{task.task_points}</td>
                <td>
                  {task.assigned_users && task.assigned_users.length > 0 ? (
                    <div style={styles.avatarContainer}>
                      {task.assigned_users.slice(0, 3).map((user, index) => (
                        <Tooltip
                          key={index}
                          title={capitalizeFirstLetter(user.name)}
                          arrow
                        >
                          <Avatar
                            src={user.image || defaultAvatar}
                            alt={capitalizeFirstLetter(user.name)}
                            className="assigned-user-avatar"
                            style={{
                              zIndex: task.assigned_users.length - index,
                              ...styles.overlappingAvatar,
                            }}
                          />
                        </Tooltip>
                      ))}
                      {task.assigned_users.length > 3 && (
                        <span style={styles.moreUsers}>
                          +{task.assigned_users.length - 3}
                        </span>
                      )}
                    </div>
                  ) : (
                    <p className="no-data">No users assigned</p>
                  )}
                </td>
                <td>
                  <div className="action-container">
                    <div className="action-dots" onClick={() => toggleActionMenu(task.task_id)}>
                      <GridMoreVertIcon />
                    </div>
                    {actionMenuVisible[task.task_id] && (
                      <div className="action-menu" ref={actionMenuRef}>
                        <div
                          className="action-item"
                          onClick={() => handleActionMenuClick('edit', task.task_id)}
                        >
                          Edit
                        </div>
                        <div
                          className="action-item"
                          onClick={() => handleActionMenuClick('delete', task.task_id)}
                        >
                          Delete
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </div>
      {isModalOpen && (
        <TaskDetail taskId={selectedTaskId} onClose={closeModal} />
      )}
      {error && (
        <div style={{ color: 'red', marginTop: '20px' }}>{error}</div>
      )}


      {/* Modal for file upload */}
      <Modal
        open={isOpen}
        onClose={close}
        aria-labelledby="file-upload-modal"
        aria-describedby="modal-for-uploading-files"
      >
        <Box className="designation-modal">
          {/* Header with title and close button */}
          <div className="designation-header">
            <h1 className="designation-title">Import File</h1>
            <img
              src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
              alt="Close"
              className="designation-close"
              onClick={close}
            />
          </div>

          {/* Main Image */}
          <img
            src={designationImage} // Replace 'designationImage' with the path or import for the desired image
            alt="Designation"
            className="designation-image"
          />

          {/* Form Section */}
          <div className="designation-form">
            <label className="designation-label">
              Select Project
            </label>
            <select
              value={selectedProject}
              onChange={(e) => setSelectedProject(e.target.value)}
              className="designation-input"
            >
              <option value="">Select a project</option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.project_name}
                </option>
              ))}
            </select>
            <label className="designation-label">
              Add File
            </label>
            <input
              type="file"
              id="fileUpload"
              onChange={handleFileChange}
              className="designation-input"
            />

            <p style={{ color: 'red', fontSize: '15px' }}>
              <span>Note:</span> All dates in this document must follow the DD/MM/YYYY format (eg., 13/01/2025)
            </p>
          </div>

          {/* Sample File Link */}
          <button className="designation-save" onClick={handleDownloadClick}>Task sample file</button>


          {/* Submit Button */}
          <button
            onClick={handleSubmit}
            className="designation-save"
          >
            Submit
          </button>
        </Box>
      </Modal>


      <Modal open={holdModalOpen} onClose={handleHoldModalClose}>
        <Box className='designation-modal'>
          <div className='designation-header'>
            <h1 className="designation-title">
              Add Details
            </h1>
            <img
              src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
              alt="Close"
              className='designation-close'
              onClick={handleHoldModalClose}
            />
          </div>
          <img
            src={designationImage} // Replace 'designationImage' with the path or import
            alt="Designation"
            className='designation-image'
          />
          <div className='designation-form'>
            <label className="form-label-deal">Reason:</label>
            <textarea
              type="text"
              rows={3}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              margin="normal"
              className='designation-input'
            />
          </div>
          <div className="form-button-group-deal">
            <button className="submit-button-deal" onClick={handleSaveHoldDetails}>
              Save
            </button>
          </div>
        </Box>
      </Modal>

    </div>
  );

};
export default Task;


const styles = {

  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginLeft: '20px'
  },
  overlappingAvatar: {
    marginLeft: '-10px', // Overlap the avatars
    border: '2px solid white', // Optional: Add white border around each avatar for better visibility
    width: '30px',
    height: '30px',
  },
  moreUsers: {
    backgroundColor: '#e0e0e0',
    borderRadius: '50%',
    padding: '0 8px',
    lineHeight: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '5px',
  },


};

