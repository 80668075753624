import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import ocean from '../assets/ocean.png';

function ForgotPassword() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  console.log('Extracted ID from URL:', id);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State for New Password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for Confirm Password visibility

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Submitting with ID:', id);
    console.log('Password:', password);
    console.log('Confirm Password:', confirmPassword);

    // Validate password and confirm password
    if (password !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }

    if (password.length < 6) {
      alert('Password must be at least 6 characters long.');
      return;
    }

    // Send POST request to API
    setLoading(true);

    try {
      const response = await fetch('https://api.myntask.com/auth/set-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id, // Include the ID from the URL
          password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('API Response:', data);
        // alert('Password successfully updated!');
        Swal.fire({
          title: 'Password Updated',
          text: 'Your password has been successfully updated.',
          icon: 'success',
          timer: '1500',
        });
        // Reset the form fields
        setPassword('');
        setConfirmPassword('');
      } else {
        const errorData = await response.json();
        console.error('Error:', errorData);
        alert('Failed to update password. Please try again.');
      }
    } catch (err) {
      console.error('API Error:', err);
      alert('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', alignItems: 'center', justifyContent: 'center', backgroundColor: '#004d4a' }}>
      <div style={{ height: '15vh', width: "15vw" }}>
        <img style={{ width: '15vw' }} src={ocean} />
      </div>
      <div style={{ backgroundColor: '#f2e2a7', padding: '30px', borderRadius: '8px', width: '400px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}>

        <h1 style={{ fontSize: '24px', fontWeight: '600', marginTop: '10px', marginBottom: '5px' }}>Reset Password</h1>
        <p style={{ fontSize: '14px', marginBottom: '20px' }}>Please create your new password</p>
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: '15px', position: 'relative' }}>
            <label htmlFor="password" style={{ display: 'block', fontWeight: '500', marginBottom: '5px' }}>New Password</label>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              placeholder="Enter your new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={{
                width: '100%',
                padding: '10px',
                fontSize: '14px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              style={{
                position: 'absolute',
                right: '10px',
                top: '70%',
                transform: 'translateY(-70%)',
                cursor: 'pointer',
                fontSize: '14px',
                color: '#007bff',
              }}
            >
              {showPassword ? '👁️' : '🙈'}
            </span>
          </div>
          <div style={{ marginBottom: '20px', position: 'relative' }}>
            <label htmlFor="confirmPassword" style={{ display: 'block', fontWeight: '500', marginBottom: '5px' }}>Confirm Password</label>
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              placeholder="Confirm your new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              style={{
                width: '100%',
                padding: '10px',
                fontSize: '14px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
            />
            <span
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              style={{
                position: 'absolute',
                right: '10px',
                top: '70%',
                transform: 'translateY(-70%)',
                cursor: 'pointer',
                fontSize: '14px',
                color: '#007bff',
              }}
            >
              {showConfirmPassword ? '👁️' : '🙈'}
            </span>
          </div>
          <button
            type="submit"
            style={{
              width: '100%',
              padding: '12px',
              fontSize: '16px',
              backgroundColor: '#007bff',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              fontWeight: '600',
            }}
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save New Password'}
          </button>
        </form>
      </div>
    </div>
  );
}


export default ForgotPassword;






