import React, { useEffect, useState } from 'react';
import './ClientDashboard.css';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';



// Register chart components
ChartJS.register(Tooltip, Legend, CategoryScale, LinearScale, BarElement);

const ClientDashboard = () => {
    const [clientData, setClientData] = useState(null);


    useEffect(() => {
        const storedData = localStorage.getItem('client_data');
        if (storedData) {
            setClientData(JSON.parse(storedData));
        }
    }, []);


    if (!clientData) {
        return <div className="loading">Loading...</div>;
    }


    const { profile } = clientData;

    // Prepare data for the graph
    const labels = profile.project_data.map((_, index) => `Project `);
    const totalProjects = profile.project_data.map((project) => project.total);
    const inProgress = profile.project_data.map((project) => project.in_progress);
    const finished = profile.project_data.map((project) => project.finished);
    const canceled = profile.project_data.map((project) => project.canceled);
    const onHold = profile.project_data.map((project) => project.on_hold);

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Total Projects',
                data: totalProjects,
                backgroundColor: 'rgba(63, 231, 231, 0.6)',
            },
            {
                label: 'In Progress',
                data: inProgress,
                backgroundColor: 'rgba(54, 162, 235, 0.6)',
            },
            {
                label: 'Finished',
                data: finished,
                backgroundColor: 'rgba(116, 81, 186, 0.6)',
            },
            {
                label: 'Canceled',
                data: canceled,
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
            },
            {
                label: 'On Hold',
                data: onHold,
                backgroundColor: 'rgba(255, 206, 86, 0.6)',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: (context) => {
                        const value = context.raw;
                        return `${context.dataset.label}: ${value}`;
                    },
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Count',
                },
            },
        },
    };


    return (
        <div className="client-dashboard">
            {/* Header */}
            <div className="header">
                <img src={profile.company_logo} alt="Company Logo" className="company-logo" />
                <div className="header-text">
                    <h1>Welcome, {profile.user_name}!</h1>
                    <p className="company-name">Empowering Your Success with {profile.company_name}.</p>
                </div>
            </div>


            {/* Profile Section */}
            {/* <div className="profile-section">
                <img src={profile.image} alt="User Avatar" className="user-avatar" />
                <div className="profile-details">
                    <p><span>Email:</span> {profile.email}</p>
                    <p><span>Mobile:</span> {profile.mobile}</p>
                </div>
            </div> */}


            {/* Project Summary */}
            <div className="project-summary">
                <h2>Project Overview</h2>
                <div className="project-data">
                    {profile.project_data.map((project, index) => (
                        <div key={index} className="project-stats">
                            <div className="stat-card">
                                <p><span>Total Projects:</span> {project.total}</p>
                            </div>
                            <div className="stat-card">
                                <p><span>In Progress:</span> {project.in_progress}</p>
                            </div>
                            <div className="stat-card">
                                <p><span>Finished:</span> {project.finished}</p>
                            </div>
                            <div className="stat-card">
                                <p><span>Canceled:</span> {project.canceled}</p>
                            </div>
                            <div className="stat-card">
                                <p><span>On Hold:</span> {project.on_hold}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>

            <div className="project-summary">
                <h2>Project Overview Chart</h2>
                <div className="graph-container">
                    <Bar data={chartData} options={options} />
                </div>
            </div>



            {/* Project Details */}
            <div className="project-details">
                <h2>Project Details</h2>
                <div className="details-grid">
                    {profile.project_details.map((project) => (
                        <div key={project.project_id} className="details-item">
                            <div className="details-text">
                                <h3 style={{ textAlign: 'center' }}>{project.project_name}</h3>
                                {/* <p><span>Project ID:</span> {project.project_id}</p> */}
                                <p><span>Total Tasks :</span> {project.total_tasks}</p>
                                <p><span>Completed Tasks :</span> {project.completed_tasks}</p>
                                <p><span>In Progress Tasks :</span> {project.in_progress_tasks}</p>
                                <p><span>Completion Percentage :</span> {project.completion_percentage}%</p>
                            </div>
                            <div className="progress-graph">
                                <CircularProgressbar
                                    value={parseFloat(project.completion_percentage)}
                                    text={`${project.completion_percentage}%`}
                                    styles={buildStyles({
                                        pathColor: `rgba(223, 68, 35, ${project.completion_percentage / 100})`,
                                        textColor: '#333',
                                        trailColor: '#ddd',
                                    })}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>


        </div>
    );
};


export default ClientDashboard;






