// SignUp.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import bevelImage from '../assets/bevel.png'; // Import the bevel image for promotion


const languages = ['English', 'Spanish', 'French', 'German', 'Chinese'];
const currencies = ['USD', 'EUR', 'GBP', 'JPY', 'AUD']; // Example currencies


function SignUp() {
    const navigate = useNavigate();
    const [form, setForm] = useState({
        companyName: '',
        companyEmail: '',
        companyPhone: '',
        companyLogo: '',
        companyWebsite: '',
        companyLanguage: '',
        companyStatus: '',
        companyAddress: '',
        defaultCurrency: '',
        defaultTimezone: '',
        accountName: '',
        accountEmail: '',
    });

    const [errors, setErrors] = useState({
        companyName: false,
        companyEmail: false,
        accountName: false,
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
        setErrors({ ...errors, [name]: false });
    };




    const handleFileChange = (e) => {
        setForm({ ...form, companyLogo: e.target.files[0] });
    };




    const handleSave = async () => {
        const newErrors = {
            companyName: form.companyName.trim() === '',
            companyEmail: form.companyEmail.trim() === '',
            accountName: form.accountName.trim() === '',
        };

        if (newErrors.companyName || newErrors.companyEmail || newErrors.accountName) {
            setErrors(newErrors);
            return;
        }

        const data = {
            company_name: form.companyName,
            company_email: form.companyEmail,
            company_phone: form.companyPhone,
            address: form.companyAddress,
            website: form.companyWebsite,
            currency_id: 2, // This value should be derived from form.defaultCurrency
            timezone: form.defaultTimezone,
            language: form.companyLanguage,
            status: form.companyStatus.toLowerCase(), // assuming backend expects lowercase
            email: form.accountEmail,
            name: form.accountName,
        };

        try {
            const response = await fetch('https://api.myntask.com/company/save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            console.log('Form data successfully saved:', result);

        } catch (error) {
            console.error('Failed to save form data:', error);
        }
    };


    // const handleCancel = () => {
    //     navigate('/LandingPage'); // Navigate back to the SuperAdminCompanies screen
    // };


    return (
        <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#f5f5f5', minHeight: '100vh', }}>
            <h2 style={{ marginBottom: '20px' }}>Sign Up for Myntask ✌️</h2>


            <div className="right-side1" style={{ borderTopLeftRadius: '10px', borderTopRightRadius: "10px" }}>
                <img className="promotion-image-centered" style={{ width: '30%', display: 'block', margin: 'auto' }} src={bevelImage} alt="Bevel" />
            </div>




            <div style={{
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px',
                width: '100%',
                maxWidth: '900px',
                padding: '20px',
                backgroundColor: '#fff'
            }}>
                <h2 style={{ marginBottom: '20px' }}>Company Detail</h2>




                <form noValidate autoComplete="off">
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '20px' }}>
                        <div style={{ marginBottom: "15px" }}>
                            <label style={{ fontSize: "14px", fontWeight: "600", color: "#495057" }}>
                                Company Name <span style={{ color: 'red' }}>*</span>
                                <input
                                    type="text"
                                    name="companyName"
                                    value={form.companyName}
                                    onChange={handleChange}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        marginTop: "5px",
                                        border: "1px solid #ced4da",
                                        borderRadius: "6px",
                                        fontSize: "14px",
                                        boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
                                    }}
                                />
                            </label>
                            {errors.companyName && <p style={{ color: 'red' }}>Company Name is required</p>}
                        </div>




                        <div style={{ marginBottom: "15px" }}>
                            <label style={{ fontSize: "14px", fontWeight: "600", color: "#495057" }}>
                                Company Email <span style={{ color: 'red' }}>*</span>
                                <input
                                    type="email"
                                    name="companyEmail"
                                    value={form.companyEmail}
                                    onChange={handleChange}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        marginTop: "5px",
                                        border: "1px solid #ced4da",
                                        borderRadius: "6px",
                                        fontSize: "14px",
                                        boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
                                    }}
                                />
                            </label>
                            {errors.companyEmail && <p style={{ color: 'red' }}>Company Email is required</p>}
                        </div>




                        <div style={{ marginBottom: "15px" }}>
                            <label style={{ fontSize: "14px", fontWeight: "600", color: "#495057" }}>
                                Company Phone Number <span style={{ color: 'red' }}>*</span>
                                <input
                                    type="text"
                                    name="companyPhone"
                                    value={form.companyPhone}
                                    onChange={handleChange}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        marginTop: "5px",
                                        border: "1px solid #ced4da",
                                        borderRadius: "6px",
                                        fontSize: "14px",
                                        boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
                                    }}
                                />
                            </label>
                        </div>




                        <div style={{ marginBottom: "15px" }}>
                            <label style={{ fontSize: "14px", fontWeight: "600", color: "#495057" }}>
                                Upload Company Logo <span style={{ color: 'red' }}>*</span>
                                <input
                                    type="file"
                                    name="companyLogo"
                                    onChange={handleFileChange}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        marginTop: "5px",
                                        border: "1px solid #ced4da",
                                        borderRadius: "6px",
                                        fontSize: "14px",
                                        boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
                                    }}
                                />
                            </label>
                        </div>




                        <div style={{ marginBottom: "15px" }}>
                            <label style={{ fontSize: "14px", fontWeight: "600", color: "#495057" }}>
                                Company Website <span style={{ color: 'red' }}>*</span>
                                <input
                                    type="text"
                                    name="companyWebsite"
                                    value={form.companyWebsite}
                                    onChange={handleChange}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        marginTop: "5px",
                                        border: "1px solid #ced4da",
                                        borderRadius: "6px",
                                        fontSize: "14px",
                                        boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
                                    }}
                                />
                            </label>
                        </div>




                        {/* <div>
              <label>
                Language
                <select
                  name="companyLanguage"
                  value={form.companyLanguage}
                  onChange={handleChange}
                  style={{ width: '100%', margin: '10px 0' }}
                >
                  {languages.map((language) => (
                    <option key={language} value={language}>
                      {language}
                    </option>
                  ))}
                </select>
              </label>
            </div>




            <div>
              <label>
                Default Currency
                <select
                  name="defaultCurrency"
                  value={form.defaultCurrency}
                  onChange={handleChange}
                  style={{ width: '100%', margin: '10px 0' }}
                >
                  {currencies.map((currency) => (
                    <option key={currency} value={currency}>
                      {currency}
                    </option>
                  ))}
                </select>
              </label>
            </div> */}




                        {/* <div>
              <label>
                Default Timezone
                <select
                  name="defaultTimezone"
                  value={form.defaultTimezone}
                  onChange={handleChange}
                  style={{ width: '100%', margin: '10px 0' }}
                >
                  {timezones.map((timezone) => (
                    <option key={timezone} value={timezone}>
                      {timezone}
                    </option>
                  ))}
                </select>
              </label>
            </div> */}




                        <div style={{ marginBottom: "15px" }}>
                            <label style={{ fontSize: "14px", fontWeight: "600", color: "#495057" }}>
                                Company Address <span style={{ color: 'red' }}>*</span>
                                <input
                                    type="text"
                                    name="companyAddress"
                                    value={form.companyAddress}
                                    onChange={handleChange}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        marginTop: "5px",
                                        border: "1px solid #ced4da",
                                        borderRadius: "6px",
                                        fontSize: "14px",
                                        boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
                                    }}
                                />
                            </label>
                        </div>






                    </div>




                    <h2 style={{ marginTop: '20px' }}>Account Details</h2>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '20px' }}>


                        <div style={{ marginBottom: "15px" }}>
                            <label style={{ fontSize: "14px", fontWeight: "600", color: "#495057" }}>
                                Account Name <span style={{ color: 'red' }}>*</span>
                                <input
                                    type="text"
                                    name="accountName"
                                    value={form.accountName}
                                    onChange={handleChange}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        marginTop: "5px",
                                        border: "1px solid #ced4da",
                                        borderRadius: "6px",
                                        fontSize: "14px",
                                        boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
                                        boxSizing: "border-box",
                                    }}
                                />
                            </label>
                            {errors.accountName && <p style={{ color: 'red' }}>Account Name is required</p>}
                        </div>




                        <div style={{ marginBottom: "15px" }}>
                            <label style={{ fontSize: "14px", fontWeight: "600", color: "#495057" }}>
                                Account Email <span style={{ color: 'red' }}>*</span>
                                <input
                                    type="email"
                                    name="accountEmail"
                                    value={form.accountEmail}
                                    onChange={handleChange}
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        marginTop: "5px",
                                        border: "1px solid #ced4da",
                                        borderRadius: "6px",
                                        fontSize: "14px",
                                        boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.1)",
                                    }}
                                />
                            </label>
                        </div>
                    </div>




                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '15px', marginTop: '20px' }}>




                        <button
                            type="button"
                            className="color-button"
                            onClick={handleSave}
                        // style={{ marginRight: '10px', padding: '10px 20px', backgroundColor: 'blue', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}


                        >
                            Save
                        </button>


                    </div>
                </form>
            </div>


        </div>


    );
}




export default SignUp;


















