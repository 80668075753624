import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/Settings.css';
import ProfileSettingg from '../Components/ProfileSettingg';
import RolePermissionSetting from '../Components/RolePermissionSetting';
import ProjectSetting from '../Components/ProjectSetting';
import TaskSetting from '../Components/TaskSetting';
import Banner from '../Components/Banner';
import TaxSettings from '../Components/TaxSettings';
import LeadSettings from '../Components/LeadSettings';
import BusinessAddress from '../Components/BusinessAddress';
import ProductSetting from '../Components/ProductSetting';
import VendorSetting from '../Components/VendorSetting';
import ClientSetting from '../Components/ClientSetting';
import Projectmember from '../Components/ProjectMember';
import LeadMember from '../Components/LeadMembers';
import UnitSetting from '../Components/UnitSetting';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';

function Settings() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('Profile Setting'); // Default active tab
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSettings = async () => {
      const token = localStorage.getItem('authToken');

      if (!token) {
        setError('No token found. Please log in.');
        return;
      }

      try {
        const response = await fetch('https://api.myntask.com/auth/setting_sidebar', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const result = await response.json();
        setData(result);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching settings:', error);
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    // Navigate to the Banner component if "Banner" tab is clicked
    if (tabName.toLowerCase() === 'banner') {
      setActiveTab('Banner');
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'Profile Setting':
        return <ProfileSettingg />;
      case 'Role & Permission':
        return <RolePermissionSetting />;
      case 'Banner':
        return <Banner />;
      case 'Business Address':
        return <BusinessAddress />;
      case 'Tax Settings':
        return <TaxSettings />;
      case 'Lead Settings':
        return <LeadSettings />;
      case 'Project Setting':
        return <ProjectSetting />;
      case 'Task Setting':
        return <TaskSetting />;
      case 'Product Setting':
        return <ProductSetting />;
      case 'Vendor Setting':
        return <VendorSetting />;
      case 'Client Setting':
        return <ClientSetting />;
      case 'Project Members':
        return <Projectmember />;
      case 'Sales Members':
        return <LeadMember />;
      case 'Unit Setting':
        return <UnitSetting />;
      default:
        return <div>No content available for this tab.</div>;
    }
  };

  //..........Loading Spinner ..........................................

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  return (
    <div className="settings-container">
      {error && <p>Error: {error}</p>}
      {data.length > 0 ? (
        <div className="vertical-tabs">
          <div className="tab-list">
            {data.map((item) => (
              <div
                key={item}
                className={`tab-item ${activeTab === item ? 'active' : ''}`}
                onClick={() => handleTabClick(item)}
              >
                {item}
              </div>
            ))}
          </div>
          <div className="tab-content">{renderTabContent()}</div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Settings;
