import React, { useEffect, useRef, useState } from 'react';
import './../Components/Main.css';
import { useNavigate } from 'react-router-dom';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Swal from 'sweetalert2';
import noDataImage from '../assets/NoDataFound.png';
import { FaChevronDown } from 'react-icons/fa'; // Dropdown icon for role
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';








const Employee = () => {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]); // Used to track selected employees
  const [dropdownVisible, setDropdownVisible] = useState(false); // For action dropdown at the top
  const [roleDropdownVisible, setRoleDropdownVisible] = useState(null); // For role dropdown in the table
  const [action, setAction] = useState('');
  const [employeeDropdownVisible, setEmployeeDropdownVisible] = useState(false);
  const [designationDropdownVisible, setDesignationDropdownVisible] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [designationList, setDesignationList] = useState([]); // Store available designations
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [selectedDesignation, setSelectedDesignation] = useState(''); // Store selected designation
  const [sortOrder, setSortOrder] = useState(null); // 'asc' or 'desc'
  const [designationSortOrder, setDesignationSortOrder] = useState(null); // 'asc' or 'desc'
  const [roles, setRoles] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [statusFilter, setStatusFilter] = useState(''); // New state for status filter
  const [selectedRole, setSelectedRole] = useState({}); // Tracks the selected role for each employee
  const dropdownRef = useRef(null); // Ref for the dropdown
  const [actionMenuVisible, setActionMenuVisible] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    add_employees: false,
    edit_employees: false,
    delete_employees: false,
  });








  const navigate = useNavigate();




  const handleSortDesignation = () => {
    if (designationSortOrder === 'asc') {
      setDesignationSortOrder('desc');
      setFilteredEmployees([...filteredEmployees].sort((a, b) => b.designation.localeCompare(a.designation)));
    } else {
      setDesignationSortOrder('asc');
      setFilteredEmployees([...filteredEmployees].sort((a, b) => a.designation.localeCompare(b.designation)));
    }
  };
  const handleSortEmployees = () => {
    if (sortOrder === 'asc') {
      setSortOrder('desc');
      setFilteredEmployees([...filteredEmployees].sort((a, b) => b.user_name.localeCompare(a.user_name)));
    } else {
      setSortOrder('asc');
      setFilteredEmployees([...filteredEmployees].sort((a, b) => a.user_name.localeCompare(b.user_name)));
    }
  };








  // Fetch roles from the API
  const fetchRoles = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await fetch('https://api.myntask.com/employee/roles', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setRoles(data); // Set the fetched roles to state
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };





  // Handle role selection
  const handleRoleSelect = async (employeeId, selectedRoleName) => {
    const token = localStorage.getItem('authToken');
    const selectedRole = roles.find((role) => role.role_name === selectedRoleName); // Find role_id by name

    if (!selectedRole) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Invalid role selected.',
        timer: 1000,
        showConfirmButton: false,
      });
      return;
    }


    console.log('role id is', selectedRole.id,);

    try {
      const response = await fetch(`https://api.myntask.com/employee/role_update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          user_id: employeeId,
          role_id: selectedRole.id, // Send role_id
        }),
      });


      if (!response.ok) throw new Error('Failed to update role');

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Role updated successfully!',
        timer: 1000,
        showConfirmButton: false,
      });


      fetchEmployees(); // Refresh employee list after update
    } catch (error) {
      console.error('Error updating role:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to update role. Please try again.',
        timer: 1000,
        showConfirmButton: false,
      });
    }
  };



  const handleExport = () => {
    const doc = new jsPDF();




    // Create the table headers (including No. as first column)
    const columns = [
      'No.', 'Employee Name', 'Email', 'Mobile', 'Status', 'Role', 'Designation', 'Reporting To'
    ];




    // Map the filteredEmployees to data rows for the table
    const rows = filteredEmployees
      .map((employee, index) => [
        index + 1, // Serial number (No.)
        employee.user_name,
        employee.email,
        employee.mobile,
        employee.status,
        employee.role_name,
        employee.designation,
        employee.reporting_to || 'N/A'
      ]);




    // Use jsPDF's autoTable to generate the table
    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 20, // Starts 20 units down from the top of the page
    });




    // Save the PDF
    doc.save('employees-report.pdf');
  };



  // Handle role click to toggle dropdown
  const handleRoleClick = (employeeId) => {
    setSelectedEmployeeId(employeeId);
    setRoleDropdownVisible(roleDropdownVisible === employeeId ? null : employeeId); // Toggle dropdown for the specific employee
  };




  const handleStatusFilter = (status) => {
    setStatusFilter(status);  // Set the status filter
    fetchEmployees('', '', status);  // Update API call with status
  };




  const fetchEmployees = async (employeeId = '', designationId = '', status = '') => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      return;
    }


    const url = `https://api.myntask.com/employee/list?status=${status}&employee_id=${employeeId}&designation_id=${designationId}`;


    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });


    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.statusText}`);
    }


    const data = await response.json();
    setEmployees(data.data || []);
    setFilteredEmployees(data.data || []);
    setPermissions(data.employee_permission || {});
    setIsLoading(false);
  };



  const fetchEmployeeList = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await fetch('https://api.myntask.com/employee/list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setEmployeeList(data.data);
    } catch (err) {
      setError('Failed to fetch employee list. Please try again later.');
    }
  };



  // Fetch designation list from API
  const fetchDesignationList = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await fetch('https://api.myntask.com/employee/designation', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setDesignationList(data); // Set fetched designations
    } catch (err) {
      setError('Failed to fetch designation list. Please try again later.');
    }
  };


  useEffect(() => {
    fetchRoles(); // Fetch roles on mount
    fetchDesignationList();
    fetchEmployeeList();
  }, []);




  useEffect(() => {
    fetchEmployees();
  }, []);


  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No token found');
      return;
    }


    // Wrap in an async IIFE for clean async handling
    (async () => {
      try {
        await fetchEmployees(); // No parameters passed
      } catch (err) {
        console.error('Error fetching employees:', err);


      }
    })();
  }, []);




  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = employees.filter(
      (employee) =>
        employee.user_name.toLowerCase().includes(value) ||
        employee.email.toLowerCase().includes(value)
    );
    setFilteredEmployees(filtered);
  };




  const handleCheckboxChange = (employeeId) => {
    setSelectedEmployeeIds((prevIds) => {
      if (prevIds.includes(employeeId)) {
        return prevIds.filter((id) => id !== employeeId);
      } else {
        return [...prevIds, employeeId];
      }
    });
  };



  const handleDropdownChange = (e) => {
    setAction(e.target.value);
  };




  const handleApplyClick = async () => {
    if (selectedEmployeeIds.length === 0) {
      alert('No employees selected.');
      return;
    }
    const token = localStorage.getItem('authToken');
    try {
      switch (action) {
        case 'edit':
          if (selectedEmployeeIds.length > 1) {
            alert('Please select only one employee for editing.');
            return;
          }
          const selectedId = selectedEmployeeIds[0];
          navigate(`/editEmployee/${selectedId}`);
          break;
        case 'delete':
          try {
            const result = await Swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!',
            });



            if (result.isConfirmed) {
              const deleteRequests = selectedEmployeeIds.map((id) =>
                fetch('https://api.myntask.com/employee/employee_delete', {
                  method: 'DELETE',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify({ user_id: id }),
                })
              );


              const responses = await Promise.all(deleteRequests);
              for (const response of responses) {
                if (!response.ok) {
                  throw new Error(`Error: ${response.statusText}`);
                }
              }



              Swal.fire({
                title: 'Deleted!',
                text: 'Employees have been deleted.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
              });



              await fetchEmployees();
            }
          } catch (error) {
            Swal.fire({
              title: 'Error!',
              text: 'Failed to delete employees. Please try again later.',
              icon: 'error',
              confirmButtonColor: '#3085d6',
            });
          }
          break;
        default:
          alert('Invalid action selected.');
          break;
      }
    } catch (error) {
      alert('Failed to perform the action. Please try again later.');
    } finally {
      setDropdownVisible(false);
      setSelectedEmployeeIds([]);
    }
  };




  const handleAddEmployeeClick = () => {
    navigate('/addEmployee');
  };




  const handleEmployeeSelect = (employeeId, employeeName) => {
    setSelectedEmployee(employeeId);  // Update selected employee ID
    setEmployeeDropdownVisible(false);
    fetchEmployees(employeeId, selectedDesignation);
  };




  const handleDesignationSelect = (designationId, designationName) => {
    setSelectedDesignation(designationId);  // Update selected designation ID
    setDesignationDropdownVisible(false);
    fetchEmployees(selectedEmployee, designationId);
  };




  useEffect(() => {
    fetchEmployees(selectedEmployee, selectedDesignation);  // Fetch with both filters
  }, [selectedEmployee, selectedDesignation]);  // Add selectedEmployee and selectedDesignation as dependencies





  const handleClearFilters = () => {
    setSelectedEmployee('');
    setSelectedDesignation('');
    fetchEmployees(); // Reset filters and fetch all employees
  };




  const toggleActionMenu = (userId) => {
    setActionMenuVisible((prevState) => (prevState === userId ? null : userId));
  };



  const handleActionSelect = async (action, userId) => {
    setActionMenuVisible(null); // Close the dropdown
    const token = localStorage.getItem('authToken');
    switch (action) {
      case 'view':
        navigate(`/profile/${userId}`);
        break;
      case 'edit':
        navigate(`/editEmployee/${userId}`);
        break;
      case 'delete':
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const response = await fetch(
                'https://api.myntask.com/employee/employee_delete',
                {
                  method: 'DELETE',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify({ user_id: userId }),
                }
              );


              if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
              }


              Swal.fire({
                title: 'Deleted!',
                text: 'Employee has been deleted.',
                icon: 'success',
                confirmButtonColor: '#3085d6',
              });


              await fetchEmployees();
            } catch (error) {
              Swal.fire({
                title: 'Error!',
                text: 'Failed to delete employee. Please try again later.',
                icon: 'error',
                confirmButtonColor: '#3085d6',
              });
            }
          }
        });
        break;
      default:
        break;
    }
  };




  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActionMenuVisible(null);
      }
    };




    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);




  const toTitleCase = (str) => {
    if (!str) return ''; // return empty string for falsy values
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };



  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }


  return (
    <div className="main-container">

      {error && <p className="error-message">{error}</p>}
      <div className="top-controls">
        <h1 className="title">
          Team Member
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          {/* Conditionally render the action buttons if selectedEmployeeIds is not empty */}
          {selectedEmployeeIds.length > 0 && (

            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <select
                onChange={handleDropdownChange}
                value={action}
                className="color-button"
              >
                <option value="">Select Action</option>
                {permissions?.edit_employees === 'all' && <option value="edit" >
                  Edit
                </option>}
                {permissions?.delete_employees === 'all' && <option value="delete">
                  Delete
                </option>}
              </select>
              <button onClick={handleApplyClick} className="color-button">
                Apply
              </button>
            </div>


          )}
          {permissions.add_employees === 'all' && (
            <button className="color-button" onClick={handleAddEmployeeClick}>
              Add Team member
            </button>
          )}
        </div>
      </div>



      <div className="top-controls">
        <div className="filters-and-search">
          <button className="border-button" onClick={handleClearFilters}>
            Clear Filters
          </button>

          <button className="border-button" onClick={handleExport}>
            Export
          </button>
          {/* <button className="border-button" onClick={handleClearFilters}>
           Invite
         </button> */}
          <input
            type="text"
            placeholder="Search by name or email..."
            value={searchTerm}
            onChange={handleSearch}
            className="searchbar"
          />
        </div>
      </div>


      {/* {filteredEmployees.length > 0 ? ( */}


      <table>
        <thead>
          <tr>
            <th>Select</th>
            <th>#</th>
            <th>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <select
                  className="header-filter"
                  onChange={(e) => handleEmployeeSelect(e.target.value, e.target.options[e.target.selectedIndex].text)}
                  value={selectedEmployee}
                >
                  <option value="">Employees</option>
                  {employeeList.map((employee) => (
                    <option key={employee.user_id} value={employee.user_id}>
                      {employee.user_name}
                    </option>
                  ))}
                </select>

                {/* Sorting icons for ascending/descending */}
                <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                  {sortOrder === 'asc' ? (
                    <span onClick={handleSortEmployees} >↑</span>
                  ) : sortOrder === 'desc' ? (
                    <span onClick={handleSortEmployees} >↓</span>
                  ) : (
                    <span onClick={handleSortEmployees} >↕</span>
                  )}
                </div>
              </div>
            </th>

            <th>Email</th>
            <th>Mobile No.</th>
            <th>
              <div >
                <select
                  onChange={(e) => handleStatusFilter(e.target.value)}
                  className="header-filter"
                >
                  <option value="">Status</option>
                  <option value="active">Active</option>
                  <option value="deactive">Deactive</option>
                </select>
              </div>
            </th>

            <th>Role</th>
            <th>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <select
                  className="header-filter"
                  onChange={(e) => handleDesignationSelect(e.target.value, e.target.options[e.target.selectedIndex].text)}
                  value={selectedDesignation}
                >
                  <option value="">Designation</option>
                  {designationList.map((designation) => (
                    <option key={designation.id} value={designation.id}>
                      {designation.name}
                    </option>
                  ))}
                </select>

                {/* Sorting icons for ascending/descending */}
                <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                  {designationSortOrder === 'asc' ? (
                    <span onClick={handleSortDesignation} >↑</span>
                  ) : designationSortOrder === 'desc' ? (
                    <span onClick={handleSortDesignation} >↓</span>
                  ) : (
                    <span onClick={handleSortDesignation} >↕</span>
                  )}
                </div>
              </div>
            </th>
            <th>Reporting To</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            filteredEmployees.length > 0 ? (
              filteredEmployees.map((employee, index) => (
                <tr key={employee.user_id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedEmployeeIds.includes(employee.user_id)}
                      onChange={() => handleCheckboxChange(employee.user_id)}
                    />
                  </td>
                  <td >{index + 1}</td>
                  <td>
                    <div className="info-container">
                      <img
                        src={employee.image || 'default-avatar.png'}
                        alt={employee.user_name}
                        className="image"
                      />
                      <div
                        className="info-text"
                        onClick={() => navigate(`/profile/${employee.user_id}`)}
                        style={{
                          cursor: 'pointer',
                          color: 'inherit',
                          textDecoration: 'none',


                        }}
                      >
                        {toTitleCase(employee.user_name)}
                      </div>
                    </div>
                  </td>
                  <td>{employee.email}</td>
                  <td >{employee.mobile}</td>
                  <td >
                    <div
                      className="status-container"


                    >
                      <span
                        style={{
                          width: '10px',
                          height: '10px',
                          borderRadius: '50%',
                          backgroundColor: employee.status.toLowerCase() === 'active' ? 'green' : 'red',
                          marginRight: '10px',
                          marginLeft: "10px"
                        }}
                      ></span>
                      <span>{toTitleCase(employee.status)}</span>
                    </div>
                  </td>


                  <td>
                    <select
                      value={employee.role_name}  // Set current role as default
                      onChange={(e) => handleRoleSelect(employee.user_id, e.target.value)} // Pass role name to handler
                      className='table-filter'
                    >
                      {roles.map((role) => (
                        <option key={role.id} value={role.role_name}>
                          {toTitleCase(role.role_name)}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>{toTitleCase(employee.designation)}</td>
                  <td>{employee.reporting_to ? toTitleCase(employee.reporting_to) : 'N/A'}</td>
                  <td>
                    <div className="action-container">
                      <div
                        className="action-dots"
                        onClick={() => toggleActionMenu(employee.user_id)}
                      >
                        <GridMoreVertIcon />
                      </div>
                      {actionMenuVisible === employee.user_id && (
                        <div className="action-menu" ref={dropdownRef}>
                          {permissions?.edit_employees === 'all' && (
                            <div
                              className="action-item"
                              onClick={() => handleActionSelect('edit', employee.user_id)}
                            >
                              Edit
                            </div>
                          )}
                          {permissions?.delete_employees === 'all' && (
                            <div
                              className="action-item"
                              onClick={() => handleActionSelect('delete', employee.user_id)}
                            >
                              Delete
                            </div>
                          )}
                          <div
                            className="action-item"
                            onClick={() => handleActionSelect('view', employee.user_id)}
                          >
                            View
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10" style={{ textAlign: 'center' }} >
                  <img src={noDataImage} alt="No data available" className="no-data-image" />
                  <p className="no-data-text">No data available</p>
                </td>
              </tr>
            )}
        </tbody>
      </table>
      {/* ) : (
        <div className="no-data-container">
          <img src={noDataImage} alt="No data available" className="no-data-image" />
          <p className="no-data-text">No data available</p>
        </div>
      )} */}
    </div>
  );
};





export default Employee;


