import React, { useEffect, useState } from "react";
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';
import "./ClientTask.css";

const ClientTask = () => {
    const [projectData, setProjectData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch the data from the API
        const fetchData = async () => {
            const token = localStorage.getItem('authToken');
            try {
                const response = await fetch("https://api.myntask.com/project/client_task_list", {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }
                });
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const data = await response.json();
                setProjectData(data);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);



    const toTitleCase = (str) =>
        str
            ? str
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")
            : "";


    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'incomplete':
                return 'red';
            case 'to do':
                return 'blue';
            case 'doing':
                return 'orange';
            case 'completed':
                return 'green';
            case 'hold':
                return 'yellow';
            default:
                return 'gray'; // Fallback color
        }
    };




    if (isLoading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    backgroundColor: "#f5f5f5",
                }}
            >
                <Stack alignItems="center">
                    <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
                    <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
                        Loading....
                    </Box>
                </Stack>
            </Box>
        );
    }

    return (
        <div className="client-task-container">
            {projectData.map((project) => (
                <div key={project.project_id} className="project-card">
                    <h2 className="project-name">{project.project_name}</h2>
                    <table className="task-table">
                        <thead>
                            <tr>
                                <th>Task Name</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {project.tasks.map((task) => (
                                <tr key={task.task_id}>
                                    <td>{task.heading}</td>
                                    <td>{task.start_date}</td>
                                    <td>{task.due_date}</td>
                                    <td>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                            <span
                                                style={{
                                                    width: '10px',
                                                    height: '10px',
                                                    borderRadius: '50%',
                                                    backgroundColor: getStatusColor(task.status),
                                                    display: 'inline-block',
                                                }}
                                            ></span>
                                            {toTitleCase(task.status)}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}
        </div>
    );

};

export default ClientTask;
