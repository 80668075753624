

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import defaultimage from '../assets/vector.jpeg';



const EditTaskModal = () => {
  const { taskId } = useParams();
  const navigate = useNavigate();

  const [taskData, setTaskData] = useState({
    assigned_to: [], // Ensuring assigned_to is always an array
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [projects, setProjects] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [labels, setLabels] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [members, setMembers] = useState([]);
  const [isAssignDropdownOpen, setIsAssignDropdownOpen] = useState(false);
  const [dependentTasks, setDependentTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleMultiSelect = (memberId) => {
    if (!taskData) return; // Prevent actions if taskData is null

    const selectedMembers = Array.isArray(taskData.assigned_to)
      ? taskData.assigned_to.includes(memberId)
        ? taskData.assigned_to.filter((id) => id !== memberId) // Remove member if already selected
        : [...taskData.assigned_to, memberId] // Add member if not selected
      : [memberId]; // Initialize assigned_to if it's not an array

    setTaskData({
      ...taskData,
      assigned_to: selectedMembers,
    });
  };



  useEffect(() => {
    const fetchTaskData = async () => {
      if (!taskId) return;

      try {
        const response = await fetch(`https://api.myntask.com/task/edit/${taskId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch task data');
        }

        const data = await response.json();
        const task = data[0]; // Assuming the API returns an array
        // Convert assigned_to from a string to an array
        const assignedToArray = task.assigned_to ? task.assigned_to.split(',').map(Number) : [];

        setTaskData({ ...task, assigned_to: assignedToArray }); // Set task data with assigned_to as an array
      } catch (error) {
        setError(error.message);
      }
    };

    fetchTaskData();
  }, [taskId]);




  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTaskData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };



  const handleSelectChange = (e) => {
    const { name, value } = e.target; // Dynamically extract `name` and `value`
    setTaskData((prevState) => ({
      ...prevState,
      [name]: value, // Update the field corresponding to `name`
    }));
  };



  const fetchDependentTasks = async (projectId) => {
    const token = localStorage.getItem("authToken");
    console.log("Project ID is:", projectId);

    if (!projectId) {
      alert("No project selected. Please select a project.");
      return;
    }

    try {
      setIsLoading(true);
      const response = await fetch(`https://api.myntask.com/task/task_dependent_list/${projectId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch dependent tasks");
      }

      const result = await response.json();
      setDependentTasks(result);
    } catch (error) {
      console.error("Error fetching dependent tasks:", error);
      alert("Failed to fetch dependent tasks. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (taskData.project_id) {
      fetchDependentTasks(taskData.project_id);
    }
  }, [taskData.project_id]);

  useEffect(() => {
    const fetchData = async (url, setter) => {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setter(data || []);
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        setter([]);
      }
    };
    fetchData('https://api.myntask.com/task/task_category', setCategories);
    fetchData('https://api.myntask.com/task/project_list', setProjects);
    fetchData('https://api.myntask.com/employee/member', setMembers);
    fetchData('https://api.myntask.com/task/task_status', setStatuses);
  }, []);


  useEffect(() => {
    if (taskData?.project_id) {
      const fetchLabels = async () => {
        const token = localStorage.getItem('authToken');
        try {
          const response = await fetch(`https://api.myntask.com/task/task_label/${taskData.project_id}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setLabels(data || []);
        } catch (error) {
          console.error('Error fetching labels:', error);
          setLabels([]);
        }
      };
      const fetchMilestones = async () => {
        const token = localStorage.getItem('authToken');
        try {
          const response = await fetch(`https://api.myntask.com/project/milestone_list/${taskData.project_id}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setMilestones(data || []);
        } catch (error) {
          console.error('Error fetching milestones:', error);
          setMilestones([]);
        }
      };
      fetchLabels();
      fetchMilestones();
    }
  }, [taskData?.project_id]);



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!taskData) return;

    // Ensure task_id is included in taskData
    const updatedTaskData = {
      ...taskData,
      task_id: taskData.task_id, // Make sure task_id is already set in taskData
    };

    setLoading(true);

    try {
      const response = await fetch('https://api.myntask.com/task/update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(updatedTaskData),
      });

      if (!response.ok) {
        throw new Error('Failed to update task');
      }

      // Success alert using SweetAlert2
      Swal.fire({
        title: 'Task Updated',
        text: 'The task was successfully updated.',
        icon: 'success',
        confirmButtonText: 'OK',
      });

      console.log('Task saved successfully:', updatedTaskData);

      // Navigate back to the task list or task detail page on success
      navigate('/task'); // Adjust this route as needed
    } catch (error) {
      // Error alert using SweetAlert2
      Swal.fire({
        title: 'Update Failed',
        text: 'There was an error updating the task. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });

      console.error('Error saving task:', error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };


  const handleClose = () => {
    navigate('/task'); // Navigate back to the task screen
  };




  if (!taskData) return <p>Loading...</p>;




  return (
    <div className="main-container">
      <ToastContainer />
      <div className="top-controls">
        <h1 className="title">
          Edit Task
          <div className="underline"></div>
        </h1>
      </div>
      {error && <p className="error">Error: {error}</p>}
      <section className="section">
        <h3 className="sectionTitle">Task Info</h3>

        <div className="formRow">
          <div className="formColumn">
            <div className="formGroup">
              <label className="label">Title</label>
              <input
                className="input"
                type="text"
                id="title"
                name="title"
                value={taskData.title || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="formGroup">
              <label className="label">Task Category:</label>
              <select
                className="input"
                value={taskData.task_category_id || ""}
                onChange={(e) =>
                  setTaskData({
                    ...taskData,
                    task_category_id: e.target.value ? parseInt(e.target.value, 10) : null,
                  })
                }
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.category_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="formGroup">
              <label className="label">Project:</label>
              <select
                className="input"
                value={taskData.project_id || ""}
                onChange={(e) =>
                  setTaskData({
                    ...taskData,
                    project_id: e.target.value ? parseInt(e.target.value, 10) : null,
                  })
                }
              >
                <option value="">Select Project</option>
                {projects.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.project_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="formGroup">
              <label className="label">Assign To</label>
              <div
                className="custom-dropdown"
                onClick={() => setIsAssignDropdownOpen((prev) => !prev)}
              >
                <div className="selected-category">
                  {Array.isArray(taskData.assigned_to) && taskData.assigned_to.length > 0
                    ? members
                      .filter((member) => taskData.assigned_to.includes(member.id))
                      .map((selectedMember) => selectedMember.member_name)
                      .join(", ")
                    : "Select Members"}
                </div>

                {isAssignDropdownOpen && (
                  <div className="dropdown-options">
                    {members.map((member) => (
                      <div
                        key={member.id}
                        // className="dropdown-item"
                        onClick={() => handleMultiSelect(member.id)}
                        style={{
                          padding: '10px',
                          display: 'flex',
                          alignContent: 'flex-start',
                          gap: '8px'
                        }}
                      >
                        <input
                          type="checkbox"
                          id={`member-${member.id}`}
                          value={member.id}
                          checked={taskData.assigned_to.includes(member.id)}
                          onClick={(e) => e.stopPropagation()} // Prevent dropdown toggle on checkbox click
                          onChange={() => handleMultiSelect(member.id)} // For keyboard accessibility
                        />
                        <img
                          src={member.member_image || defaultimage} // Assuming each member object has an image_url property
                          alt={member.member_name}
                          style={{
                            width: '30px',
                            height: '30px',
                            borderRadius: '50%'
                          }}
                        />

                        <label htmlFor={`member-${member.id}`}>
                          {member.member_name}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

          </div>



          <div className="formColumn">
            <div className="formGroup">
              <label className="label">Start Date</label>
              <input
                className="input"
                type="date"
                id="start_date"
                name="start_date"
                value={
                  taskData.start_date && !isNaN(new Date(taskData.start_date).getTime())
                    ? new Date(taskData.start_date).toISOString().split("T")[0]
                    : ""
                }
                onChange={handleInputChange}
              />
            </div>

            <div className="formGroup">
              <label className="label">Due Date</label>
              <input
                className="input"
                type="date"
                id="due_date"
                name="due_date"
                value={
                  taskData.due_date && !isNaN(new Date(taskData.due_date).getTime())
                    ? new Date(taskData.due_date).toISOString().split("T")[0]
                    : ""
                }
                onChange={handleInputChange}
              />
            </div>

            <div className="formGroup">
              <label className="label">Description</label>
              <textarea
                className="input"
                id="description"
                rows={10}
                name="description"
                value={taskData.description || ""}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <h3 className="sectionTitle">Other Details</h3>

        <div className="formRow">
          <div className="formColumn">
            <div className="formGroup">
              <label className="label">Status<span className="required">*</span></label>
              <select
                className="input"
                value={taskData.status_id || ""}
                onChange={(e) =>
                  setTaskData({
                    ...taskData,
                    status_id: e.target.value ? parseInt(e.target.value, 10) : null,
                  })
                }
                required
              >
                <option value="" disabled>Select Status</option>
                {statuses.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.status}
                  </option>
                ))}
              </select>
            </div>

            <div className="formGroup">
              <label className="label">Priority</label>
              <select
                className="input"
                name="priority" // This must match the state key
                value={taskData.priority || ""} // Set the value from the state
                onChange={handleSelectChange} // Ensure this function updates the state
              >
                <option value="" disabled>Select Priority</option>
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
              </select>
            </div>


            <div className="formGroup">
              <label className="label">Label</label>
              <select
                className="input"
                value={taskData.label_id || ""}
                onChange={(e) =>
                  setTaskData({
                    ...taskData,
                    label_id: e.target.value ? parseInt(e.target.value, 10) : null,
                  })
                }
              >
                <option value="" disabled>Select Label</option>
                {labels.map((label) => (
                  <option key={label.id} value={label.id}>
                    {label.label_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="formGroup">
              <label className="label">Milestone</label>
              <select
                className="input"
                value={taskData.milestone_id || ""}
                onChange={(e) =>
                  setTaskData({
                    ...taskData,
                    milestone_id: e.target.value ? parseInt(e.target.value, 10) : null,
                  })
                }
              >
                <option value="" disabled>Select Milestone</option>
                {milestones.map((milestone) => (
                  <option key={milestone.id} value={milestone.id}>
                    {milestone.milestone_title}
                  </option>
                ))}
              </select>
            </div>

            <div className="formGroup">
              <label className="label">Private</label>
              <input
                type="checkbox"
                name="is_private"
                checked={taskData.is_private === 1} // Check if taskData.is_private equals 1
                onChange={(e) =>
                  setTaskData((prevData) => ({
                    ...prevData,
                    is_private: e.target.checked ? 1 : 0,
                  }))
                }
                className="checkboxInput"
              />
            </div>
          </div>

          <div className="formColumn">

            <div className="formGroup">
              <label className="label">Dependent Task</label>
              <select
                className="input"
                value={taskData.dependent_task_id || ""}
                onChange={(e) => {
                  const selectedTaskId = e.target.value;
                  setTaskData((prevData) => ({
                    ...prevData,
                    dependent_task_id: selectedTaskId, // Update the dependent task ID
                  }));
                }}
              >
                <option value="" disabled>
                  Select a task
                </option>
                {dependentTasks.map((task) => (
                  <option key={task.id} value={task.id}>
                    {task.name} {task.task_short_code ? `(${task.task_short_code})` : ""}
                  </option>
                ))}
              </select>
            </div>


            <div className="formGroup">
              <label className='label'>Task Points</label>
              <input
                className="input"
                type="number"
                id="task_point"
                name="task_point"
                value={taskData.task_point}
                onChange={handleInputChange}
                placeholder="Enter task points"  // Placeholder added
              />
            </div>

            {/* Repeat Checkbox */}
            <div className="formGroup">
              <label className="label">Repeat</label>
              <input
                type="checkbox"
                name="repeat"
                checked={taskData.repeat === 1} // Assuming repeat is 1 for checked
                onChange={(e) =>
                  setTaskData((prevData) => ({
                    ...prevData,
                    repeat: e.target.checked ? 1 : 0,
                  }))
                }
                className="checkboxInput"
              />
            </div>

            {/* Repeat inputs that appear when the checkbox is checked */}
            {taskData.repeat === 1 && (
              <>
                <div className="formGroup">
                  <label className="label">Repeat every</label>
                  <div className="categoryDropdownContainer">
                    <input
                      type="number"
                      name="repeat_count"
                      value={taskData.repeat_count || ""}
                      onChange={(e) =>
                        setTaskData((prevData) => ({
                          ...prevData,
                          repeat_count: e.target.value,
                        }))
                      }
                      className="input"
                      placeholder="Enter repeat count" // Placeholder added
                    />
                    <select
                      name="repeat_type"
                      value={taskData.repeat_type || ""}
                      onChange={(e) =>
                        setTaskData((prevData) => ({
                          ...prevData,
                          repeat_type: e.target.value,
                        }))
                      }
                      className="drop"
                    >
                      <option value="day">Day(s)</option>
                      <option value="week">Week(s)</option>
                      <option value="month">Month(s)</option>
                    </select>
                  </div>
                </div>

                <div className="formGroup">
                  <label className="label">Cycles</label>
                  <input
                    type="number"
                    name="repeat_cycles"
                    value={taskData.repeat_cycles || ""}
                    onChange={(e) =>
                      setTaskData((prevData) => ({
                        ...prevData,
                        repeat_cycles: e.target.value,
                      }))
                    }
                    className="input"
                    placeholder="Enter number of cycles" // Placeholder added
                  />
                </div>
              </>
            )}

            <div className="formGroup">
              <label className="label">Estimate Minutes</label>
              <input
                className="input"
                type="text"
                value={taskData.estimate_minutes || ""}
                onChange={handleInputChange}
              />
            </div>

            <div className="formGroup">
              <label className="label">Billable</label>
              <input
                type="checkbox"
                name="billable"
                checked={taskData.billable === 1} // Check if taskData.billable equals 1
                onChange={(e) =>
                  setTaskData((prevData) => ({
                    ...prevData,
                    billable: e.target.checked ? 1 : 0,
                  }))
                }
                className="checkboxInput"
              />
            </div>
          </div>
        </div>
      </section>


      <div className="formButtons">
        <button className='color-button' onClick={handleSubmit}>Save</button>
        <button className="color-button" onClick={handleClose}>
          Cancel
        </button>
      </div>
    </div>

  );
};




export default EditTaskModal;















































