import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './../Components/Main.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Swal from 'sweetalert2'; // Import SweetAlert
import { BiSortAlt2 } from 'react-icons/bi';
import noDataImage from '../assets/NoDataFound.png';
import Modal from '@mui/material/Modal';
import iconImage from '../assets/Icon.png';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';


const Leave = () => {
  const [leaves, setLeaves] = useState([]);
  const [filteredLeaves, setFilteredLeaves] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [actionMenuVisible, setActionMenuVisible] = useState(null); // Control action menu per leaveId
  const [selectedLeaveId, setSelectedLeaveId] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedLeaves, setSelectedLeaves] = useState({});
  const [action, setAction] = useState('');
  const [permissions, setPermissions] = useState(null); // Store permissions from API
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc'); // 'asc' for ascending, 'desc' for descending
  const actionMenuRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [reasonModalVisible, setReasonModalVisible] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');

  const [leaveTypes, setLeaveTypes] = useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState('');
  const [duration, setDuration] = useState('full day');
  const [leaveDate, setLeaveDate] = useState('');
  const [reason, setReason] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();




  //...............Add New Leave Modal Section...................................................................................


  useEffect(() => {
    const fetchLeaveTypes = async () => {
      const token = localStorage.getItem('authToken');
      try {
        const response = await fetch('https://api.myntask.com/leave/leave_type', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch leave types');
        }


        const data = await response.json();
        setLeaveTypes(data);
      } catch (error) {
        console.error('Error fetching leave types:', error);
      }
    };


    fetchLeaveTypes();
  }, []);








  const handleSubmit = async () => {

    if (
      !selectedLeaveType || // Check if leave type is selected
      !duration || // Check if duration is selected
      (duration === 'multiple' && (!startDate || !endDate)) || // Check start and end date for multiple days
      (duration !== 'multiple' && !leaveDate) || // Check leave date for other durations
      !reason // Check if reason is provided
    ) {
      // Show validation error using SweetAlert
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please fill out all required fields.',
        timer: 2000, // Auto-close after 2 seconds
        showConfirmButton: false, // Hide confirm button
      });
      return; // Stop submission if validation fails
    }

    const token = localStorage.getItem('authToken');


    // Construct leaveData object with null checks
    const leaveData = {
      leave_type_id: selectedLeaveType || null, // Send null if not selected
      duration: duration || null,               // Send null if not selected
      leave_date: duration === 'multiple' ? null : leaveDate || null,
      start_date: duration === 'multiple' ? startDate || null : null,
      end_date: duration === 'multiple' ? endDate || null : null,           // Send null if not provided
      reason,                    // Send null if not provided
    };


    console.log('Leave data:', leaveData); // Log the leaveData for debugging
    setIsLoading(true);

    try {
      const response = await fetch('https://api.myntask.com/leave/save', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(leaveData),
      });




      // Check for a successful response
      if (!response.ok) {
        const errorText = await response.text(); // Retrieve error response for logging
        console.error('Error response from server:', errorText); // Log the error response
        throw new Error('Failed to save leave request'); // Throw an error for handling
      }


      setShowModal(false);
      fetchLeaveData();

      // Show success message using SweetAlert without confirmation
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Leave request submitted successfully!',
        timer: 2000, // Auto-close after 3 seconds
        showConfirmButton: false, // Hide the confirm button
      });
      window.location.reload();
      setIsLoading(false);
    } catch (error) {
      console.error('Error saving leave request:', error); // Log the error



      // setShowModal(false);
      // Show error message using SweetAlert without confirmation
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to submit leave request.',
        timer: 2000, // Auto-close after 3 seconds
        showConfirmButton: false // Hide the confirm button
      });
    }
  };


  const fetchLeaveData = async (startDate, endDate) => {
    const token = localStorage.getItem('authToken');
    const apiUrl = `https://api.myntask.com/leave/list?start_date=${startDate}&end_date=${endDate}`;


    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });


      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log("API Response: ", data); // Log the response data
      setLeaves(data.data); // Set the leaves from response data
      setFilteredLeaves(data.data); // Set filtered leaves as well


      // Check and log permissions
      console.log("Permissions: ", data.leave_permission); // Log the permissions object
      setPermissions(data.leave_permission || {}); // Ensure permissions is an object
      setIsLoading(false);

    } catch (error) {
      console.error('Error fetching leave data:', error);
      // setError('Failed to fetch leave data.');
      setIsLoading(false);
    }
  };


  useEffect(() => {
    const lowercasedTerm = searchTerm.toLowerCase();
    const filtered = leaves.filter(
      (leave) =>
        leave.name.toLowerCase().includes(lowercasedTerm) ||
        leave.designation.toLowerCase().includes(lowercasedTerm)
    );
    setFilteredLeaves(filtered);
  }, [searchTerm, leaves]);



  const handleViewReason = (reason) => {
    setSelectedReason(reason);
    setReasonModalVisible(true);
  };



  // Sorting function
  const handleSort = (field) => {
    const newDirection = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(newDirection);




    const sortedLeaves = [...filteredLeaves].sort((a, b) => {
      if (field === 'leave_date') {
        const dateA = new Date(a[field]);
        const dateB = new Date(b[field]);
        return newDirection === 'asc' ? dateA - dateB : dateB - dateA;
      }
      else {
        if (newDirection === 'asc') {
          return a[field] > b[field] ? 1 : -1;
        } else {
          return a[field] < b[field] ? 1 : -1;
        }
      }
    });




    setFilteredLeaves(sortedLeaves);
  };








  useEffect(() => {
    const sortedLeaves = [...leaves].sort((a, b) => new Date(b.leave_date) - new Date(a.leave_date));
    setFilteredLeaves(sortedLeaves);
  }, [leaves]);





  const handleCheckboxChange = (leaveId) => {
    setSelectedLeaves((prevState) => ({
      ...prevState,
      [leaveId]: !prevState[leaveId], // Toggle the selected state
    }));




    setSelectedLeaveId(leaveId); // Set the selected leave ID
    setDropdownVisible(true); // Show the dropdown
  };



  useEffect(() => {
    fetchLeaveData(startDate, endDate);
  }, [startDate, endDate]);





  const toggleActionMenu = (leaveId) => {
    if (actionMenuVisible === leaveId) {
      setActionMenuVisible(null); // Close the menu if it's already open for the same row
    } else {
      setActionMenuVisible(leaveId); // Set the current leaveId to display the action menu
    }
    setSelectedLeaveId(leaveId); // Set the selected leave ID
  };





  const handleDropdownClose = () => {
    setActionMenuVisible(null); // Close the action menu
    setSelectedLeaveId(null);
    setAction(''); // Reset the action after handling it
  };



  const handleDelete = async (leaveId) => {
    const token = localStorage.getItem('authToken');




    // Show confirmation dialog using SweetAlert
    const result = await Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'This action cannot be undone!',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });




    // If the user confirmed the deletion
    if (result.isConfirmed) {
      try {
        const response = await fetch(`https://api.myntask.com/leave/leave_delete`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ leave_id: leaveId }),
        });




        if (!response.ok) {
          throw new Error('Failed to delete leave');
        }




        // Remove the deleted leave from the state
        setLeaves(leaves.filter((leave) => leave.leave_id !== leaveId));
        setFilteredLeaves(filteredLeaves.filter((leave) => leave.leave_id !== leaveId));

        fetchLeaveData();
        // Show success message using SweetAlert
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: 'Leave deleted successfully!',
          timer: 2000, // Auto-close after 2 seconds
          showConfirmButton: false, // Hide the confirm button
        });
      } catch (error) {
        console.error('Error deleting leave:', error);




        // Show error message using SweetAlert
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to delete leave. Please try again.',
        });
      }
    }
  };


  const updateLeaveStatus = async (leaveId, status) => {
    const token = localStorage.getItem('authToken');

    try {
      const response = await fetch(
        'https://api.myntask.com/leave/leave_status_update',
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            leave_id: leaveId,
            status_name: status,
          }),
        }
      );




      if (!response.ok) {
        throw new Error(`Failed to update leave status to ${status}`);
      }




      // Update the leaves state
      const updatedLeaves = leaves.map((leave) =>
        leave.leave_id === leaveId ? { ...leave, status: status } : leave
      );


      setLeaves(updatedLeaves);
      setFilteredLeaves(updatedLeaves);


      // Show success message using SweetAlert
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: `Leave status updated to ${status}`,
        timer: 2000, // Auto-close after 2 seconds
        showConfirmButton: false, // Hide the confirm button
      });




    } catch (error) {
      console.error(`Error updating leave status to ${status}:`, error);




      // Show error message using SweetAlert
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Failed to update leave status to ${status}.`,
        timer: 2000, // Auto-close after 2 seconds
        showConfirmButton: false, // Hide the confirm button
      });




      setError(`Failed to update leave status to ${status}.`);
    }
  };


  const handleApprove = () => {
    updateLeaveStatus(selectedLeaveId, 'approved');
    handleDropdownClose();
  };


  const handleReject = () => {
    updateLeaveStatus(selectedLeaveId, 'rejected');
    handleDropdownClose();
    console.log('updating status to rejected of ', selectedLeaveId);
  };



  const handleAction = (action) => {
    switch (action) {
      case 'Edit':
        if (selectedLeaveId) {
          navigate(`/edit-leave/${selectedLeaveId}`); // Use the correct ID for navigation
        } else {
          alert('Please select a leave to edit.');
        }
        break;
      case 'Delete':
        handleDelete(selectedLeaveId); // Call delete function with ID
        break;
      case 'Approve':
        handleApprove(); // Approve function
        break;
      case 'Rejected':
        handleReject(); // Reject function
        break;
      default:
        break;
    }
    setActionMenuVisible(null); // Close the menu after action
  };


  // Close action menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionMenuRef.current && !actionMenuRef.current.contains(event.target)) {
        setActionMenuVisible(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [actionMenuRef]);


  const handleClearFilters = () => {
    setSearchTerm('');
    setStartDate('');
    setEndDate('');
    fetchLeaveData('', '');
  };








  const handleModalOpen = () => {
    setShowModal(true);
  }


  const handleModalClose = () => {
    setShowModal(false);


  }



  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }




  return (
    <>
      <div className="main-container">

        <div className="top-controls">
          <h1 className='title'>Leave
            <div className="underline"></div>
          </h1>

          <div className="buttons-row">
            {dropdownVisible && (
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <select onChange={(e) => setAction(e.target.value)} value={action} className="color-button">
                  <option value="">Select Action</option>
                  {permissions?.edit_leave && <option value="Edit">Edit</option>}
                  {permissions?.delete_leave && <option value="Delete">Delete</option>}
                  {permissions?.approve_or_reject_leaves && <option value="Approve">Approve</option>}
                  {permissions?.approve_or_reject_leaves && <option value="Rejected">Reject</option>}
                </select>
                <button onClick={() => handleAction(action)} className="color-button">
                  Apply
                </button>
              </div>
            )}
            {permissions?.add_leave && (
              <button className="color-button" onClick={handleModalOpen}>
                New Leave
              </button>
            )}
          </div>
        </div>


        <div className="top-controls">
          <div className="filters-and-search">
            <button className="border-button" onClick={handleClearFilters}>
              Clear Filters
            </button>
            <input
              id="start-date"
              className="border-button"
              style={{ width: '150px' }}
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <input
              id="end-date"
              className="border-button"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              style={{ width: '150px' }}
            />
            <input
              id="search"
              className="searchbar"
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search"
            />
          </div>
        </div>


        {error && <p className="error-message">{error}</p>}


        {filteredLeaves.length > 0 ? (

          <table >
            <thead>
              <tr>
                <th>Select</th>
                <th onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>
                  Name <BiSortAlt2 />
                  {sortField === 'name' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('leave_date')} style={{ cursor: 'pointer' }}>
                  Leave Date <BiSortAlt2 />
                  {sortField === 'leave_date' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('duration')} style={{ cursor: 'pointer' }}>
                  Duration <BiSortAlt2 />
                  {sortField === 'duration' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('status')} style={{ cursor: 'pointer' }}>
                  Leave Status <BiSortAlt2 />
                  {sortField === 'status' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('type_name')} style={{ cursor: 'pointer' }}>
                  Leave Type <BiSortAlt2 />
                  {sortField === 'type_name' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th onClick={() => handleSort('paid')} style={{ cursor: 'pointer' }}>
                  Paid <BiSortAlt2 />
                  {sortField === 'paid' && (sortDirection === 'asc' ? '▲' : '▼')}
                </th>
                <th>Reason</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredLeaves.map((leave, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={!!selectedLeaves[leave.leave_id]}
                      onChange={() => handleCheckboxChange(leave.leave_id)}
                    />
                  </td>
                  <td>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={leave.image}
                        alt={leave.name}
                        className="image"
                        style={{ marginRight: '10px' }}
                      />
                      <div>
                        <span style={{ fontWeight: 600 }}>
                          {leave.name ? leave.name.charAt(0).toUpperCase() + leave.name.slice(1).toLowerCase() : 'N/A'}
                        </span>
                        <p style={{ margin: 0 }}>
                          {leave.designation ? leave.designation.charAt(0).toUpperCase() + leave.designation.slice(1).toLowerCase() : 'N/A'}
                        </p>

                      </div>
                    </div>
                  </td>
                  <td >{new Date(leave.leave_date).toLocaleDateString()}</td>
                  {/* <td >{leave.duration.charAt(0).toUpperCase() + leave.duration.slice(1).toLowerCase()}
                  </td> */}
                  <td>{leave.duration ? leave.duration.charAt(0).toUpperCase() + leave.duration.slice(1).toLowerCase() : 'N/A'}</td>
                  <td>
                    <span className="status-container">
                      <span
                        className="status-dot"
                        style={{
                          backgroundColor: leave.status.toLowerCase() === 'approved'
                            ? 'green'    // Color for approved
                            : leave.status.toLowerCase() === 'rejected'
                              ? 'red'      // Color for rejected
                              : leave.status.toLowerCase() === 'pending'
                                ? 'orange'   // Color for pending
                                : 'gray'     // Default color for unknown status
                        }}
                      ></span>
                      {leave.status.charAt(0).toUpperCase() + leave.status.slice(1)}
                    </span>
                  </td>


                  <td>{leave.type_name.charAt(0).toUpperCase() + leave.type_name.slice(1).toLowerCase()}
                  </td>
                  <td >{leave.paid ? 'Yes' : 'No'}</td>
                  <td>
                    <button className="view-button" onClick={() => handleViewReason(leave.reason || "No reason provided.")}>
                      View
                    </button>
                  </td>
                  <td>
                    <div className="action-container" style={{ position: 'relative' }}>
                      <div
                        className="action-dots"
                        onClick={() => toggleActionMenu(leave.leave_id)}
                        style={{ cursor: 'pointer' }}
                      >
                        <MoreVertIcon />
                      </div>
                      {actionMenuVisible === leave.leave_id && (
                        <div className="action-menu" ref={actionMenuRef}>
                          {permissions?.edit_leave && (
                            <div className="action-item" onClick={() => handleAction('Edit')}>
                              Edit
                            </div>
                          )}
                          {permissions?.delete_leave && (
                            <div className="action-item" onClick={() => handleAction('Delete')}>
                              Delete
                            </div>
                          )}
                          {permissions?.approve_or_reject_leaves && (
                            <>
                              <div className="action-item" onClick={() => handleAction('Approve')}>
                                Approve
                              </div>
                              <div className="action-item" onClick={() => handleAction('Rejected')}>
                                Reject
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="no-data-container">
            <img src={noDataImage} alt="No data available" className="no-data-image" />
            <p className="no-data-text">No data available</p>
          </div>
        )}

      </div>

      <Modal open={showModal} onClose={handleModalClose} >
        <div className='modal-overlay-deal'>
          <div className="modal-content-deal">

            <img
              src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
              alt="Close"
              style={{
                position: "absolute",
                top: "16px",
                right: "16px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={handleModalClose}
            />
            <h1 className='modal-title-deal'>Add New Leave</h1>

            {/* <h3 className="sectionTitle">Leave Details</h3> */}
            <div className="form-grid-deal" style={{ marginTop: '40px' }}>
              <div className="form-field-group-deal">
                <label className="form-label-deal">
                  Leave Type
                </label>
                <select
                  id="leave-type"
                  value={selectedLeaveType}
                  onChange={(e) => setSelectedLeaveType(e.target.value)}
                  disabled={leaveTypes.length === 0}
                  className="form-input-deal"
                >
                  <option value="" disabled>
                    Select a type
                  </option>
                  {leaveTypes.length > 0 ? (
                    leaveTypes.map((leaveType) => (
                      <option key={leaveType.id} value={leaveType.id}>
                        {leaveType.type_name}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      No leave types available
                    </option>
                  )}
                </select>


              </div>




              <div className="form-field-group-deal">
                <label className="form-label-deal">Duration</label>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                  <label className="form-label-deal">
                    <input
                      type="radio"
                      value="full day"
                      checked={duration === 'full day'}
                      onChange={(e) => setDuration(e.target.value)}
                      className="form-input-deal"
                    />
                    Full Day
                  </label>
                  <label className="form-label-deal">
                    <input
                      type="radio"
                      value="first half"
                      checked={duration === 'first half'}
                      onChange={(e) => setDuration(e.target.value)}
                      className="form-input-deal"
                    />
                    First Half
                  </label>
                  <label className="form-label-deal">
                    <input
                      type="radio"
                      value="second half"
                      checked={duration === 'second half'}
                      onChange={(e) => setDuration(e.target.value)}
                      className="form-input-deal"
                    />
                    Second Half
                  </label>
                  <label className="form-label-deal">
                    <input
                      type="radio"
                      value="multiple"
                      checked={duration === 'multiple'}
                      onChange={(e) => setDuration(e.target.value)}
                      className="form-input-deal"
                    />
                    Multiple
                  </label>
                </div>
              </div>

              {/* <div className="formColumn"> */}
              {duration === 'multiple' ? (
                <>
                  <div className="form-field-group-deal" >
                    <label className="form-label-deal">
                      Start Date :
                    </label>
                    <input
                      type="date"
                      id="start-date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      className="form-input-deal"
                    />
                    &nbsp;&nbsp;
                    <label className="form-label-deal">
                      End Date :
                    </label>
                    <input
                      type="date"
                      id="end-date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      className="form-input-deal"
                    />
                  </div>


                </>
              ) : (
                <div className="form-field-group-deal" >
                  <label className="form-label-deal">
                    Leave Date :
                  </label>
                  <input
                    type="date"
                    id="leave-date"
                    value={leaveDate}
                    onChange={(e) => setLeaveDate(e.target.value)}
                    className="form-input-deal"
                  />
                </div>
              )}


              <div className="form-field-group-deal">
                <label className="form-label-deal" >
                  Reason
                </label>
                <input
                  type="text"
                  id="reason"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  className="form-input-deal"
                />
              </div>
              {/* </div> */}
            </div>


            <div className="form-button-group-deal">
              <button onClick={handleSubmit} className="submit-button-deal">
                Submit
              </button>
            </div>
            {/* <button onClick={handleModalClose} className="color-button">Cancel</button> */}
          </div>
        </div>
      </Modal>


      {reasonModalVisible && (
        <div className="modal-overlay-deal">
          <div className="modal-content-deal">
            <h2>Reason</h2>
            <p>{selectedReason || 'No reason provided.'}</p>
            <button
              style={{
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                padding: '10px 10px',
                borderRadius: '4px',
                cursor: 'pointer',
                marginTop: '30px',
                display: 'block',
                width: '100px',
                margin: '20px auto 0 auto',
              }}
              onClick={() => setReasonModalVisible(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}


    </>
  );
};


export default Leave;

