import React, { useEffect, useState } from "react";
import { MdOutlineStarHalf, MdOutlineStarPurple500 } from 'react-icons/md';
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom"; // Import useNavigate instead of useHistory
import "./Attendance.css";
import defaultImage from "../assets/vector.jpeg";
import { FaCheck } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import { FaStarHalfAlt } from "react-icons/fa";
import noDataImage from '../assets/NoDataFound.png';
import { Modal } from "@mui/material";
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';

import DatePicker from 'react-datepicker';
import Swal from 'sweetalert2';
import iconImage from '../assets/Icon.png';








const Attendance = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState("");
  const [designations, setDesignations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const navigate = useNavigate(); // Use useNavigate for navigation
  const [showModal, setShowModal] = useState(false);
  const [isAttendanceOpen, setAttendanceOpen] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const [attendanceDetails, setAttendanceDetails] = useState(null);
  const [permission, setPermissions] = useState({});


  // const [departments, setDepartments] = useState([]);
  // const [selectedDepartment, setSelectedDepartment] = useState('');
  const [employees, setEmployees] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [locations, setLocations] = useState([]); // State for locations
  const [location, setLocation] = useState(''); // For selected location
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [clockIn, setClockIn] = useState('10:30');
  const [clockOut, setClockOut] = useState('08:00');
  const [late, setLate] = useState('no');
  const [halfDay, setHalfDay] = useState('no');
  const [workingFrom, setWorkingFrom] = useState('Office');
  const [overwrite, setOverwrite] = useState(false);
  const [isLoading, setIsLoading] = useState(true);







  //.....................................Mark Attendance Modal ........................................................................................


  const token = localStorage.getItem('authToken'); // Get the token from localStorage




  // Fetch departments from the API
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await fetch('https://api.myntask.com/employee/department', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch department data');
        }

        const data = await response.json();
        setDepartments(data); // Set departments from the API response
      } catch (error) {
        console.error('Error fetching departments:', error);
        setError('Failed to fetch department data');
      }
    };

    fetchDepartments();
  }, [token]);



  // Fetch locations from the API
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await fetch('https://api.myntask.com/attendance/locations', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch location data');
        }

        const data = await response.json();
        setLocations(data); // Set locations from the API response
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };

    fetchLocations();
  }, [token]);






  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        // Construct the URL dynamically based on the selectedDepartment
        const url = selectedDepartment
          ? `https://api.myntask.com/employee/member?department_id=${selectedDepartment}`
          : 'https://api.myntask.com/employee/member'; // Fetch all employees if no department is selected


        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch employee data');
        }


        const data = await response.json();
        console.log('Fetched Employees:', data);




        setEmployees(data);


      } catch (error) {
        console.error('Error fetching employees:', error);


      }
    };


    fetchEmployees();
  }, [selectedDepartment, token]); // Trigger fetch whenever selectedDepartment or token changes








  const toggleDropdown = () => {
    console.log("Dropdown toggled");
    setShowDropdown((prev) => !prev);
  };






  const handleSubmit = async (e) => {
    e.preventDefault();


    const formattedStartDate = startDate ? startDate.toISOString().split('T')[0] : null;
    const formattedEndDate = endDate ? endDate.toISOString().split('T')[0] : null;


    if (!formattedStartDate || !formattedEndDate) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please select valid start and end dates.',
        timer: 1500,
        showConfirmButton: false,
        backdrop: true,
      });
      return;
    }


    const payload = {
      user_id: selectedEmployee, // Pass the selected employee ID directly
      location_id: location,
      clock_in_time: clockIn ? `${clockIn}:00` : null, // Ensure time is in HH:MM:SS format
      clock_out_time: clockOut ? `${clockOut}:00` : null, // Ensure time is in HH:MM:SS format
      late,
      half_day: halfDay,
      working_from: workingFrom.toLowerCase(),
      from: formattedStartDate, // Send properly formatted start date
      to: formattedEndDate,     // Send properly formatted end date
      attendance_overwrite: overwrite ? 'yes' : 'no',
    };

    setIsLoading(true);

    try {
      const response = await fetch('https://api.myntask.com/attendance/mark_attendance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });


      const contentType = response.headers.get('content-type');
      let responseData;


      if (response.ok) {
        if (contentType && contentType.includes('application/json')) {
          responseData = await response.json();  // Handle JSON response
        } else {
          responseData = await response.text();  // Handle text response
        }


        console.log('Attendance marked successfully:', responseData);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Attendance marked successfully!',
          timer: 1500,
          showConfirmButton: false,
        });


        setShowModal(false);


      } else {
        // Handle error case but don't show error toast
        responseData = await response.text();  // If there's an error, still read the response
        console.error('Error marking attendance:', responseData);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to mark attendance. Please try again.',
          timer: 1500,
          showConfirmButton: false,
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error marking attendance:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An unexpected error occurred. Please try again.',
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };






  // const handleCancel = () => {
  //   navigate('/attendance'); // Replace '/attendance' with your actual route
  // };


  //......................................Close Modal .................................................................................................






  const handleCellClick = async (attendanceData) => {
    if (attendanceData.attendance_id) {
      setSelectedAttendance(attendanceData);
      setAttendanceOpen(true);


      // Fetch attendance details
      try {
        const response = await fetch(`https://api.myntask.com/attendance/attendance_detail/${attendanceData.attendance_id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
          },
        });




        if (!response.ok) {
          throw new Error('Failed to fetch attendance details');
        }


        const data = await response.json();
        setAttendanceDetails(data);
      } catch (error) {
        console.error("Error fetching attendance details:", error);
        setAttendanceDetails(null);
      }
    }
  };







  const closeModal = () => {
    setAttendanceOpen(false);
    setSelectedAttendance(null);
    setAttendanceDetails(null); // Reset attendance details
  };




  const handleMarkAttendanceClick = () => {
    setShowModal(true);
  };




  const handleCloseModal = () => {
    setShowModal(false);
  }






  useEffect(() => {
    const fetchData = async (url, setter) => {
      const token = localStorage.getItem("authToken");
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setter(data || []);
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        setter([]);
      }
    };






    fetchData("https://api.myntask.com/employee/designation", setDesignations);
    fetchData("https://api.myntask.com/employee/department", setDepartments);
    fetchData("https://api.myntask.com/employee/member", setMembers);
  }, []);






  useEffect(() => {
    const fetchAttendanceData = async () => {
      const token = localStorage.getItem("authToken");


      if (!token) {
        setError("No token found. Please log in.");
        return;
      }

      try {
        const url = `https://api.myntask.com/attendance/list?employee_id=${selectedMember}&department_id=${selectedDepartment}&designation_id=${selectedDesignation}&month=${selectedMonth}&year=${selectedYear}`;


        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });


        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(`Failed to fetch attendance data: ${response.status} - ${errorMessage}`);
        }


        const data = await response.json();
        setAttendanceData(data.result);
        setFilteredData(data.result);
        setPermissions(data.attendance_permission || {});  // Default to empty object if not available
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
        setError(`Failed to fetch attendance data: ${error.message}`);
        setIsLoading(false);
      }
    };


    fetchAttendanceData();
  }, [selectedDesignation, selectedDepartment, selectedMember, selectedMonth, selectedYear]);










  useEffect(() => {
    const filtered = attendanceData.filter((entry) => {
      const nameMatch = entry.name.toLowerCase().includes(searchQuery.toLowerCase());
      const attendanceMatch = Object.values(entry.attendance).some((day) =>
        day.present.toLowerCase().includes(searchQuery.toLowerCase())
      );
      return nameMatch || attendanceMatch;
    });
    setFilteredData(filtered);
  }, [searchQuery, attendanceData]);





  const handleLocationClick = (locationUrl) => {
    if (locationUrl) {
      window.open(locationUrl, "_blank");
    } else {
      alert("Location URL is not available.");
    }
  };




  const getAttendanceIcon = (attendanceStatus, dayData) => {
    // Check if dayData is defined and dayKey exists
    if (dayData && dayData.dayKey && dayData.dayKey.includes('Sun')) {
      return <MdOutlineStarPurple500 className="attendance-icon sunday-icon" />;
    }




    // Check if it's a half-day
    if (dayData && dayData.half_day === "yes") {
      return <FaStarHalfAlt className="attendance-icon half-day-icon" />;
    }




    // Return normal attendance icons based on attendance status
    if (attendanceStatus === "yes") {
      return <FaCheck className="attendance-icon present-icon" />;
    } else if (attendanceStatus === "no") {
      return <RxCross2 className="attendance-icon absent-icon" />;
    } else {
      return <span>--</span>;
    }
  };






  const handleMapView = () => {
    navigate("/map"); // Use navigate instead of history.push
  };




  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }



  return (
    <>
      <div className="map-container">
        <ToastContainer />
        <div className="top-controls">
          <h1 className='title'>Attendance
            <div className="underline"></div>
          </h1>


          <div className="buttons-row">
            <button onClick={handleMapView} className="color-button">
              Map View
            </button>
            {permission.add_attendance === 'all' && (
              <button className="color-button" onClick={handleMarkAttendanceClick}>
                Mark Attendance
              </button>
            )}
          </div>
        </div>
        {/* Search Bar and Dropdown Filters */}
        <div className="top-controls">
          <div className="filters-and-search">


            <select
              className="border-button"
              value={selectedDesignation}
              onChange={(e) => setSelectedDesignation(e.target.value)}
            >
              <option value="">Designation</option>
              {designations.map((designation) => (
                <option key={designation.id} value={designation.id}>
                  {designation.name}
                </option>
              ))}
            </select>
            <select
              className="border-button"
              value={selectedDepartment}
              onChange={(e) => setSelectedDepartment(e.target.value)}
            >
              <option value="">Department</option>
              {departments.map((department) => (
                <option key={department.id} value={department.id}>
                  {department.department}
                </option>
              ))}
            </select>
            <select
              className="border-button"
              value={selectedMember}
              onChange={(e) => setSelectedMember(e.target.value)}
            >
              <option value="">Employee</option>
              {members.map((member) => (
                <option key={member.id} value={member.id}>
                  {member.member_name}
                </option>
              ))}
            </select>
            <select
              className="border-button"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            >
              <option value="">Month</option>
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {new Date(0, i).toLocaleString("en", { month: "long" })}
                </option>
              ))}
            </select>
            <select
              className="border-button"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              <option value="">Year</option>
              {Array.from({ length: 5 }, (_, i) => (
                <option key={i} value={2024 - i}>
                  {2024 - i}
                </option>
              ))}
            </select>
            <input
              type="text"
              className="searchbar"
              placeholder="Search by name..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>


        <div
          className="attendance-grid"
          style={{
            '--attendance-column-count': `1fr repeat(${filteredData.length > 0 ? filteredData[0].totalDays : 0}, 1fr)`
          }}
        >
          <div className="attendance-header-row">
            <div className="attendance-header-cell">Employee</div>
            {filteredData.length > 0 &&
              Object.keys(filteredData[0].attendance).map((dayKey, index) => (
                <div key={index} className="attendance-header-cell">
                  {dayKey}
                </div>
              ))}
          </div>
          {filteredData.length > 0 ? (
            filteredData.map((entry, index) => (
              <div key={index} className="attendance-row">
                <div className="attendance-cell name-cell">
                  <img
                    src={entry.image || defaultImage}
                    alt={entry.name}
                    className="image"
                  />
                  <div>
                    <div>{entry.name}</div>
                    <div className="employee-title">{entry.title}</div>
                  </div>
                </div>
                {Object.keys(entry.attendance).map((dayKey, dayIndex) => {
                  const attendanceData = entry.attendance[dayKey];
                  return (
                    <div
                      key={dayIndex}
                      className="attendance-cell"
                      onClick={() => handleCellClick(attendanceData)} // Click handler
                    >
                      {getAttendanceIcon(attendanceData?.present, { dayKey, ...attendanceData })}
                    </div>
                  );
                })}
              </div>
            ))
          ) : (
            <div className="no-data-container">
              <img src={noDataImage} alt="No data available" className="no-data-image" />
              <p className="no-data-text">No data available</p>
            </div>
          )}
        </div>












        {/* Modal for attendance details */}
        {isAttendanceOpen && (
          <div className="attendanceModal">
            <div className="attendanceModal-content">


              {attendanceDetails ? (
                <>
                  <div className="top-controls">
                    <h2 className="title">Attendance Details</h2>
                    <img
                      src={attendanceDetails.user_image}
                      alt={attendanceDetails.user_name}
                      className="image"
                    />
                  </div>
                  <div className="details-container">
                    <div className="details-row">
                      <strong>Name:</strong>
                      <span>{attendanceDetails.user_name}</span>
                    </div>
                    <div className="details-row">
                      <strong>Designation:</strong>
                      <span>{attendanceDetails.designation}</span>
                    </div>
                    <div className="details-row">
                      <strong>Date:</strong>
                      <span>{attendanceDetails.date || "N/A"}</span>
                    </div>
                    <div className="details-row">
                      <strong>Location:</strong>
                      <span>{attendanceDetails.location || "N/A"}</span>
                    </div>
                    <div className="details-row">
                      <strong>Clock In:</strong>
                      <span>{attendanceDetails.clock_in || "N/A"}</span>
                    </div>
                    <div className="details-row">
                      <strong>Clock Out:</strong>
                      <span>{attendanceDetails.clock_out || "N/A"}</span>
                    </div>
                    <div className="details-row">
                      <strong>Total Time:</strong>
                      <span>{attendanceDetails.total_time || "N/A"}</span>
                    </div>
                  </div>
                  <button className="color-button" onClick={closeModal}>Close</button>
                </>
              ) : (
                <p>Loading attendance details...</p>
              )}


            </div>
          </div>
        )}
      </div>


      <Modal className="modal-overlay-deal" open={showModal} onClose={handleCloseModal} >
        <div className="modal-content-deal">
          <ToastContainer />


          <h1 className="modal-title-deal">Mark Attendance</h1>
          <img
            src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
            alt="Close"
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              border: "none",
              cursor: "pointer",
            }}
            onClick={handleCloseModal}
          />




          <div className="form-grid-deal">


            {/* <div className="formColumn"> */}
            <div className="form-field-group-deal">
              <label className="form-label-deal">Department</label>
              <select
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
                className="form-input-deal"
              >
                <option value="">-- Select Department --</option>
                {departments.map((department) => (
                  <option key={department.id} value={department.id}>
                    {department.department}
                  </option>
                ))}
              </select>
            </div>


            <div className="form-field-group-deal">
              <label className="form-label-deal">Employees *</label>
              <select
                value={selectedEmployee || ''}
                onChange={(e) => setSelectedEmployee(e.target.value)} // Directly set the selected ID
                className="form-input-deal"
              >
                <option value="">-- Select Employee --</option>
                {employees.map((employee) => (
                  <option key={employee.id} value={employee.id}>
                    {employee.member_name}
                  </option>
                ))}
              </select>
            </div>








            <div className="form-field-group-deal">
              <label className="form-label-deal">Working From *</label>
              <select className="form-input-deal" value={workingFrom} onChange={(e) => setWorkingFrom(e.target.value)} required>
                <option value="Office">Office</option>
                <option value="Home">Home</option>
              </select>
            </div>




            {/* Location Dropdown */}
            <div className="form-field-group-deal">
              <label className="form-label-deal">Location</label>
              <select
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="form-input-deal"
              >
                <option value="">-- Select Location --</option>
                {locations.map((loc) => (
                  <option key={loc.id} value={loc.id}>
                    {loc.location}
                  </option>
                ))}
              </select>
            </div>








            {/* Date and Time Selection */}


            <div className="form-field-group-deal">
              <label className="form-label-deal">Clock In *</label>
              <input
                type="text"
                value={clockIn}
                onChange={(e) => setClockIn(e.target.value)}
                required
                className="form-input-deal"
              />
            </div>




            <div className="form-field-group-deal">
              <label className="form-label-deal">Clock Out</label>
              <input
                type="text"
                value={clockOut}
                onChange={(e) => setClockOut(e.target.value)}
                className="form-input-deal"
              />
            </div>




            {/* </div> */}




            {/* <div className="formColumn"> */}




            <div className="form-field-group-deal">
              <label className="form-label-deal">Mark Attendance By</label>


              <DatePicker
                selected={startDate}
                onChange={(dates) => {
                  const [start, end] = dates;
                  setStartDate(start);
                  setEndDate(end);




                }}


                className="form-input-deal"
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline={false}
              />




            </div>




            {/* Late and Half Day options */}
            <div className="form-field-group-deal">
              <label className="form-label-deal">Late</label>
              <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '60%' }}>
                <div>
                  <input
                    type="radio"
                    name="late"
                    value="yes"
                    checked={late === 'yes'}
                    className="form-input-deal"
                    onChange={() => setLate('yes')}
                  />
                  <label className="form-label-deal">Yes</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="late"
                    value="no"
                    checked={late === 'no'}
                    className="form-input-deal"
                    onChange={() => setLate('no')}
                  />
                  <label className="form-label-deal">No</label>
                </div>
              </div>
            </div>






            <div className="form-field-group-deal" >
              <label className="form-label-deal">Attendance Overwrite</label>
              <input
                type="checkbox"
                checked={overwrite}
                onChange={() => setOverwrite(!overwrite)}
                className="checkboxInput"
              />


            </div>






            <div className="form-field-group-deal" >


              <label className="form-label-deal">Half Day</label>
              <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '60%' }}>
                <div>
                  <input
                    type="radio"
                    name="halfDay"
                    value="yes"
                    checked={halfDay === 'yes'}
                    onChange={() => setHalfDay('yes')}
                  />
                  <label className="form-label-deal">Yes</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="halfDay"
                    value="no"
                    checked={halfDay === 'no'}
                    onChange={() => setHalfDay('no')}
                  />
                  <label className="form-label-deal"> No</label>
                </div>
              </div>
            </div>






            {/* </div> */}




          </div>
          <div className="form-button-group-deal">
            <button onClick={handleSubmit} className="submit-button-deal">Save</button>
          </div>
          <ToastContainer />


        </div>
      </Modal>
    </>
  );
};










export default Attendance;








