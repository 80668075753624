import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import ticketImage from '../assets/ticket.png'; // Import the ticket image
import closeIcon from '../assets/Icon.png'; // Import the close icon image
import attachmentIcon from '../assets/Attachments.png'; // Import the attachment image
import { Box, Modal, Stack, Typography } from '@mui/material';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import { Vortex } from 'react-loader-spinner';


function Tickets() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    subject: '',
    description: '',
    department_id: '',
    file: null,
  });
  const [departments, setDepartments] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [error, setError] = useState(null);
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fileName, setFileName] = useState('No file chosen');
  const [actionMenuVisible, setActionMenuVisible] = useState(null);

  // Retrieve token from localStorage
  const authToken = localStorage.getItem('authToken');


  // Function to fetch department data
  const fetchDepartments = async () => {
    try {
      const response = await fetch('https://api.myntask.com/employee/department', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });


      if (!response.ok) {
        throw new Error('Failed to fetch departments');
      }


      const data = await response.json();
      setDepartments(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching departments:', error);

    }
  };


  // Function to fetch ticket list
  const fetchTicketList = async () => {
    try {
      const response = await fetch('https://api.myntask.com/ticket/list', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });


      if (!response.ok) {
        throw new Error('Failed to fetch ticket list');
      }


      const data = await response.json();
      setTicketList(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching ticket list:', error);

    }
  };


  // Fetch departments and tickets on component mount
  useEffect(() => {
    fetchDepartments();
    fetchTicketList();
  }, []);


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };


  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFormData({
      subject: '',
      description: '',
      department_id: '',
      file: null,
    });
    setFileName('No file chosen');
    setError('');
  };

  const toggleActionMenu = (id) => {
    setActionMenuVisible((prevVisible) => (prevVisible === id ? null : id));
  };


  const handleDeleteClick = async (id) => {
    const token = localStorage.getItem("authToken"); // Retrieve token from local storage

    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Authentication Error",
        text: "No token found. Please log in again.",
        toast: true,
        position: "top-right",
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    try {
      // Confirmation before deleting
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: "This action will permanently delete the ticket.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (!confirmation.isConfirmed) {
        return; // Do nothing if the user cancels
      }

      const response = await fetch(`https://api.myntask.com/ticket/delete/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        Swal.fire({
          icon: "error",
          title: "Deletion Failed",
          text: errorData.message || "An error occurred while deleting the ticket.",
          toast: true,
          position: "top-right",
          timer: 1500,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        return;
      }

      fetchTicketList();
      Swal.fire({
        icon: "success",
        title: "Ticket Deleted",
        text: `Ticket has been successfully deleted.`,
        toast: true,
        position: "top-right",
        timer: 1400,
        timerProgressBar: true,
        showConfirmButton: false,
      });

      // window.location.reload(); 
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to delete the ticket. Please try again.",
        toast: true,
        position: "top-right",
        timer: 1500,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      console.error("Failed to delete ticket:", error);
    }
  };



  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData({ ...formData, file: files[0] });
      setFileName(files[0]?.name || 'No file chosen');
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };


  const handleSave = async () => {
    if (!formData.subject || !formData.description || !formData.department_id) {
      setError('Please fill all the required fields.');
      return;
    }


    const formDataToSend = new FormData();
    formDataToSend.append('subject', formData.subject);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('department_id', formData.department_id);
    if (formData.file) {
      formDataToSend.append('file', formData.file);
    }


    try {
      const response = await fetch('https://api.myntask.com/ticket/add', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: formDataToSend,
      });


      if (!response.ok) {
        throw new Error('Failed to save ticket. Please try again.');
      }


      const data = await response.json();
      console.log('API Response:', data);


      Swal.fire({
        icon: 'success',
        title: 'Ticket Generated',
        text: 'Your ticket has been created successfully!',
      });


      // Refresh the ticket list
      fetchTicketList();


      // Close the modal
      handleCloseModal();
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to save the ticket.');
    }
  };


  const handleStatusChange = async (id, newStatus) => {
    const token = localStorage.getItem("authToken"); // Retrieve the token from local storage

    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Authentication Error",
        text: "No token found. Please log in again.",
        toast: true,
        position: "top-right",
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return;
    }

    const requestBody = {
      id,
      status_name: newStatus,
    };

    try {
      const response = await fetch(
        "https://api.myntask.com/ticket/status_update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        Swal.fire({
          icon: "error",
          title: "Failed to Update Status",
          text: errorData.message || "An error occurred while updating the status.",
          toast: true,
          position: "top-right",
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
        return;
      }
      fetchTicketList();
      Swal.fire({
        icon: "success",
        title: "Status Updated",
        text: `Successfully updated status to '${newStatus}' .`,
        toast: true,
        position: "top-right",
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      // window.location.reload(); 

    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update the status. Please try again.",
        toast: true,
        position: "top-right",
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      console.error("Failed to update ticket status:", error);
    }
  };


  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }



  return (
    <div className='main-container'>
      <div className="top-controls">
        <h1 className='title'>Tickets
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button
            className='color-button'
            onClick={handleOpenModal}
          >
            Generate Ticket
          </button>
        </div>
      </div>

      <table
      >
        <thead>
          <tr >
            <th >Ticket ID</th>
            <th >Subject</th>
            <th >Raised By</th>
            <th >Status</th>
            <th >Date</th>
            <th >Attachment</th>
          </tr>
        </thead>
        <tbody>
          {ticketList.map((ticket) => (
            <tr key={ticket.id}>
              <td >{ticket.ticket_id}</td>
              <td >{ticket.subject}</td>
              <td >{ticket.raised_by}</td>
              <td>
                <select
                  value={ticket.status || ""} // Use an empty string if status is not provided
                  onChange={(e) => handleStatusChange(ticket.id, e.target.value)} // Trigger status update on change
                  className="table-filter"
                >
                  {/* Placeholder option */}
                  <option value="" disabled>
                    Select an option
                  </option>
                  {/* Dropdown options */}
                  <option value="new">New</option>
                  <option value="in_progress">In Progress</option>
                  <option value="resolved">Resolved</option>
                  <option value="closed">Closed</option>
                  <option value="on_hold">On Hold</option>
                </select>
              </td>

              <td >
                {new Date(ticket.datetime).toLocaleString()}
              </td>
              <td >
                {ticket.attacement ? (
                  <a href={ticket.attacement} target="_blank" rel="noopener noreferrer">
                    View Attachment
                  </a>
                ) : (
                  'No Attachment'
                )}
              </td>
              <td>
                <div className="action-container">
                  <div className="action-dots" onClick={() => toggleActionMenu(ticket.id)}>
                    <GridMoreVertIcon />
                  </div>
                  {actionMenuVisible === ticket.id && (
                    <div className="action-menu">
                      {/* <div
                        className="action-item"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent row click
                          handleDownload(ticket.id);
                        }}
                      >
                        Download
                      </div> */}
                      <div
                        className="action-item"
                        onClick={() => handleDeleteClick(ticket.id)}
                      >
                        Delete
                      </div>

                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>


      {/* Modal */}
      {/* {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              background: '#fff',
              borderRadius: '16px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              padding: '20px',
              width: '400px',
              textAlign: 'center',
              position: 'relative',
            }}
          >
           
            <img
              src={closeIcon}
              alt="Close"
              style={{
                position: 'absolute',
                top: '16px',
                right: '16px',
                width: '24px',
                height: '24px',
                cursor: 'pointer',
              }}
              onClick={handleCloseModal}
            />
            <h2 style={{ marginBottom: '20px', fontSize: '20px', fontWeight: 'bold' }}>
              Generate Ticket
            </h2>
            <img
              src={ticketImage}
              alt="Ticket"
              style={{
                width: '150px',
                height: '150px',
                marginBottom: '20px',
              }}
            />
            <div>
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleChange}
                style={{
                  width: '100%',
                  padding: '10px',
                  marginBottom: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                }}
              />
              <textarea
                name="description"
                placeholder="Description"
                value={formData.description}
                onChange={handleChange}
                style={{
                  width: '100%',
                  padding: '10px',
                  marginBottom: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  height: '80px',
                }}
              />
              <select
                name="department_id"
                value={formData.department_id}
                onChange={handleChange}
                style={{
                  width: '100%',
                  padding: '10px',
                  marginBottom: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                }}
              >
                <option value="">Select Department</option>
                {departments.map((department) => (
                  <option key={department.id} value={department.id}>
                    {department.department}
                  </option>
                ))}
              </select>
              <label style={{ display: 'block', marginBottom: '10px', cursor: 'pointer' }}>
                <img
                  src={attachmentIcon}
                  alt="Attach"
                  style={{ width: '50px', height: '50px' }}
                />
                <input
                  type="file"
                  name="file"
                  onChange={handleChange}
                  style={{ display: 'none' }}
                />
              </label>
              <p>{fileName}</p>
              {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
            </div>
            <button
              style={{
                width: '100%',
                padding: '10px',
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
                fontSize: '16px',
                marginTop: '10px',
              }}
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      )} */}


      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="ticket-modal"
        aria-describedby="modal-for-ticket-generation"
      >
        <Box className="designation-modal">
          {/* Header with title and close button */}
          <div className="designation-header">
            <h1 className="designation-title">Generate Ticket</h1>
            <img
              src={closeIcon}
              alt="Close"
              className="designation-close"
              onClick={handleCloseModal}
            />
          </div>

          {/* Main Image */}
          {/* <img
          src={ticketImage}
          alt="Ticket"
          className="designation-image"
        /> */}

          {/* Form Section */}
          <div className="designation-form">
            <label className="designation-label">
              Subject
            </label>
            <input
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              className="designation-input"
              placeholder="Enter Subject"
            />

            <label className="designation-label">
              Description
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="designation-input"
              placeholder="Enter Description"

            />

            <label className="designation-label">
              Select Department
            </label>
            <select
              name="department_id"
              value={formData.department_id}
              onChange={handleChange}
              className="designation-input"
            >
              <option value="">Select a department</option>
              {departments.map((department) => (
                <option key={department.id} value={department.id}>
                  {department.department}
                </option>
              ))}
            </select>

            <label className="designation-label">
              Add File
            </label>
            <img
              src={attachmentIcon}
              alt="Attach"
              style={{ width: '50px', height: '50px' }}
            />
            <input
              type="file"
              name="file"
              onChange={handleChange}
              style={{ display: 'none' }}
            />
            <p>{fileName}</p>
          </div>


          {/* Submit Button */}
          <button
            onClick={handleSave}
            className="designation-save"
          >
            Save
          </button>
        </Box>
      </Modal>


    </div>
  );
}


export default Tickets;



