

import React, { useState, useEffect } from "react";
import "./VendorForm.css";
import Swal from 'sweetalert2';  // Ensure SweetAlert is imported
import { useNavigate } from "react-router-dom";
import { Box, Modal, Stack, Typography } from "@mui/material";
import iconImage from '../assets/Icon.png';
import './../Screens/css/Designation.css';
import { Vortex } from 'react-loader-spinner';


const VendorForm = () => {
  const [activeTab, setActiveTab] = useState("vendorDetail");
  const [vendorDetail, setVendorDetail] = useState({
    company_name: "",
    vendor_category: "",
    email: "",
    currency: "",
    vat_no: "",
    website: "",
    bank_name: "",
    holder_name: "",
    ifsc_code: "",
    account_no: "",
    branch: "",
    state: "",
    zip_code: "",
    country: "",
    city: "",
    address: "",
    retailer_policy: "",
    image: null,
  });


  const [currencies, setCurrencies] = useState([]); // To store currency options
  const [categories, setCategories] = useState([]); // To store vendor category options
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const navigate = useNavigate();

  // Fetch currencies and vendor categories from the API
  const fetchData = async () => {
    const token = localStorage.getItem("authToken");


    if (!token) {
      setError("Authentication token not found. Please log in.");
      return;
    }


    try {
      // Fetch currencies
      const currencyResponse = await fetch("https://api.myntask.com/expenses/currency", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Pass token in header
        },
      });



      if (!currencyResponse.ok) {
        throw new Error("Failed to fetch currencies");
      }

      const currencyData = await currencyResponse.json();
      setCurrencies(currencyData); // Store fetched currencies


      // Fetch vendor categories
      const categoryResponse = await fetch("https://api.myntask.com/vendor/category/list", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Pass token in header
        },
      });


      if (!categoryResponse.ok) {
        throw new Error("Failed to fetch vendor categories");
      }


      const categoryData = await categoryResponse.json();
      setCategories(categoryData); // Store fetched categories
      setIsLoading(false); // Update loading state
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data. Please try again later.");
      setIsLoading(false); // Update loading state
    }
  };


  useEffect(() => {

    fetchData();
  }, []);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVendorDetail({
      ...vendorDetail,
      [name]: value,
    });
  };


  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    if (file) {
      setVendorDetail((prevDetail) => ({
        ...prevDetail,
        image: file, // Store the image in the vendorDetail state
      }));
    }
  };


  const handleSave = async (e) => {
    e.preventDefault(); // Prevent form reload

    const token = localStorage.getItem('authToken'); // Get the token

    // Create a new FormData object to hold the form data
    const formDataToSend = new FormData();

    // Append the regular form fields to the FormData object
    Object.keys(vendorDetail).forEach(key => {
      if (key !== 'image') { // Don't include the image in JSON form
        formDataToSend.append(key, vendorDetail[key]);
      }
    });

    // Append the image if it's available
    if (vendorDetail.image) {
      formDataToSend.append('image', vendorDetail.image);
    }

    console.log('vendor data', formDataToSend);
    setIsLoading(true);

    try {
      const response = await fetch('https://api.myntask.com/vendor/save', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, // Add authorization token
        },
        body: formDataToSend, // Send the FormData object as the body
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to save vendor details.');
      }

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'Vendor Saved',
        text: 'Vendor details saved successfully!',
        timer: 1500,
        showConfirmButton: false,
      });

      // Optionally reset the form
      setVendorDetail({
        company_name: "",
        vendor_category: "",
        email: "",
        currency: "",
        vat_no: "",
        website: "",
        bank_name: "",
        holder_name: "",
        ifsc_code: "",
        account_no: "",
        branch: "",
        state: "",
        zip_code: "",
        country: "",
        city: "",
        address: "",
        retailer_policy: "",
        image: null, // Reset image field
      });
      setIsLoading(false);
      navigate("/vendor");
    } catch (error) {
      // Show error message
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'Failed to save vendor details. Please try again.',
        timer: 1500,
        showConfirmButton: false,
      });
      console.error('Error saving vendor details:', error);
    }
    setIsLoading(false);
  };


  const handleCategorySave = async () => {
    if (!newCategory) {
      Swal.fire({
        icon: "warning",
        title: "Missing Category Name",
        text: "Please enter a category name.",
        toast: true,
        position: "top-end",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

    const token = localStorage.getItem("authToken");
    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Authentication Error",
        text: "Authentication token not found.",
        toast: true,
        position: "top-end",
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }

    try {
      const response = await fetch("https://api.myntask.com/vendor/category/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ name: newCategory }),
      });

      if (!response.ok) {
        throw new Error("Failed to save category.");
      }

      const savedCategory = await response.json();
      setCategories((prevCategories) => [...prevCategories, savedCategory]);
      await fetchData();
      setNewCategory("");
      setModalOpen(false); // Close modal after saving

      // Show success toast
      Swal.fire({
        icon: "success",
        title: "Category Added",
        text: "The category has been saved successfully!",
        toast: true,
        position: "top-end",
        timer: 1500,
        showConfirmButton: false,
      });


    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add category. Please try again.",
        toast: true,
        position: "top-end",
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };


  const handleCancel = () => {
    navigate(-1);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "vendorDetail":
        return (
          <div className="vendor-tab-content">
            <h2>Vendor Detail</h2>
            <div className="form-grid">
              {/* Company Name */}
              <div className="form-field">
                <label>Company Name</label>
                <input
                  type="text"
                  name="company_name"
                  value={vendorDetail.company_name}
                  onChange={handleInputChange}
                  placeholder="Enter company name"
                  className="vendor-input"
                />
              </div>


              {/* Vendor Category */}
              <div className="form-field">
                <label>Vendor Category</label>
                <div className="vendor-category-wrapper">
                  <select
                    className="vendor-input"
                    name="vendor_category"
                    value={vendorDetail.vendor_category}
                    onChange={handleInputChange}


                  >
                    <option value="">Select Vendor Category</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                  <button onClick={() => setModalOpen(true)} className="color-button">
                    Add
                  </button>
                </div>
              </div>


              {/* Email */}
              <div className="form-field">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={vendorDetail.email}
                  onChange={handleInputChange}
                  placeholder="Enter email"
                  className="vendor-input"
                />
              </div>

              {/* VAT No */}
              <div className="form-field">
                <label>VAT No</label>
                <input
                  type="text"
                  name="vat_no"
                  value={vendorDetail.vat_no}
                  onChange={handleInputChange}
                  placeholder="Enter VAT number"
                  className="vendor-input"
                />
              </div>


              {/* Website */}
              <div className="form-field">
                <label>Website</label>
                <input
                  type="url"
                  name="website"
                  value={vendorDetail.website}
                  onChange={handleInputChange}
                  placeholder="Enter website URL"
                  className="vendor-input"
                />
              </div>

              {/* Image Field */}
              <div className="form-field">
                <label>Image</label>
                <input
                  type="file"
                  name="image"
                  accept="image/*"  // Allows only image files
                  onChange={handleImageChange}
                  className="vendor-input"
                />
              </div>
            </div>


            {/* Bank Detail Section */}
            <h3 style={{ marginTop: "30px", marginBottom: "20px" }}>Bank Details</h3>
            <div className="form-grid">
              {/* Bank Name */}
              <div className="form-field">
                <label>Bank Name</label>
                <input
                  type="text"
                  name="bank_name"
                  value={vendorDetail.bank_name}
                  onChange={handleInputChange}
                  placeholder="Enter bank name"
                  className="vendor-input"
                />
              </div>

              {/* Holder Name */}
              <div className="form-field">
                <label>Holder Name</label>
                <input
                  type="text"
                  name="holder_name"
                  value={vendorDetail.holder_name}
                  onChange={handleInputChange}
                  placeholder="Enter account holder's name"
                  className="vendor-input"
                />
              </div>

              {/* IFSC Code */}
              <div className="form-field">
                <label>IFSC Code</label>
                <input
                  type="text"
                  name="ifsc_code"
                  value={vendorDetail.ifsc_code}
                  onChange={handleInputChange}
                  className="vendor-input"
                  placeholder="Enter IFSC code"
                />
              </div>

              {/* Account Number */}
              <div className="form-field">
                <label>Account Number</label>
                <input
                  type="text"
                  name="account_no"
                  value={vendorDetail.account_no}
                  onChange={handleInputChange}
                  placeholder="Enter account number"
                  className="vendor-input"
                />
              </div>

              {/* Branch */}
              <div className="form-field">
                <label>Branch</label>
                <input
                  type="text"
                  name="branch"
                  value={vendorDetail.branch}
                  onChange={handleInputChange}
                  className="vendor-input"
                  placeholder="Enter branch name"
                />
              </div>

              {/* Currency */}
              <div className="form-field">
                <label>Currency</label>
                <select
                  className="vendor-input"
                  name="currency"
                  value={vendorDetail.currency}
                  onChange={handleInputChange}
                >
                  <option value="">Select Currency</option>
                  {currencies.map((currency) => (
                    <option key={currency.id} value={currency.id}>
                      {currency.currency_code} ({currency.currency_symbol})
                    </option>
                  ))}
                </select>
              </div>


            </div>
          </div>
        );

      case "billingShipping":
        return (
          <div className="tab-content">
            <h2>Billing & Shipping</h2>
            <div className="form-grid">
              {/* State */}
              <div className="form-field">
                <label>State</label>
                <input
                  type="text"
                  name="state"
                  value={vendorDetail.state}
                  onChange={handleInputChange}
                  placeholder="Enter state"
                  className="vendor-input"
                />
              </div>

              {/* Zip Code */}
              <div className="form-field">
                <label>Zip Code</label>
                <input
                  type="text"
                  name="zip_code"
                  value={vendorDetail.zip_code}
                  onChange={handleInputChange}
                  placeholder="Enter zip code"
                  className="vendor-input"
                />
              </div>

              {/* Country */}
              <div className="form-field">
                <label>Country</label>
                <input
                  type="text"
                  name="country"
                  value={vendorDetail.country}
                  onChange={handleInputChange}
                  placeholder="Enter country"
                  className="vendor-input"
                />
              </div>


              {/* City */}
              <div className="form-field">
                <label>City</label>
                <input
                  type="text"
                  name="city"
                  value={vendorDetail.city}
                  onChange={handleInputChange}
                  className="vendor-input"
                  placeholder="Enter city"
                />
              </div>


              {/* Address */}
              <div className="form-field">
                <label>Address</label>
                <textarea
                  name="address"
                  className="vendor-input"
                  value={vendorDetail.address}
                  onChange={handleInputChange}
                  placeholder="Enter address"
                  rows="3"
                />
              </div>
            </div>
          </div>
        );
      case "returnPolicies":
        return (
          <div className="tab-content">
            <h2>Retailer Policies</h2>
            <div className="form-field">
              <label>Retailer Policy</label>
              <textarea
                name="retailer_policy"
                value={vendorDetail.retailer_policy}
                onChange={handleInputChange}
                placeholder="Enter return policy details"
                rows="6"
                style={{ width: "100%", padding: "10px", borderRadius: "5px" }}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  //..........Loading Spinner ..........................................
  useEffect(() => {
    // Adjust the timer duration here (e.g., 3000ms = 3 seconds)
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 300);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);


  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }




  return (
    <div className="vendor-screen">
      {/* Tab Navigation */}
      <div className="tab-navigation">
        <button
          className={`tab-button ${activeTab === "vendorDetail" ? "active" : ""}`}
          onClick={() => setActiveTab("vendorDetail")}
        >
          Vendor Detail
        </button>
        <button
          className={`tab-button ${activeTab === "billingShipping" ? "active" : ""}`}
          onClick={() => setActiveTab("billingShipping")}
        >
          Billing & Shipping
        </button>
        <button
          className={`tab-button ${activeTab === "returnPolicies" ? "active" : ""}`}
          onClick={() => setActiveTab("returnPolicies")}
        >
          Return Policies
        </button>
      </div>

      {/* Tab Content */}
      {renderTabContent()}

      {/* Save Button */}
      <div className="buttons-row" id="bottom-btn" style={{ alignItems: 'flex-end', marginTop: '15px' }}>
        <button onClick={handleCancel} className="color-button">
          Cancel
        </button>

        <button onClick={handleSave} className="color-button">
          Save
        </button>
      </div>


      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}

      >
        <Box className="designation-modal">
          {/* Header with title and close button */}
          <div className="designation-header">
            <h1 className="designation-title">Add New Category</h1>
            <img
              src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
              alt="Close"
              className="designation-close"
              onClick={() => setModalOpen(false)}
            />
          </div>

          {/* Form Section */}
          <div className="designation-form">
            <label className="designation-label" >
              Category Name
            </label>
            <input
              type="text"
              id="category-name"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              placeholder="Enter category name"
              className="designation-input"
            />
          </div>

          <button className="designation-save" onClick={handleCategorySave}>Save</button>

        </Box>
      </Modal>

    </div>
  );
};




export default VendorForm;
