import { useEffect, useState } from 'react';
import { Chrono } from 'react-chrono'; // Only this import is necessary
import Swal from 'sweetalert2';
import "./FollowUpModal.css";


const FollowUpModal = ({ isOpen, onClose, dealId }) => {
  const [followUpDetails, setFollowUpDetails] = useState([]);
  const [remark, setRemark] = useState('');
  const [nextFollowUpDate, setNextFollowUpDate] = useState('');
  const [sendReminder, setSendReminder] = useState(false);
  const [remindTime, setRemindTime] = useState('');
  const [remindType, setRemindType] = useState('');




  const resetFields = () => {
    setRemark('');
    setNextFollowUpDate('');
    setSendReminder(false);
    setRemindTime('');
    setRemindType('');
  };


  const fetchFollowUpDetails = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await fetch(`https://api.myntask.com/leads/follow_up_list/${dealId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });


      if (!response.ok) {
        throw new Error('Failed to fetch follow-up details');
      }


      const data = await response.json();
      setFollowUpDetails(data);
    } catch (error) {
      console.error('Error fetching follow-up details:', error);
    }
  };


  useEffect(() => {
    if (isOpen && dealId) {
      fetchFollowUpDetails(); // Initial fetch when the modal opens
    }
  }, [isOpen, dealId]);


  if (!isOpen) {
    return null;
  }




  const handleSubmit = async (e) => {
    e.preventDefault();


    const payload = {
      deal_id: dealId,
      remark,
      next_follow_up_date: nextFollowUpDate,
      send_reminder: sendReminder ? 1 : 0,
      remind_time: parseInt(remindTime),
      remind_type: remindType,
    };


    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        Swal.fire({
          icon: 'error',
          title: 'Authentication Error',
          text: 'Please log in again.',
          timer: 1500,
          showConfirmButton: false,
        });
        return;
      }


      const response = await fetch('https://api.myntask.com/leads/add_follow_up', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });


      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to add follow-up.');
      }


      // Success message
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Follow-up added successfully!',
        timer: 1500,
        showConfirmButton: false,
      });


      fetchFollowUpDetails();
      resetFields();


    } catch (error) {


      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'Failed to add follow-up. Please try again.',
        timer: 1500,
        showConfirmButton: false,
      });
      console.error('Error adding follow-up:', error);
    }
  };






  const timelineItems = followUpDetails.map((followUp) => ({
    title: followUp.created_at,
    cardTitle: followUp.next_follow_up_date,
    cardDetailedText: followUp.remark,
  }));


  return (
    <div className="follow-modal">
      <div className="follow-modal-content">
        <div className="top-controls">
          <h1 className="title">
            Follow-Up
            <div className="underline"></div>
          </h1>
          <div className="buttons-row">
            <button className="color-button" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
        <div>
          {timelineItems.length > 0 ? (
            <Chrono
              items={timelineItems}
              mode="VERTICAL"  // Change mode to vertical
              theme={{
                primary: '#4cd964',
                secondary: '#f0f0f0',
                cardBgColor: '#fff',
                titleColor: '#333',
              }}
              cardWidth={300}
              cardHeight={60}  // Adjust the height if needed
              scrollable
            />
          ) : (
            <p className="no-data-message">No follow-up details available.</p>
          )}
        </div>
        {/* <div className="underline"></div> */}
        <hr className="divider" />
        <form >
          <div>
            <label style={labelStyle}>Remark</label>
            <textarea
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
              required
              placeholder="Enter your remark"
              className='input-field'
            ></textarea>
          </div>
          <div>
            <label style={labelStyle}>Next Follow-Up Date</label>
            <input
              type="date"
              value={nextFollowUpDate}
              onChange={(e) => setNextFollowUpDate(e.target.value)}
              required
              // className='input-field'
              style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxSizing: 'border-box' }}
            />
          </div>
          <div className='send-reminder'>
            <label className='labelName'>Send Reminder</label>
            <input
              type="checkbox"
              id='sendReminder'
              checked={sendReminder}
              onChange={() => setSendReminder((prev) => !prev)}


            />
          </div>
          {sendReminder && (
            <>
              <div>
                <label style={labelStyle}>Reminder Time</label>
                <input
                  type="number"
                  value={remindTime}
                  onChange={(e) => setRemindTime(e.target.value)}
                  required
                  placeholder="e.g., 2"
                  style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                />
              </div>
              <div>
                <label style={labelStyle}>Reminder Type</label>
                <select
                  value={remindType}
                  onChange={(e) => setRemindType(e.target.value)}
                  required
                  style={{ width: '100%', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', boxSizing: 'border-box' }}
                >
                  <option value="">Select Type</option>
                  <option value="hour">Hour</option>
                  <option value="day">Day</option>
                </select>
              </div>
            </>
          )}
          <div className="modal-actions">
            <button className='color-button' onClick={handleSubmit}>Save</button>
          </div>
        </form>


      </div>
    </div>
  );
};


export default FollowUpModal;




const labelStyle = {
  fontweight: 'bold',
  marginTop: '10px',
  marginBottom: '10px',
  color: '#555',
}






