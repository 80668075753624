import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DealFile from '../Components/DealFile';
import AddFollowUpForm from '../Components/AddFollowUpForm';
import DealNotes from '../Components/DealNotes';
import { Box, Grid, CardContent, Card, Stack, Typography } from "@mui/material";
import { Vortex } from 'react-loader-spinner';






const DealProfile = () => {
  const { id } = useParams();
  const [lead, setLead] = useState(null);
  const [activeTab, setActiveTab] = useState('profile');
  const [deals, setDeals] = useState([]);
  const [followUps, setFollowUps] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);










  useEffect(() => {
    const fetchLeadData = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }


      try {
        const response = await fetch(`https://api.myntask.com/leads/lead_view/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });




        if (!response.ok) {
          throw new Error('Failed to fetch lead data');
        }


        const data = await response.json();
        setLead(data);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching lead data:', err);
        setError('Failed to load lead data.');
        setIsLoading(false);
      }
    };


    fetchLeadData();
  }, [id]);












  const renderProfile = () => (
    <div style={profileContainerStyle}>
      {/* <h2 style={profileHeaderStyle}>Profile Info</h2> */}
      <div style={profileCardStyle}>
        {renderProfileItem('Name', lead.client_name)}
        {renderProfileItem('Email', lead.client_email)}
        {renderProfileItem('Mobile', lead.mobile || 'N/A')}
        {renderProfileItem('Company Name', lead.company_name || 'N/A')}
        {renderProfileItem('Website', lead.website || 'N/A')}
        {renderProfileItem('Address', lead.address || 'N/A')}
        {renderProfileItem('City', lead.city || 'N/A')}
        {renderProfileItem('State', lead.state || 'N/A')}
        {renderProfileItem('Country', lead.country || 'N/A')}
        {renderProfileItem('Postal Code', lead.postal_code || 'N/A')}
      </div>
    </div>
  );








  const renderProfileItem = (label, value) => (
    <div style={profileItemContainerStyle}>
      <p style={profileLabelStyle}>{label}</p>
      <p style={profileValueStyle}>{value}</p>
    </div>
  );








  const renderProposals = () => (
    <div>
      {/* <h2 style={sectionHeaderStyle}>Deals</h2> */}
      {deals.length > 0 ? (
        <table style={tableStyle}>
          <thead>
            <tr>
              {['Name', 'Company Name', 'Client Name', 'Client Email', 'Mobile', 'Value', 'Close Date', 'Stage'].map((header) => (
                <th key={header} style={tableHeaderStyle}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {deals.map(deal => (
              <tr key={deal.id} style={tableRowStyle}>
                <td style={tableCellStyle}>{deal.name}</td>
                <td style={tableCellStyle}>{deal.company_name}</td>
                <td style={tableCellStyle}>{deal.client_name}</td>
                <td style={tableCellStyle}>{deal.client_email}</td>
                <td style={tableCellStyle}>{deal.mobile}</td>
                <td style={tableCellStyle}>{deal.value}</td>
                <td style={tableCellStyle}>{deal.close_date}</td>
                <td style={tableCellStyle}>{deal.stage}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p style={noDataStyle}>No Proposals found for this deal.</p>
      )}
    </div>
  );




















  const renderTabContent = () => {
    switch (activeTab) {
      case 'profile':
        return renderProfile();
      case 'proposals':
        return renderProposals();
      case 'files':
        return <DealFile id={id} />;
      case 'follow-up':
        return <AddFollowUpForm dealId={id} onFollowUpAdded={(data) => setFollowUps(prev => [...prev, data])} />;
      case 'notes':
        return <DealNotes id={id} />;
      default:
        return null;
    }
  };










  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }






  if (!lead) {
    return <p style={loadingStyle}>Loading...</p>;
  }








  return (
    <div style={mainContainerStyle}>
      <h1 style={headerStyle}>Lead Profile</h1>
      <div style={tabContainerStyle}>
        <button onClick={() => setActiveTab('profile')} style={activeTab === 'profile' ? activeTabStyle : tabButtonStyle}>Profile</button>
        <button onClick={() => setActiveTab('proposals')} style={activeTab === 'proposals' ? activeTabStyle : tabButtonStyle}>Proposals</button>
        <button onClick={() => setActiveTab('files')} style={activeTab === 'files' ? activeTabStyle : tabButtonStyle}>Files</button>
        <button onClick={() => setActiveTab('follow-up')} style={activeTab === 'follow-up' ? activeTabStyle : tabButtonStyle}>Follow Up</button>
        <button onClick={() => setActiveTab('notes')} style={activeTab === 'notes' ? activeTabStyle : tabButtonStyle}>Notes</button>
      </div>
      <div style={tabContentStyle}>{renderTabContent()}</div>
    </div>
  );
};








export default DealProfile;








const mainContainerStyle = {
  width: '100%',
  margin: '20px auto',
  padding: '10px',


  // backgroundColor: '#f8f9fc',
  // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
};








const headerStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#333',
  textAlign: 'center',
  marginBottom: '20px',
};








const tabContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#fff',
  padding: '10px',
  borderBottom: '1px solid #ddd',
};








const tabButtonStyle = {
  flex: 1,
  padding: '10px 15px',
  margin: '0 5px',
  border: '1px solid #ddd',
  borderRadius: '5px',
  backgroundColor: '#fff',
  color: '#333',
  cursor: 'pointer',
  textAlign: 'center',
  fontSize: '14px',
  fontWeight: 'bold',
};








const activeTabStyle = {
  ...tabButtonStyle,
  backgroundColor: '#007bff',
  color: '#fff',
  border: '1px solid #007bff',
};








const tabContentStyle = {
  padding: '20px',
  backgroundColor: '#fff',
};








const profileContainerStyle = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  border: '1px solid #ddd',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  marginBottom: '20px',
};








const profileHeaderStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#333',
  marginBottom: '10px',
};








const profileCardStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '10px 20px',
};








const profileItemContainerStyle = {
  padding: '10px 15px',
  // border: '1px solid #ddd',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '5px',
  backgroundColor: '#f8f9fc',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
};








const profileLabelStyle = {
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#666',
  marginBottom: '5px',
};








const profileValueStyle = {
  fontSize: '14px',
  color: '#333',
};








const sectionHeaderStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#333',
  marginBottom: '10px',
};








const tableStyle = {
  width: '100%',
  borderCollapse: 'collapse',
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  borderRadius: '5px',
};








const tableHeaderStyle = {
  padding: '10px',
  // backgroundColor: '#007bff',
  color: '#fff',
  fontSize: '14px',
  textAlign: 'left',
};








const tableRowStyle = {
  borderBottom: '1px solid #ddd',
};








const tableCellStyle = {
  padding: '10px',
  textAlign: 'left',
  fontSize: '14px',
  color: '#333',
};








const noDataStyle = {
  color: '#666',
  textAlign: 'center',
  fontSize: '14px',
};








const loadingStyle = {
  textAlign: 'center',
  fontSize: '16px',
  color: '#555',
};


































