import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for toast notifications
import './BannerRender.css'; // Import CSS for styling and animation


const BannerRender = () => {
  const [banners, setBanners] = useState(null); // Use null initially to distinguish between no data and loading state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');


  // Fetch banner data when the component mounts
  useEffect(() => {
    const fetchBanners = async () => {
      const token = localStorage.getItem('authToken'); // Retrieve token from localStorage
      console.log('Token:', token); // Debug token


      if (!token) {
        setError('Token not found. Please login again.');
        toast.error('Token not found. Please login again.');
        return;
      }


      try {
        const response = await fetch('https://api.myntask.com/home/banner_list', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Pass the token in the Authorization header
          },
        });


        console.log('Response:', response); // Debug full response


        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error Response Body:', errorText); // Log error response body if available
          throw new Error('Failed to fetch banners.');
        }


        const data = await response.json();
        console.log('Banner fetch successful:', data); // Log the response data


        // Handle the response structure: if it's an array, set it as banners; if it's an object, wrap it in an array.
        if (Array.isArray(data)) {
          setBanners(data); // Assuming data is an array of banners
        } else {
          setBanners([data]); // If data is a single object, convert it to an array
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching banners:', error);
        setError(`Error fetching banners: ${error.message}`);
        toast.error(`Error fetching banners: ${error.message}`);
        setLoading(false);
      }
    };


    fetchBanners();
  }, []);


  if (loading) {
    return <div>Loading banners...</div>;
  }


  if (error) {
    return <div>{error}</div>;
  }


  if (!banners || banners.length === 0) {
    return <p>No banners found</p>;
  }


  return (
    <div className="banner-container">
      <div className="banner-marquee">
        {banners.map((banner, index) => (
          <span key={index} className="banner-text">
            {banner.banner_content}
          </span>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};


export default BannerRender;







