import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Modal, Box } from '@mui/material';
import iconImage from '../assets/Icon.png';
import './../Screens/css/Designation.css';

const ClientSetting = () => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState({});
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [subCategoryName, setSubCategoryName] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [openSubCategoryModal, setOpenSubCategoryModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchCategories = async () => {
    try {
      const token = localStorage.getItem("authToken"); // Get the token from local storage
      const response = await fetch("https://api.myntask.com/client/category", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch client categories");
      }
      const data = await response.json();
      setCategories(data); // Assuming the API returns an array of categories
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // Fetch subcategories for a specific category
  const fetchSubCategories = async (categoryId) => {
    try {
      const token = localStorage.getItem("authToken"); // Get the token from local storage
      const response = await fetch(
        `https://api.myntask.com/client/sub_category/${categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch client sub-categories");
      }
      const data = await response.json();
      // Store subcategories in state under their respective categoryId
      setSubCategories((prev) => ({
        ...prev,
        [categoryId]: data,
      }));
    } catch (error) {
      console.error("Error fetching sub-categories:", error);
    }
  };

  // Toggle subcategories (expand/collapse)
  const toggleSubCategories = (categoryId) => {
    console.log("Toggling subcategories for category ID:", categoryId);

    // Check if subcategories for this category are already loaded
    if (subCategories[categoryId]) {
      // Collapse subcategories
      setSubCategories((prev) => {
        const updated = { ...prev };
        delete updated[categoryId];
        return updated;
      });
    } else {
      // Fetch and expand subcategories
      fetchSubCategories(categoryId);
    }
  };

  // UseEffect to call fetchSubCategories when selectedCategoryId changes
  useEffect(() => {
    if (selectedCategoryId) {
      fetchSubCategories(selectedCategoryId);
    }
  }, [selectedCategoryId]); // Dependency array


  const handleAddCategory = () => {
    setOpenCategoryModal(true);
  };


  const handleSaveCategory = async () => {
    if (!newCategory.trim()) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Category name is required!',
        timer: 1500, // Automatically closes after 3 seconds
        showConfirmButton: false, // Hide the confirmation button
      });
      return;
    }

    const token = localStorage.getItem('authToken');
    if (!token) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'Token not found in local storage!',
        timer: 1500, // Automatically closes after 3 seconds
        showConfirmButton: false, // Hide the confirmation button
      });
      return;
    }

    const payload = {
      category_name: newCategory.trim(),
    };

    try {
      const response = await fetch('https://api.myntask.com/client/category_save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      // Check if the response is JSON
      const contentType = response.headers.get('Content-Type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Category saved successfully!',
            timer: 1500, // Automatically closes after 3 seconds
            showConfirmButton: false, // Hide the confirmation button
          });
          fetchCategories();
          setNewCategory('');
          setOpenCategoryModal(false);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: data.message || 'Failed to save category!',
          });
        }
      } else {
        // Handle plain text response
        const text = await response.text();
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: text,
            timer: 1500, // Automatically closes after 3 seconds
            showConfirmButton: false, // Hide the confirmation button
          });
          fetchCategories();
          setNewCategory('');
          setOpenCategoryModal(false);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: text,
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Something went wrong: ${error.message}`,
        timer: 1500, // Automatically closes after 3 seconds
        showConfirmButton: false, // Hide the confirmation button
      });
    }
  };


  const handleAddSubCategory = () => {
    setOpenSubCategoryModal(true); // Open the modal
  };


  const handleSaveSubCategory = async () => {
    if (!subCategoryName.trim()) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Sub-category name is required!',
      });
      return;
    }

    const token = localStorage.getItem('authToken');
    if (!token) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'Token not found in local storage!',
      });
      return;
    }

    const payload = {
      category_name: subCategoryName.trim(),
      category_id: selectedCategoryId,  // Send the selected category ID
    };

    setLoading(true);

    try {
      const response = await fetch('https://api.myntask.com/client/sub_category_save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      // Check if the response is JSON
      const contentType = response.headers.get('Content-Type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        if (response.ok) {
          fetchSubCategories(selectedCategoryId);
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Sub-category saved successfully!',
          });
          setSubCategoryName('');

          setOpenSubCategoryModal(false);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: data.message || 'Failed to save sub-category!',
          });
        }
      } else {
        // Handle plain text response
        const text = await response.text();
        if (response.ok) {
          fetchSubCategories(selectedCategoryId);
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: text,
          });
          setSubCategoryName('');
          setOpenSubCategoryModal(false);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: text,
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Something went wrong: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };


  const deleteCategory = async (categoryId) => {
    const token = localStorage.getItem("authToken"); // Retrieve token from local storage

    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Authorization Error",
        text: "Authorization token not found!",
      });
      return;
    }

    // Confirm deletion using SweetAlert
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action will delete the category and cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (!result.isConfirmed) {
      return; // If the user cancels, exit the function
    }

    try {
      const response = await fetch(`https://api.myntask.com/client/category_delete/${categoryId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Category deleted successfully.",
          timer: 2000,
          showConfirmButton: false,
        });

        // Optionally update the categories list after deletion
        setCategories((prev) => prev.filter((category) => category.category_id !== categoryId));
      } else {
        const errorData = await response.json();
        Swal.fire({
          icon: "error",
          title: "Failed to Delete",
          text: errorData.message || "Unknown error occurred.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    }
  };


  const deleteSubCategory = async (subCategoryId, categoryId) => {
    const token = localStorage.getItem("authToken"); // Retrieve token from local storage

    if (!token) {
      Swal.fire({
        icon: "error",
        title: "Authorization Error",
        text: "Authorization token not sfound!",
      });
      return;
    }

    // Confirm deletion using SweetAlert
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action will delete the sub-category and cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (!result.isConfirmed) {
      return; // If the user cancels, exit the function
    }

    try {
      const response = await fetch(
        `https://api.myntask.com/client/sub_category_delete/${subCategoryId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        fetchSubCategories(categoryId);
        Swal.fire({
          icon: "success",
          title: "Deleted!",
          text: "Sub-category deleted successfully.",
          timer: 2000,
          showConfirmButton: false,
        });

        // Update the sub-categories list after deletion
        setSubCategories((prev) => {
          const updatedSubCategories = { ...prev };
          updatedSubCategories[categoryId] = updatedSubCategories[
            categoryId
          ].filter((subCat) => subCat.sub_category_id !== subCategoryId);
          return updatedSubCategories;
        });
      } else {
        const errorData = await response.json();
        Swal.fire({
          icon: "error",
          title: "Failed to Delete",
          text: errorData.message || "Unknown error occurred.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
    }
  };



  return (
    <div className="permissions-container">
      <div className="top-controls">
        <h1 className="title">
          Client Category Setting
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={handleAddCategory}>
            Add Category
          </button>
          <button className="color-button" onClick={handleAddSubCategory}>
            Add Sub Category
          </button>
        </div>
      </div>

      <table className="category-table">
        <thead>
          <tr>
            <th>Category Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <React.Fragment key={category.category_id}>
              {/* Main category row */}
              <tr>
                <td
                  onClick={() => toggleSubCategories(category.category_id)}
                  style={{ cursor: "pointer", fontWeight: "bold" }}
                >
                  {category.category_name}
                </td>
                <td>
                  <span onClick={() => deleteCategory(category.category_id)} style={{ cursor: "pointer", color: "red" }}>
                    Delete
                  </span>
                </td>
              </tr>

              {/* Subcategories table */}
              {subCategories[category.category_id] && (
                <tr>
                  <td colSpan="2">
                    <table className="subcategory-table">
                      <thead>
                        <tr>
                          <th>Sub-category Name</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subCategories[category.category_id].map((subCategory) => (
                          <tr key={subCategory.sub_category_id}>
                            <td>{subCategory.category_name}</td>
                            <td>
                              <span
                                onClick={() =>
                                  deleteSubCategory(subCategory.sub_category_id, category.category_id)
                                } style={{ cursor: "pointer", color: "red" }}
                              >
                                Delete
                              </span>

                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <Modal
        open={openCategoryModal}
        onClose={() => setOpenCategoryModal(false)}
      >
        <Box className="designation-modal">
          {/* Header with title and close button */}
          <div className="designation-header">
            <h1 className="designation-title">Add Category</h1>
            <img
              src={iconImage}
              alt="Close"
              className="designation-close"
              onClick={() => setOpenCategoryModal(false)}
            />
          </div>


          {/* Form Section */}
          <div className="designation-form">
            <div className="form-group">
              <input
                type="text"
                name="newCategory"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                placeholder="Category Name"
                className="designation-input"
                required
              />
            </div>


            <button
              className="designation-save"
              onClick={handleSaveCategory}
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </Box>
      </Modal>


      <Modal open={openSubCategoryModal} onClose={() => setOpenSubCategoryModal(false)}>
        <Box className="designation-modal">
          {/* Modal Header */}
          <div className="designation-header">
            <h1 className="designation-title">Add Sub-Category</h1>
            <img
              src={iconImage} // Replace 'iconImage' with your close icon image
              alt="Close"
              className="designation-close"
              onClick={() => setOpenSubCategoryModal(false)}
            />
          </div>


          {/* Modal Form */}
          <div className="designation-form">
            <div className="form-group">
              <label>Category</label>
              <select
                className="designation-input"
                value={selectedCategoryId}
                onChange={(e) => setSelectedCategoryId(e.target.value)}
              >
                <option value="">Select Category</option>
                {/* Render categories dynamically */}
                {categories.map((category) => (
                  <option key={category.category_id} value={category.category_id}>
                    {category.category_name}
                  </option>
                ))}
              </select>
            </div>


            <div className="form-group">
              <input
                type="text"
                name="subCategoryName"
                value={subCategoryName}
                onChange={(e) => setSubCategoryName(e.target.value)}
                placeholder="Sub-Category Name"
                className="designation-input"
                required
              />
            </div>


            <button
              className="designation-save"
              onClick={handleSaveSubCategory}
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </Box>
      </Modal>

    </div>
  );
};

export default ClientSetting;
