// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Dialog,
//   Modal,

// } from '@mui/material';
// import { useDropzone } from 'react-dropzone';
// import Swal from 'sweetalert2'; // Import SweetAlert2
// import iconImage from '../assets/Icon.png';
// import './../Screens/css/Designation.css';


// function Files({ projectId }) {
//   const [open, setOpen] = useState(false); // For modal open/close state
//   const [uploadedFile, setUploadedFile] = useState(null); // State for uploaded files
//   const [fileList, setFileList] = useState([]); // State to store the list of files fetched from the API
//   const [searchTerm, setSearchTerm] = useState(''); // State for the search term
//   const [fileName, setFileName] = useState(''); // State for file name
//   const [categoryId, setCategoryId] = useState(''); // State for category ID
//   const [status, setStatus] = useState('');
//   const [taskId, setTaskId] = useState('');
// const [milestoneId, setMilestoneId] = useState('');
//   const [categories, setCategories] = useState([]);
//   const [milestones, setMilestones] = useState([]);
//   const [tasks, setTasks] = useState([]);
//   const [error, setError] = useState('');
//   const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
//   const [newCategoryName, setNewCategoryName] = useState('');


//   // Function to fetch existing files
//   const fetchFiles = async () => {
//     const token = localStorage.getItem('authToken');
//     if (!token) {
//       console.error('No auth token found.');
//       return;
//     }


//     const url = `https://api.myntask.com/project/files/${projectId}`;


//     try {
//       const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         },
//       });


//       if (!response.ok) {
//         throw new Error('Failed to fetch files');
//       }


//       const data = await response.json();
//       setFileList(data);
//     } catch (error) {


//     }
//   };


//   // Fetch files when the component mounts
//   useEffect(() => {
//     fetchFiles();
//   }, [projectId]);


//   // Handle opening and closing the upload modal
//   const handleOpenUpload = () => setOpen(true);


//   const handleCloseUpload = () => {
//     setOpen(false);
//     setUploadedFile(null);
//     setFileName('');
//     setCategoryId('');
//     setStatus('');
//     setTaskId('');  // Reset taskId
//     setMilestoneId('');  // Reset milestoneId
//   };




//   // Dropzone logic for drag-and-drop file upload
//   const { getRootProps, getInputProps } = useDropzone({
//     accept: '.pdf, .jpg, .jpeg, .png', // Accepting images and PDFs
//     multiple: true, // Allow multiple files
//     onDrop: (acceptedFiles) => {
//       setUploadedFile(acceptedFiles);
//     },
//   });


//   const fetchCategories = async () => {
//     const token = localStorage.getItem('authToken');

//     if (!token) {
//       setError('No token found. Please log in.');
//       return;
//     }

//     try {
//       const response = await fetch(`https://api.myntask.com/project/file_category_dropdown/${projectId}`, {
//         method: 'GET',
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error('Failed to fetch categories');
//       }

//       const result = await response.json();
//       console.log('Categories:', result);
//       setCategories(result);

//     } catch (err) {
//       console.error('Error fetching categories:', err);
//       setError('Failed to fetch categories');
//     }
//   };

//   const fetchMilestones = async () => {
//     const token = localStorage.getItem('authToken');

//     if (!token) {
//       setError('No token found. Please log in.');
//       return;
//     }

//     try {
//       const response = await fetch(`https://api.myntask.com/project/milestone_list/${projectId}`, {
//         method: 'GET',
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         },
//       });

//       if (!response.ok) {
//         throw new Error('Failed to fetch Milestones');
//       }

//       const result = await response.json();
//       console.log('Milestones:', result);
//       setMilestones(result);

//     } catch (err) {
//       console.error('Error fetching Milestones:', err);
//       setError('Failed to fetch Milestones');
//     }
//   };

//   useEffect(() => {
//     fetchCategories();
//     fetchMilestones();
//   }, [projectId]);

//   const handleAddCategory = () => {
//     setIsAddCategoryModalOpen(true);
//   };

//   const handleSaveCategory = async () => {
//     const token = localStorage.getItem('authToken');
//     if (!token) {
//       Swal.fire({
//         icon: 'error',
//         title: 'Authentication Error',
//         text: 'No token found. Please log in.',
//         toast: true,
//         position: 'top-end',
//         timer: 1500,
//         showConfirmButton: false,
//       });
//       return;
//     }

//     try {
//       const response = await fetch(
//         'https://api.myntask.com/project/file_category_save',
//         {
//           method: 'POST',
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             name: newCategoryName,
//             project_id: projectId, // Replace this with the actual project ID
//           }),
//         }
//       );

//       if (!response.ok) {
//         throw new Error('Failed to save category');
//       }

//       const result = await response.json();
//       setCategories([...categories, result]); // Add the new category to the dropdown
//       fetchCategories();
//       setIsAddCategoryModalOpen(false); // Close the modal
//       setNewCategoryName(''); // Clear the input

//       // Success message
//       Swal.fire({
//         icon: 'success',
//         title: 'Category Saved!',
//         text: 'The new category has been added successfully.',
//         toast: true,
//         position: 'top-end',
//         timer: 1500,
//         showConfirmButton: false,
//       });
//     } catch (err) {
//       console.error(err);

//       // Error message
//       Swal.fire({
//         icon: 'error',
//         title: 'Save Failed',
//         text: 'Error saving category. Please try again.',
//         toast: true,
//         position: 'top-end',
//         timer: 1500,
//         showConfirmButton: false,
//       });
//     }
//   };

//   const handleCategoryChange = (e) => {
//     setCategoryId(e.target.value);
//     console.log('Selected Category ID:', e.target.value);
//   };

//   const handleMilestoneChange = (e) => {
//     const selectedMilestoneId = e.target.value;
//     setMilestoneId(selectedMilestoneId);
//     setTasks([]); // Clear tasks when a new milestone is selected
//     if (selectedMilestoneId) {
//       fetchTasks(selectedMilestoneId); // Fetch tasks for the selected milestone
//     }
//   };

//   const fetchTasks = async (milestoneId) => {
//     const token = localStorage.getItem("authToken");
//     if (!token) {
//       console.error("Authentication token is missing.");
//       return;
//     }

//     try {
//       const response = await fetch(`https://api.myntask.com/project/milestone_task_dropdown/${milestoneId}`, {
//         method: "GET",
//         headers: {
//           Authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//         },
//       });

//       if (!response.ok) {
//         throw new Error("Failed to fetch tasks");
//       }

//       const result = await response.json();
//       setTasks(result); // Update the tasks state with fetched data
//     } catch (error) {
//       console.error("Error fetching tasks:", error);
//     }
//   };


//   const handleSubmit = async () => {
//     if (!uploadedFile) {
//       Swal.fire({
//         icon: 'error',
//         title: 'No file selected',
//         text: 'Please select a file to upload.',
//         toast: true,
//         position: 'top-end',
//         timer: 1500,
//         showConfirmButton: false,
//       });
//       return;
//     }

//     if (!fileName || !categoryId ) {
//       Swal.fire({
//         icon: 'error',
//         title: 'Missing Fields',
//         text: 'All fields are required.',
//         toast: true,
//         position: 'top-end',
//         timer: 1500,
//         showConfirmButton: false,
//       });
//       return;
//     }

//     const token = localStorage.getItem('authToken');
//     if (!token) {
//       Swal.fire({
//         icon: 'error',
//         title: 'Authentication Error',
//         text: 'No token found. Please log in.',
//         toast: true,
//         position: 'top-end',
//         timer: 1500,
//         showConfirmButton: false,
//       });
//       return;
//     }

//     // Create FormData and append the file(s) to it
//     const formData = new FormData();
//     formData.append('project_id', projectId);
//     formData.append('category_id', categoryId);
//     formData.append('file_name', fileName);
//     formData.append('status', status);
//     formData.append('file', uploadedFile);
//     formData.append('task_id', taskId);  // Add task_id to FormData
//     formData.append('milestone_id', milestoneId);  // Add milestone_id to FormData

//       // Log FormData content
//   console.log("FormData content:");
//   formData.forEach((value, key) => {
//     console.log(`${key}: ${value}`);
//   });


//     const url = `https://api.myntask.com/project/file_save`; // API URL

//     try {
//       const response = await fetch(url, {
//         method: 'POST',
//         headers: {
//           Authorization: `Bearer ${token}`, // Ensure token is correct and valid
//         },
//         body: formData,
//       });

//       if (!response.ok) {
//         const errorResponse = await response.text();
//         console.error('Error response:', response.status, response.statusText, errorResponse);
//         throw new Error(`Network response was not ok. Status code: ${response.status}`);
//       }

//       // Refresh files and close modal
//       fetchFiles();

//       Swal.fire({
//         icon: 'success',
//         title: 'Success!',
//         text: 'File uploaded successfully.',
//         toast: true,
//         position: 'top-end',
//         timer: 1500,
//         showConfirmButton: false,
//       });
//       handleCloseUpload(); // Close the modal after successful upload
//     } catch (error) {
//       console.error('Error uploading file:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Upload Failed',
//         text: 'Error uploading file. Please try again.',
//         toast: true,
//         position: 'top-end',
//         timer: 1500,
//         showConfirmButton: false,
//       });
//     }
//   };



//   // Filtered list of files based on the search term
//   const filteredFiles = fileList.filter((file) =>
//     file.filename.toLowerCase().includes(searchTerm.toLowerCase())
//   );


//   return (
//     <Box padding={4}>
//       <div className="top-controls">
//         <h1 className='title'>Files
//           <div className="underline"></div>
//         </h1>
//         <div className="buttons-row">
//           <button className="color-button"  onClick={handleOpenUpload}>
//           Upload Files
//           </button>
//         </div>
//       </div>

//       <div className="top-controls">
//           <div className="filters-and-search">
//           <input
//   type="text"
//   placeholder="Search Files"
//   value={searchTerm}
//   onChange={(e) => setSearchTerm(e.target.value)}
//   className='searchbar'
//   style={{
//     width: '250px',
//   }}
// />

//           </div>
//         </div>

//       {/* Upload Modal  */}
//       <Modal open={open} onClose={handleCloseUpload}>
//     <Box
//       className='designation-modal' sx={{maxWidth:'450px'}}
//     >
//         <div className='designation-header'>
//       <h1 className="designation-title" >
//         Add Document
//       </h1> 
//       <img
//         src={iconImage}
//         alt="Close"
//         className='designation-close'
//         onClick={handleCloseUpload}
//       />
//     </div>

//     <div  className='designation-form'>
//       <label className='designation-label'>File Name</label>
//       <input
//         type="text"
//         value={fileName}
//         onChange={(e) => setFileName(e.target.value)}
//          className='designation-input'
//       />
//       <label className='designation-label'>Category</label>
//       <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
//         <select
//           value={categoryId}
//           onChange={handleCategoryChange}
//            className='designation-input'
//         >
//           <option value="">Select a category</option>
//           {categories.map((category) => (
//             <option key={category.id} value={category.id}>
//               {category.name}
//             </option>
//           ))}
//         </select>
//         <button className="color-button" style={{ marginBottom: '15px' }} onClick={handleAddCategory}>
//           Add 
//         </button>
//       </div>

//       <label className='designation-label'>Status</label>
//       <select
//         value={status}
//         onChange={(e) => setStatus(e.target.value)}
//          className='designation-input'
//       >
//         <option value="for_review">For Review</option>
//         <option value="good_for_construction">Good For Construction</option>
//         <option value="deleted">Deleted</option>
//         <option value="updated">Updated</option>
//       </select>

//        {/* Milestone Select Dropdown */}
//        <label className='designation-label'>Milestone ID</label>
// <select
//   value={milestoneId}
//   onChange={handleMilestoneChange}
//    className='designation-input'
// >
//   <option value="">Select a milestone</option>
//   {milestones.map((milestone) => (
//     <option key={milestone.id} value={milestone.id}>
//       {milestone.milestone_title}
//     </option>
//   ))}
// </select>


//       {/* New Task ID Field */}
// <label className='designation-label'>Task ID</label>
// <select
//   value={taskId}
//   onChange={(e) => setTaskId(e.target.value)}
//    className='designation-input'
//   disabled={!milestoneId} // Disable if no Milestone ID is selected
// >
//   <option value="">Select a task</option>
//   {tasks.map((task) => (
//     <option key={task.id} value={task.id}>
//       {task.heading}
//     </option>
//   ))}
// </select>


//       <label className='designation-label'>Select File</label>
//       <input
//         type="file"
//         onChange={(e) => setUploadedFile(e.target.files[0])}
//         accept=".png,.jpg,.jpeg,.pdf"
//          className='designation-input'
//       />
//       {uploadedFile && (
//         <p style={{ color: "#666" }}>Selected File: {uploadedFile.name}</p>
//       )}
//     </div>

//       <button onClick={handleSubmit} className="designation-save">
//         Upload
//       </button>

// </Box>
// </Modal>


//       {/* Render the list of existing files */}  
//       <table >
//         <thead>
//           <tr>
//             <th>Category</th>
//             <th>Filename</th>
//             <th>Task Id</th>
//             <th>Uploaded By</th>
//             <th>Created At</th>
//             <th>Status</th>
//             <th>Action</th>
//           </tr>
//         </thead>
//         <tbody>
//   {filteredFiles.map((file, index) => (
//     <tr key={index}>
//       <td>{file.category_name}</td>
//       <td>
//         <span style={{ textDecoration: file.status === 'deleted' ? 'line-through' : 'none' }}>
//           {file.filename}
//         </span>
//       </td>
//       <td style={{ textAlign: 'center' }}>{file.task_id}</td>
//       <td>{file.uploaded_by}</td>
//       <td style={{ textAlign: 'center' }}>
//         {file.created_at ? new Date(file.created_at).toLocaleString() : 'N/A'}
//       </td>
//       <td> <span
//           style={{
//             fontWeight: file.status === 'deleted' ? 'bold' : 'normal',
//             color: file.status === 'deleted' ? 'red' : 'inherit'
//           }}
//         >{file.status}</span></td>
//       <td style={{ textAlign: 'center' }}>
//         <span
//           style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
//           onClick={() => window.open(file.filepath, '_blank', 'noopener,noreferrer')}
//         >
//           View File
//         </span>
//       </td>
//     </tr>
//   ))}
// </tbody>

//       </table>


//       <Modal
//     open={isAddCategoryModalOpen}
//     onClose={() => setIsAddCategoryModalOpen(false)}
//   >
//     <Box
//       sx={{
//         p: 3,
//         backgroundColor: "white",
//         margin: "auto",
//         mt: "15%",
//         maxWidth: "350px",
//         borderRadius: "16px",
//       }}
//     >
//       {/* Modal Header */}
//       <div className="designation-header">
//         <h1 className="designation-title">Add New Category</h1>
//         <img
//           src={iconImage}
//           alt="Close"
//           className="designation-close"
//           onClick={() => setIsAddCategoryModalOpen(false)}
//         />
//       </div>

//       {/* Input Field */}
//       <div className="designation-form">
//         <label className="designation-label">Category Name:</label>
//         <input
//           type="text"
//           id="categoryName"
//           value={newCategoryName}
//           onChange={(e) => setNewCategoryName(e.target.value)}
//           placeholder="Enter category name"
//           className="designation-input"
//         />
//       </div>

//       <button className="designation-save" onClick={handleSaveCategory}>
//         Save
//       </button>
//     </Box>
//   </Modal>


//     </Box>
//   );
// }


// export default Files;






import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  Modal,


} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'; // Import SweetAlert2
import iconImage from '../assets/Icon.png';
import './../Screens/css/Designation.css';




function Files({ projectId, projectName }) {
  const [open, setOpen] = useState(false); // For modal open/close state
  const [uploadedFile, setUploadedFile] = useState(null); // State for uploaded files
  const [fileList, setFileList] = useState([]); // State to store the list of files fetched from the API
  const [searchTerm, setSearchTerm] = useState(''); // State for the search term
  const [fileName, setFileName] = useState(''); // State for file name
  const [categoryId, setCategoryId] = useState(''); // State for category ID
  const [status, setStatus] = useState('');
  const [taskId, setTaskId] = useState('');
  const [milestoneId, setMilestoneId] = useState('');
  const [categories, setCategories] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [error, setError] = useState('');
  const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');




  // Function to fetch existing files
  const fetchFiles = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error('No auth token found.');
      return;
    }




    const url = `https://api.myntask.com/project/files/${projectId}`;




    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });




      if (!response.ok) {
        throw new Error('Failed to fetch files');
      }




      const data = await response.json();
      setFileList(data);
    } catch (error) {




    }
  };




  // Fetch files when the component mounts
  useEffect(() => {
    fetchFiles();
  }, [projectId]);




  // Handle opening and closing the upload modal
  const handleOpenUpload = () => setOpen(true);




  const handleCloseUpload = () => {
    setOpen(false);
    setUploadedFile(null);
    setFileName('');
    setCategoryId('');
    setStatus('');
    setTaskId('');  // Reset taskId
    setMilestoneId('');  // Reset milestoneId
  };








  // Dropzone logic for drag-and-drop file upload
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.pdf, .jpg, .jpeg, .png', // Accepting images and PDFs
    multiple: true, // Allow multiple files
    onDrop: (acceptedFiles) => {
      setUploadedFile(acceptedFiles);
    },
  });




  const fetchCategories = async () => {
    const token = localStorage.getItem('authToken');


    if (!token) {
      setError('No token found. Please log in.');
      return;
    }


    try {
      const response = await fetch(`https://api.myntask.com/project/file_category_dropdown/${projectId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });


      if (!response.ok) {
        throw new Error('Failed to fetch categories');
      }


      const result = await response.json();
      console.log('Categories:', result);
      setCategories(result);


    } catch (err) {
      console.error('Error fetching categories:', err);
      setError('Failed to fetch categories');
    }
  };


  const fetchMilestones = async () => {
    const token = localStorage.getItem('authToken');


    if (!token) {
      setError('No token found. Please log in.');
      return;
    }


    try {
      const response = await fetch(`https://api.myntask.com/project/milestone_list/${projectId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });


      if (!response.ok) {
        throw new Error('Failed to fetch Milestones');
      }


      const result = await response.json();
      console.log('Milestones:', result);
      setMilestones(result);


    } catch (err) {
      console.error('Error fetching Milestones:', err);
      setError('Failed to fetch Milestones');
    }
  };


  useEffect(() => {
    fetchCategories();
    fetchMilestones();
  }, [projectId]);


  const handleAddCategory = () => {
    setIsAddCategoryModalOpen(true);
  };


  const handleSaveCategory = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'No token found. Please log in.',
        toast: true,
        position: 'top-end',
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }


    try {
      const response = await fetch(
        'https://api.myntask.com/project/file_category_save',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: newCategoryName,
            project_id: projectId, // Replace this with the actual project ID
          }),
        }
      );


      if (!response.ok) {
        throw new Error('Failed to save category');
      }


      const result = await response.json();
      setCategories([...categories, result]); // Add the new category to the dropdown
      fetchCategories();
      setIsAddCategoryModalOpen(false); // Close the modal
      setNewCategoryName(''); // Clear the input


      // Success message
      Swal.fire({
        icon: 'success',
        title: 'Category Saved!',
        text: 'The new category has been added successfully.',
        toast: true,
        position: 'top-end',
        timer: 1500,
        showConfirmButton: false,
      });
    } catch (err) {
      console.error(err);


      // Error message
      Swal.fire({
        icon: 'error',
        title: 'Save Failed',
        text: 'Error saving category. Please try again.',
        toast: true,
        position: 'top-end',
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };


  const handleCategoryChange = (e) => {
    setCategoryId(e.target.value);
    console.log('Selected Category ID:', e.target.value);
  };


  const handleMilestoneChange = (e) => {
    const selectedMilestoneId = e.target.value;
    setMilestoneId(selectedMilestoneId);
    setTasks([]); // Clear tasks when a new milestone is selected
    if (selectedMilestoneId) {
      fetchTasks(selectedMilestoneId); // Fetch tasks for the selected milestone
    }
  };


  const fetchTasks = async (milestoneId) => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      console.error("Authentication token is missing.");
      return;
    }


    try {
      const response = await fetch(`https://api.myntask.com/project/milestone_task_dropdown/${milestoneId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });


      if (!response.ok) {
        throw new Error("Failed to fetch tasks");
      }


      const result = await response.json();
      setTasks(result); // Update the tasks state with fetched data
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };




  const handleSubmit = async () => {
    if (!uploadedFile) {
      Swal.fire({
        icon: 'error',
        title: 'No file selected',
        text: 'Please select a file to upload.',
        toast: true,
        position: 'top-end',
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }


    if (!fileName || !categoryId) {
      Swal.fire({
        icon: 'error',
        title: 'Missing Fields',
        text: 'All fields are required.',
        toast: true,
        position: 'top-end',
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }


    const token = localStorage.getItem('authToken');
    if (!token) {
      Swal.fire({
        icon: 'error',
        title: 'Authentication Error',
        text: 'No token found. Please log in.',
        toast: true,
        position: 'top-end',
        timer: 1500,
        showConfirmButton: false,
      });
      return;
    }


    // Create FormData and append the file(s) to it
    const formData = new FormData();
    formData.append('project_id', projectId);
    formData.append('category_id', categoryId);
    formData.append('file_name', fileName);
    formData.append('status', status);
    formData.append('file', uploadedFile);
    formData.append('task_id', taskId);  // Add task_id to FormData
    formData.append('milestone_id', milestoneId);  // Add milestone_id to FormData


    // Log FormData content
    console.log("FormData content:");
    formData.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });




    const url = `https://api.myntask.com/project/file_save`; // API URL


    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`, // Ensure token is correct and valid
        },
        body: formData,
      });


      if (!response.ok) {
        const errorResponse = await response.text();
        console.error('Error response:', response.status, response.statusText, errorResponse);
        throw new Error(`Network response was not ok. Status code: ${response.status}`);
      }


      // Refresh files and close modal
      fetchFiles(projectId);


      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'File uploaded successfully.',
        toast: true,
        position: 'top-end',
        timer: 1500,
        showConfirmButton: false,
      });
      handleCloseUpload(); // Close the modal after successful upload
    } catch (error) {
      console.error('Error uploading file:', error);
      Swal.fire({
        icon: 'error',
        title: 'Upload Failed',
        text: 'Error uploading file. Please try again.',
        toast: true,
        position: 'top-end',
        timer: 1500,
        showConfirmButton: false,
      });
    }
  };






  // Filtered list of files based on the search term
  const filteredFiles = fileList.filter((file) =>
    file.filename.toLowerCase().includes(searchTerm.toLowerCase())
  );




  return (
    <Box style={{ padding: '10px 30px' }}>
      <div className="top-controls">
        <h1 className='title'>{projectName} - Files
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={handleOpenUpload}>
            Upload Files
          </button>
        </div>
      </div>


      <div className="top-controls">
        <div className="filters-and-search">
          <input
            type="text"
            placeholder="Search Files"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className='searchbar'
            style={{
              width: '250px',
            }}
          />


        </div>
      </div>


      {/* Upload Modal  */}
      <Modal className="modal-overlay-deal" open={open} onClose={handleCloseUpload}>
        <div className="modal-content-deal" style={{ width: '450px' }}>
          <h1 className="modal-title-deal" >
            Add Document
          </h1>
          <img
            src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
            alt="Close"
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              border: "none",
              cursor: "pointer",
            }}
            onClick={handleCloseUpload}
          />


          <div className="form-field-group-deal">
            <label className="form-label-deal">File Name</label>
            <input
              type="text"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              className="form-input-deal"
            />
            <label className="form-label-deal">Category</label>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <select
                value={categoryId}
                onChange={handleCategoryChange}
                className='designation-input'
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              <button className="submit-button-deal" style={{ marginBottom: '15px' }} onClick={handleAddCategory}>
                Add
              </button>
            </div>


            <label className="form-label-deal">Status</label>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="form-input-deal"
            >
              <option value="for_review">For Review</option>
              <option value="good_for_construction">Good For Construction</option>
              <option value="deleted">Deleted</option>
              <option value="updated">Updated</option>
            </select>


            {/* Milestone Select Dropdown */}
            <label className="form-label-deal">Milestone ID</label>
            <select
              value={milestoneId}
              onChange={handleMilestoneChange}
              className="form-input-deal"
            >
              <option value="">Select a milestone</option>
              {milestones.map((milestone) => (
                <option key={milestone.id} value={milestone.id}>
                  {milestone.milestone_title}
                </option>
              ))}
            </select>




            {/* New Task ID Field */}
            <label className="form-label-deal">Task ID</label>
            <select
              value={taskId}
              onChange={(e) => setTaskId(e.target.value)}
              className="form-input-deal"
              disabled={!milestoneId} // Disable if no Milestone ID is selected
            >
              <option value="">Select a task</option>
              {tasks.map((task) => (
                <option key={task.id} value={task.id}>
                  {task.heading}
                </option>
              ))}
            </select>




            <label className="form-label-deal">Select File</label>
            <input
              type="file"
              onChange={(e) => setUploadedFile(e.target.files[0])}
              accept=".png,.jpg,.jpeg,.pdf"
              className="form-input-deal"
            />
            {uploadedFile && (
              <p style={{ color: "#666" }}>Selected File: {uploadedFile.name}</p>
            )}
          </div>
          <div className="form-button-group-deal">
            <button onClick={handleSubmit} className="submit-button-deal">
              Upload
            </button>
          </div>


        </div>
      </Modal>




      {/* Render the list of existing files */}
      <table >
        <thead>
          <tr>
            <th>Category</th>
            <th>Filename</th>
            <th>Task Id</th>
            <th>Uploaded By</th>
            <th>Created At</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredFiles.map((file, index) => (
            <tr key={index}>
              <td>{file.category_name}</td>
              <td>
                <span style={{ textDecoration: file.status === 'deleted' ? 'line-through' : 'none' }}>
                  {file.filename}
                </span>
              </td>
              <td style={{ textAlign: 'center' }}>{file.task_id}</td>
              <td>{file.uploaded_by}</td>
              <td style={{ textAlign: 'center' }}>
                {file.created_at ? new Date(file.created_at).toLocaleString() : 'N/A'}
              </td>
              <td> <span
                style={{
                  fontWeight: file.status === 'deleted' ? 'bold' : 'normal',
                  color: file.status === 'deleted' ? 'red' : 'inherit'
                }}
              >{file.status}</span></td>
              <td style={{ textAlign: 'center' }}>
                <span
                  style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => window.open(file.filepath, '_blank', 'noopener,noreferrer')}
                >
                  View File
                </span>
              </td>
            </tr>
          ))}
        </tbody>


      </table>




      <Modal
        open={isAddCategoryModalOpen}
        onClose={() => setIsAddCategoryModalOpen(false)}
      >
        <Box
          sx={{
            p: 3,
            backgroundColor: "white",
            margin: "auto",
            mt: "15%",
            maxWidth: "350px",
            borderRadius: "16px",
          }}
        >
          {/* Modal Header */}
          <div className="designation-header">
            <h1 className="designation-title">Add New Category</h1>
            <img
              src={iconImage}
              alt="Close"
              className="designation-close"
              onClick={() => setIsAddCategoryModalOpen(false)}
            />
          </div>


          {/* Input Field */}
          <div className="designation-form">
            <label className="designation-label">Category Name:</label>
            <input
              type="text"
              id="categoryName"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              placeholder="Enter category name"
              className="designation-input"
            />
          </div>


          <button className="designation-save" onClick={handleSaveCategory}>
            Save
          </button>
        </Box>
      </Modal>




    </Box>
  );
}




export default Files;


















