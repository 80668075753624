import React, { useState, useEffect } from 'react';
import './AddProjectPage.css';
import { Checkbox, FormControlLabel, Select, MenuItem, InputLabel, FormControl, Modal, Button, IconButton, Box, Typography, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import swal from 'sweetalert';
import iconImage from '../assets/Icon.png';
import AddClientModal from './AddClientModal';
import './../Screens/css/Designation.css';
import defaultimage from '../assets/vector.jpeg';
import { Vortex } from 'react-loader-spinner';








const AddProjectPage = () => {
  const navigate = useNavigate();



  const [newProject, setNewProject] = useState({
    project_name: '',
    start_date: '',
    deadline: '',
    category_id: '',
    department_id: '',
    client_id: '',
    project_summary: '',
    notes: '',
    public_project: 0,
    currency_id: '',
    project_budget: '',
    total_points: '',
    project_member: [],
  });








  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [members, setMembers] = useState([]);
  const [clients, setClients] = useState([]);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [openDeleteCategoryModal, setOpenDeleteCategoryModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [viewClientModal, setViewClientModal] = useState(false);




  const fetchData = async (url, setter) => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setter(data || []);
      setIsLoading(false);
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
      setError(`Failed to fetch data from ${url}`);
      setter([]);
    }
  };


  const fetchClient = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await fetch('https://api.myntask.com/project/client', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setClients(data || []);  // Update the state with the fetched clients
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching clients:', error);
      setError('Failed to fetch clients');
      setClients([]);  // Set an empty array in case of an error
    }
  };





  useEffect(() => {
    fetchData('https://api.myntask.com/project/category', setCategories);
    fetchData('https://api.myntask.com/employee/department', setDepartments);
    fetchData('https://api.myntask.com/company/currency', setCurrency);
    fetchData('https://api.myntask.com/project/member_list', setMembers);
    fetchClient();
  }, []);








  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewProject((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? (checked ? 1 : 0) : value,
    }));
  };






  const handleMultiSelect = (memberId) => {
    setNewProject((prevData) => {
      const updatedMembers = prevData.project_member.includes(memberId)
        ? prevData.project_member.filter((id) => id !== memberId) // Remove member if already selected
        : [...prevData.project_member, memberId]; // Add member if not selected

      return { ...prevData, project_member: updatedMembers };
    });
  };





  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('authToken');

    setIsLoading(true);
    try {
      const response = await fetch('https://api.myntask.com/project/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newProject),
      });


      if (!response.ok) {
        let errorText;
        try {
          errorText = await response.json();
        } catch {
          errorText = await response.text();
        }
        throw new Error(`Failed to save project: ${response.status} - ${errorText}`);
      }


      Swal.fire({
        icon: 'success',
        title: 'Project saved successfully!',
        timer: 1500,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
      });


      setTimeout(() => {
        navigate('/project');
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      console.error('Error saving project:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error saving project. Please try again.',
        timer: 1500,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
      });
    }
    setIsLoading(false);
  };





  const handleAddCategory = () => {
    setOpenCategoryModal(true);
  };



  const handleDeleteCategoryModalOpen = () => {
    setOpenDeleteCategoryModal(true);
  };



  const handleSaveCategory = async () => {
    const token = localStorage.getItem('authToken');
    setIsLoading(true);
    try {
      const response = await fetch('https://api.myntask.com/project/project_category_add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ name: newCategory }),
      });



      if (!response.ok) {
        throw new Error('Failed to add category');
      }


      // Success alert using SweetAlert
      swal({
        title: 'Success!',
        text: 'Category added successfully!',
        icon: 'success',
        timer: 1500,
        buttons: false,
      });


      setOpenCategoryModal(false);
      setNewCategory('');
      fetchData('https://api.myntask.com/project/category', setCategories);
      setIsLoading(false);
    } catch (error) {
      console.error('Error adding category:', error);
      setError('Failed to add category');

      // Error alert using SweetAlert
      swal({
        title: 'Error',
        text: 'Error adding category. Please try again.',
        icon: 'error',
        timer: 1500,
        buttons: false,
      });
    }
    setIsLoading(false);
  };







  const handleDeleteCategory = async (categoryId) => {
    const token = localStorage.getItem('authToken');


    setIsLoading(true);
    try {
      // Ask for confirmation using SweetAlert
      const willDelete = await swal({
        title: 'Are you sure?',
        text: 'Do you really want to delete this category? This action cannot be undone.',
        icon: 'warning',
        buttons: ['Cancel', 'Yes, delete it!'],
        dangerMode: true,
      });




      if (!willDelete) {
        return; // User canceled the deletion
      }


      const response = await fetch(`https://api.myntask.com/project/project_category_delete/${categoryId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });


      if (!response.ok) {
        throw new Error('Failed to delete category');
      }

      // Show success alert
      swal({
        title: 'Deleted!',
        text: 'Category deleted successfully!',
        icon: 'success',
        timer: 1500,
        buttons: false,
      });


      fetchData('https://api.myntask.com/project/category', setCategories);
      setIsLoading(false);
    } catch (error) {
      console.error('Error deleting category:', error);
      setError('Failed to delete category');

      // Show error alert
      swal({
        title: 'Error',
        text: 'Error deleting category. Please try again.',
        icon: 'error',
        timer: 1500,
        buttons: false,
      });
    }
    setIsLoading(false);
  };








  const handleCancel = () => {
    navigate('/project');
  };








  const handleViewClientModal = () => {
    console.log("Opening client modal");
    setViewClientModal(true);
  }




  const handleCloseClientModal = () => {
    setViewClientModal(false);
  }






  useEffect(() => {
    // Adjust the timer duration here (e.g., 3000ms = 3 seconds)
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 300);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);






  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }






  return (
    <div className="formContainer">

      <h3 className="center-heading">Add Project</h3>


      <form onSubmit={handleSubmit}>
        <div className="employeeForm" >
          <div className="container1">
            <label className="label">Project Name</label>
            <input
              className="field"
              type="text"
              id="project_name"
              name="project_name"
              value={newProject.project_name}
              onChange={handleChange}
              required
            />
          </div>


          <div className="container1">
            <label className="label">Start Date</label>
            <input
              className="field"
              type="date"
              id="start_date"
              name="start_date"
              value={newProject.start_date}
              onChange={handleChange}
            />
          </div>



          <div className="container1">
            <label className="label">Deadline</label>
            <input
              className="field"
              type="date"
              id="deadline"
              name="deadline"
              value={newProject.deadline}
              onChange={handleChange}
            />
          </div>



          <div className="container1">
            <label className="label">Department</label>
            <select
              className="drop"
              id="department_id"
              name="department_id"
              value={newProject.department_id}
              onChange={handleChange}

            >
              <option value="" disabled>Select Department</option>
              {departments.map((department) => (
                <option key={department.id} value={department.id}>
                  {department.department}
                </option>
              ))}
            </select>
          </div>



          <div className="container1">
            <label className="label">Client</label>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <select
                className="drop"
                id="client_id"
                name="client_id"
                value={newProject.client_id}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select Client
                </option>
                {clients.map((client) => (
                  <option key={client.client_id} value={client.client_id}>
                    {client.client_name}
                  </option>
                ))}
              </select>
              <button type="button" className="color-button" onClick={handleViewClientModal}>
                Add
              </button>
            </div>
          </div>
          <div className="container1">
            <label className="label">Category</label>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <select
                className="drop"
                id="category_id"
                name="category_id"
                value={newProject.category_id}
                onChange={handleChange}
              >
                <option value="" disabled>Select Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.category_name}
                  </option>
                ))}
              </select>
              <button type="button" className="color-button" onClick={handleAddCategory}>
                Add
              </button>
              <button type="button" className="color-button" onClick={handleDeleteCategoryModalOpen}>
                Delete
              </button>
            </div>
          </div>


          <div className="container1">
            <label className="label">Notes</label>
            <input
              className="field"
              type="text"
              id="notes"
              name="notes"
              value={newProject.notes}
              onChange={handleChange}
            />
          </div>


          <div className="container1">
            <label className="label">Currency</label>
            <select
              className="drop"
              id="currency_id"
              name="currency_id"
              value={newProject.currency_id}
              onChange={handleChange}
            >
              <option value="" disabled>Select Currency</option>
              {currency.map((cur) => (
                <option key={cur.id} value={cur.id}>
                  {cur.currency_code}
                </option>
              ))}
            </select>
          </div>


          <div className="container1">
            <label className="label">Project Member</label>
            <div
              className="custom-dropdown"
              onClick={() => setIsDropdownOpen((prev) => !prev)} // Toggle dropdown
            >
              {/* Dropdown Header */}
              <div className="selected-category">
                {newProject.project_member.length > 0
                  ? members
                    .filter((member) => newProject.project_member.includes(member.member_id))
                    .map((selectedMember) => selectedMember.member_name)
                    .join(", ")
                  : "Select Members"}
              </div>




              {/* Dropdown Options */}
              {isDropdownOpen && (
                <div className="dropdown-options">
                  {members.map((member) => (
                    <div
                      key={member.id}
                      onClick={() => handleMultiSelect(member.member_id)} // Row click toggles selection
                      style={{
                        padding: '10px',
                        display: 'flex',
                        alignContent: 'flex-start',
                        gap: '8px'
                      }}
                    >
                      <input
                        type="checkbox"
                        id={`member-${member.member_id}`}
                        value={member.member_id}
                        checked={newProject.project_member.includes(member.member_id)}
                        onClick={(e) => e.stopPropagation()} // Prevents row toggle on checkbox click
                        onChange={() => handleMultiSelect(member.member_id)} // For keyboard interaction
                      />


                      <img
                        src={member.member_image || defaultimage} // Assuming each member object has an image_url property
                        alt={member.member_name}
                        style={{
                          width: '30px',
                          height: '30px',
                          borderRadius: '50%'
                        }}
                      />
                      <label htmlFor={`member-${member.id}`}>
                        {member.member_name}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>



          <div className="container1">
            <label className="label">Project Budget</label>
            <input
              className="field"
              type="number"
              id="project_budget"
              name="project_budget"
              value={newProject.project_budget}
              onChange={handleChange}

            />
          </div>




          <div className="container1">
            <label className="label">Total Points</label>
            <input
              className="field"
              type="number"
              id="total_points"
              name="total_points"
              value={newProject.total_points}
              onChange={handleChange}
            />
          </div>



          <div className="container1">
            <FormControlLabel
              control={
                <Checkbox
                  name="public_project"
                  checked={!!newProject.public_project}
                  onChange={handleChange}
                  color="primary"
                />
              }
              label="Public Project"
            />
          </div>


        </div>
        <div className="container1" style={{ marginTop: '20px' }}>
          <label className="label">Project Summary</label>
          <textarea
            className="field"
            type="text"
            id="project_summary"
            name="project_summary"
            value={newProject.project_summary}
            onChange={handleChange}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px', gap: '15px' }}>
          <button type='button' className='color-button' onClick={handleCancel}> Cancel </button>
          <button type="submit" className="color-button">Add Project</button>
        </div>
      </form>




      <Modal
        open={openCategoryModal}
        onClose={() => setOpenCategoryModal(false)}
      >
        <Box className="designation-modal">
          {/* Header with title and close button */}
          <div className="designation-header">
            <h1 className="designation-title">Add Category</h1>
            <img
              src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
              alt="Close"
              className="designation-close"
              onClick={() => setOpenCategoryModal(false)}
            />
          </div>



          {/* Form Section */}
          <div className="designation-form">
            <div className="form-group">
              <input
                type="text"
                name="newCategory"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                placeholder="Category Name"
                className="designation-input"
                required
              />
            </div>


            <button className='designation-save' onClick={handleSaveCategory}>
              Save
            </button>
          </div>
        </Box>
      </Modal>






      {/* Modal for Deleting Category */}
      <Modal open={openDeleteCategoryModal} onClose={() => setOpenDeleteCategoryModal(false)}
        className='modal-overlay-deal'>
        <div className="modal-container delete-modal">
          <h2>Delete Category</h2>
          <div className="category-list delete-category-list">
            {categories.map((category) => (
              <div key={category.id} className="category-item">
                <span className="category-name">{category.category_name}</span>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDeleteCategory(category.id)}
                  color="secondary"
                  className="delete-button"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button variant="outlined" onClick={() => setOpenDeleteCategoryModal(false)}>
              Close
            </Button>
          </div>
        </div>
      </Modal>


      <AddClientModal
        isOpen={viewClientModal}
        onClose={handleCloseClientModal}
        fetchClient={fetchClient}  // Pass fetchClient to the modal
      />


    </div>
  );
};








export default AddProjectPage;










































