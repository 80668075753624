import React, { useState, useEffect } from 'react';
import './ProjectMember.css';
import Swal from 'sweetalert2';

const ProjectMember = () => {
  const [totalMembers, setTotalMembers] = useState([]); // Renamed from nonSelected
  const [selectedMembers, setSelectedMembers] = useState([]); // Now fetches from API
  const [loadingTotal, setLoadingTotal] = useState(true);
  const [loadingSelected, setLoadingSelected] = useState(true);
  const [error, setError] = useState(null);

  const fetchMembers = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('Authorization token is missing');
      }

      // Fetch total members
      setLoadingTotal(true);
      const totalResponse = await fetch('https://api.myntask.com/employee/member', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (!totalResponse.ok) {
        throw new Error('Failed to fetch total members');
      }
      const totalData = await totalResponse.json();
      const formattedTotal = totalData.map((member) => ({
        id: member.id,
        name: member.member_name,
      }));
      setTotalMembers(formattedTotal);

      // Fetch selected members
      setLoadingSelected(true);
      const selectedResponse = await fetch('https://api.myntask.com/project/member_list', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (!selectedResponse.ok) {
        throw new Error('Failed to fetch selected members');
      }
      const selectedData = await selectedResponse.json();
      const formattedSelected = selectedData.map((member) => ({
        id: member.id,
        member_id: member.member_id,
        name: member.member_name,
      }));
      setSelectedMembers(formattedSelected);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoadingTotal(false);
      setLoadingSelected(false);
    }
  };


  useEffect(() => {

    fetchMembers();
  }, []);



  const handleAddMembers = async (selectedIds) => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        throw new Error('Authorization token is missing');
      }

      const response = await fetch('https://api.myntask.com/project/member_list_add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ user_ids: selectedIds }),
      });

      if (!response.ok) {
        throw new Error('Failed to add members');
      }

      // Update selected members state
      const addedMembers = totalMembers.filter((member) => selectedIds.includes(member.id));
      setSelectedMembers([...selectedMembers, ...addedMembers]);

      // Show success notification
      Swal.fire({
        icon: 'success',
        title: 'Members Added',
        text: 'Selected members have been successfully added.',
        timer: 2000,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });
    } catch (err) {
      setError(err.message);

      // Show error notification
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: err.message,
        timer: 3000,
        showConfirmButton: false,
        toast: true,
        position: 'top-end',
      });
    }
  };


  const handleMoveToSelected = () => {
    const selectedIds = Array.from(
      document.querySelectorAll(`#totalMembers option:checked`)
    ).map((option) => parseInt(option.value));

    if (selectedIds.length > 0) {
      handleAddMembers(selectedIds);
    }
  };

  const handleMoveToTotal = async () => {
    const selectedOption = document.querySelector(`#selectedMembers option:checked`);

    if (selectedOption) {
      const selectedId = parseInt(selectedOption.value);
      console.log("Selected ID:", selectedId); // Log the ID being sent

      // Show SweetAlert for confirmation
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to remove the selected project member?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove',
        cancelButtonText: 'Cancel',
      });

      if (result.isConfirmed) {
        try {
          const authToken = localStorage.getItem('authToken');
          if (!authToken) {
            throw new Error('Authorization token is missing');
          }

          // Send DELETE request to the API
          const response = await fetch(
            `https://api.myntask.com/project/member_list_delete/${selectedId}`,
            {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
              },
            }
          );

          console.log("API Response:", response); // Log the API response object

          if (!response.ok) {
            throw new Error('Failed to remove member');
          }

          const responseData = await response.json();
          console.log("Response Data:", responseData); // Log the response data

          // Update the state
          const removedMember = selectedMembers.find((member) => member.id === selectedId);
          setSelectedMembers((prevSelected) => prevSelected.filter((member) => member.id !== selectedId));
          setTotalMembers((prevTotal) => [...prevTotal, removedMember]);
          fetchMembers();
          // Show success notification
          Swal.fire({
            icon: 'success',
            title: ' Member Removed',
            text: 'The selected member has been successfully removed.',
            timer: 1500,
            showConfirmButton: false,
            toast: true,
            position: 'top-end',
          });
        } catch (err) {
          setError(err.message);

          // Show error notification
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: err.message,
            timer: 1500,
            showConfirmButton: false,
            toast: true,
            position: 'top-end',
          });
        }
      }
    }
  };

  return (
    <div className="permissions-container">
      <div className="top-controls">
        <h1 className="title">
          Project Member Settings
          <div className="underline"></div>
        </h1>
      </div>

      <div className="project-member">
        <div className="member-box">
          <h3>Total Employees</h3>
          {loadingTotal ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="error">{error}</p>
          ) : (
            <select id="totalMembers" multiple>
              {totalMembers.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {employee.name}
                </option>
              ))}
            </select>
          )}
        </div>

        <div className="buttons">
          <button onClick={handleMoveToSelected}>&gt;&gt;</button>
          <button onClick={handleMoveToTotal}>&lt;&lt;</button> {/* Left Arrow Button */}
        </div>

        <div className="member-box">
          <h3>Selected Employees</h3>
          {loadingSelected ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="error">{error}</p>
          ) : (
            <select id="selectedMembers" multiple>
              {selectedMembers.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {employee.name}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectMember;
