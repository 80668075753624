
import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Pagination, Tooltip, Box, Stack, } from '@mui/material';
import { Vortex } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import defaultimage from '../assets/vector.jpeg';
import { GridMoreVertIcon } from '@mui/x-data-grid';
import { ToastContainer } from 'react-toastify';
import './../Components/Main.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const PAGE_SIZE = 15;

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [action, setAction] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [statuses, setStatuses] = useState([]);
  const [actionMenuVisible, setActionMenuVisible] = useState({});
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [hoveredProjectId, setHoveredProjectId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(''); // State for the selected status
  const actionMenuRef = useRef(null);
  const navigate = useNavigate();
  const [sortField, setSortField] = useState(null); // No default sorting field
  const [sortOrder, setSortOrder] = useState(null); // No default sorting order


  const fetchProjects = async (status) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No authentication token found.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`https://api.myntask.com/project/list?status_name=${status || ''}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok. Status: ${response.status}`);
      }

      const data = await response.json();

      // Check if projects data is an array and handle cases where it might be empty or null
      if (data.projects && Array.isArray(data.projects)) {
        const sortedData = sortProjects(data.projects); // Sort the data here
        setProjects(sortedData);
        setTotalPages(Math.ceil(sortedData.length / PAGE_SIZE));
      } else {
        // Set an empty array when no projects are returned to avoid showing an error
        setProjects([]);
        setTotalPages(0);
      }

      // Set permissions from the API response
      if (data.project_permission) {
        setPermissions(data.project_permission);
      } else {
        setPermissions({}); // Clear permissions if none received
      }
    } catch (error) {
      setError('Failed to fetch projects. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchStatuses = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No authentication token found.');
      return;
    }

    try {
      const response = await fetch('https://api.myntask.com/project/status', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok. Status: ${response.status}`);
      }

      const data = await response.json();
      setStatuses(data);
    } catch (error) {
      setError('Failed to fetch statuses. Please try again later.');
    }
  };

  const sortProjects = (projects) => {
    // Only sort if a sort field is set
    if (!sortField || !sortOrder) {
      return projects; // Return original order if no sorting
    }

    return projects.sort((a, b) => {
      let aValue = a[sortField];
      let bValue = b[sortField];

      // Handle date fields explicitly
      if (sortField === 'start_date' || sortField === 'deadline') {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      }
      // Handle number fields (e.g., completion_percentage or total_points)
      else if (typeof aValue === 'number' || !isNaN(aValue)) {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
      }
      // Handle string fields (e.g., project_name)
      else {
        aValue = aValue.toString().toLowerCase();
        bValue = bValue.toString().toLowerCase();
      }

      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const handleSort = (field) => {
    // Toggle sort order and set field
    const newSortOrder = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortOrder(newSortOrder);

    const sortedProjects = sortProjects([...projects]); // Create a new sorted copy
    setProjects(sortedProjects);
  };


  useEffect(() => {
    fetchStatuses(); // Fetch statuses initially
    fetchProjects(selectedStatus); // Fetch projects based on initial selected status (empty initially)
  }, []);

  useEffect(() => {
    fetchStatuses(); // Fetch statuses initially

  }, []);

  const handleDownload = () => {
    const doc = new jsPDF();

    const tableColumn = ["Code", "Project Name", "Start Date", "Deadline", "Status", "Total Task Points", "Clients", "Members"];
    const tableRows = [];

    filteredProjects.forEach(project => {
      const membersNames = project.members && project.members.length > 0
        ? project.members.map(member => member.member_name).join(', ')
        : 'No members available';

      const rowData = [
        project.code || 'N/A',
        project.project_name || 'N/A',
        project.start_date || 'N/A',
        project.deadline || 'N/A',
        project.status || 'N/A',
        project.total_task_points || 'N/A',
        project.clients && project.clients.length > 0 ? project.clients[0]?.company_name || 'N/A' : 'No client available',
        membersNames
      ];

      tableRows.push(rowData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });

    doc.save('projects_table.pdf');
  };


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleAddProject = () => {
    navigate('/addproject');
  };

  const handleCheckboxChange = (projectId) => {
    setSelectedProjects((prevSelected) =>
      prevSelected.includes(projectId)
        ? prevSelected.filter((id) => id !== projectId)
        : [...prevSelected, projectId]
    );
  };

  const handleActionChange = (event) => {
    setAction(event.target.value);
  };

  const handleDelete = async (projectId) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No authentication token found.');
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No authentication token found.',
      });
      return;
    }

    // SweetAlert confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });

    // If the user cancels, exit the function
    if (!result.isConfirmed) {
      return;
    }
    setLoading(true);

    try {
      const response = await fetch('https://api.myntask.com/project/delete', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ project_id: projectId }),
      });

      if (!response.ok) {
        throw new Error(`Failed to delete project with ID: ${projectId}`);
      }

      const responseData = await response.json();

      // Update the UI to reflect deleted project
      setProjects((prevProjects) =>
        prevProjects.filter((project) => project.project_id !== projectId)
      );
      setTotalPages(Math.ceil(projects.length / PAGE_SIZE));
      fetchProjects();
      // SweetAlert success message
      await Swal.fire({
        icon: 'success',
        title: 'Deleted!',
        text: 'Project has been deleted successfully.',
        confirmButtonColor: '#3085d6',
      });
      setLoading(false);

    } catch (error) {
      console.error(error);
      setError('Failed to delete project. Please try again later.');

      // SweetAlert error message
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to delete project. Please try again.',
        confirmButtonColor: '#d33',
      });
    }
  };


  const handleApply = () => {
    if (action === 'edit' && selectedProjects.length === 1) {
      navigate(`/editproject/${selectedProjects[0]}`);
    } else if (action === 'delete') {
      handleDelete(selectedProjects);  // Pass selectedProjects to handleDelete
    }
    setSelectedProjects([]);
    setAction('');
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };



  const handleDropdownChange = async (event, projectId) => {
    const { value } = event.target;
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('No authentication token found.');
      Swal.fire({
        icon: 'error',
        title: 'No authentication token found.',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    try {
      const response = await fetch('https://api.myntask.com/project/status_update', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          project_id: projectId,
          status_name: value,
        }),
      });

      if (response.ok) {
        console.log('Status updated successfully!');
        Swal.fire({
          icon: 'success',
          title: 'Project status updated successfully!',
          showConfirmButton: false,
          timer: 1500,
        });

        // Update project status in the state
        setProjects((prevProjects) =>
          prevProjects.map((project) =>
            project.project_id === projectId ? { ...project, status: value } : project
          )
        );
      } else {
        console.error('Error updating status:', response.statusText);
        Swal.fire({
          icon: 'error',
          title: 'Failed to update project status. Please try again.',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      console.error('Error updating status:', error);
      setError('Failed to update project status.');
      Swal.fire({
        icon: 'error',
        title: 'Failed to update project status. Please try again.',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };


  const filteredProjects = projects
    .filter((project) => project.project_name.toLowerCase().includes(searchQuery))
    .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);

  const toggleActionMenu = (id) => {
    setActionMenuVisible((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
    setSelectedProjectId(id);
  };

  const handleActionMenuClick = async (actionType, selectedProjectId) => {
    if (actionType === 'edit') {
      navigate(`/editproject/${selectedProjectId}`);
    } else if (actionType === 'delete') {
      await handleDelete([selectedProjectId]);  // Pass the project ID directly
    }

    setActionMenuVisible((prevState) => ({
      ...prevState,
      [selectedProjectId]: false,
    }));
  };


  // Close action menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionMenuRef.current && !actionMenuRef.current.contains(event.target)) {
        setActionMenuVisible({});
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [actionMenuRef]);


  const handleProjectClick = (projectId, projectName) => {
    navigate(`/Projectdashboard/${projectId}`, { state: { projectName } });
  };

  const clearFilters = () => {
    setSearchQuery('');       // Clear search input
    setSortField(null);       // Reset sort field
    setSortOrder(null);       // Reset sort order
    setSelectedStatus(null);    // Reset status filter
    fetchProjects();          // Fetch all projects without filters
  };



  // Utility function to convert text to Title Case
  const toTitleCase = (text) => {
    if (!text) return 'N/A'; // handle empty strings or undefined cases
    return text
      .toLowerCase() // Convert the entire string to lowercase first
      .split(' ') // Split the string into words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words back into a single string
  };


  return (
    <div className="main-container">
      <ToastContainer />
      <div className="top-controls">
        <h1 className="title">
          Projects
          <div className="underline"></div>
        </h1>

        <div className="buttons-row">
          <button onClick={handleDownload} className="color-button">
            Download Table
          </button>
          {selectedProjects.length > 0 && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <select value={action} onChange={handleActionChange} className="color-button">
                <option value="" disabled>
                  Select Action
                </option>
                {permissions.edit_project === 'all' && <option value="edit">Edit</option>}
                {permissions.delete_project === 'all' && <option value="delete">Delete</option>}
              </select>
              <button
                className="color-button"
                onClick={handleApply}
                disabled={!action || (action === 'edit' && selectedProjects.length !== 1)}
              >
                Apply
              </button>
            </div>
          )}
          {permissions.add_project === 'all' && (
            <button onClick={handleAddProject} className="color-button">
              Add Projects
            </button>
          )}
        </div>
      </div>

      <div className="top-controls">
        <div className="filters-and-search">
          <button className="border-button" onClick={clearFilters}>
            Clear Filters
          </button>

          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="searchbar"
          />
        </div>
      </div>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#f5f5f5",
          }}
        >
          <Stack alignItems="center">
            <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
            <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
              Loading....
            </Box>
          </Stack>
        </Box>
      ) : (
        <>

          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span onClick={() => handleSort('code')}>Code</span>
                    <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                      {sortField === 'code' && sortOrder === 'asc' ? (
                        <span>↑</span>
                      ) : sortField === 'code' && sortOrder === 'desc' ? (
                        <span>↓</span>
                      ) : (
                        <span>↕</span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span onClick={() => handleSort('project_name')}>Project Name</span>
                    <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                      {sortField === 'project_name' && sortOrder === 'asc' ? (
                        <span>↑</span>
                      ) : sortField === 'project_name' && sortOrder === 'desc' ? (
                        <span>↓</span>
                      ) : (
                        <span>↕</span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span onClick={() => handleSort('start_date')}>Start Date</span>
                    <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                      {sortField === 'start_date' && sortOrder === 'asc' ? (
                        <span>↑</span>
                      ) : sortField === 'start_date' && sortOrder === 'desc' ? (
                        <span>↓</span>
                      ) : (
                        <span>↕</span>
                      )}
                    </div>
                  </div>
                </th>
                <th>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span onClick={() => handleSort('deadline')}>Deadline</span>
                    <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                      {sortField === 'deadline' && sortOrder === 'asc' ? (
                        <span>↑</span>
                      ) : sortField === 'deadline' && sortOrder === 'desc' ? (
                        <span>↓</span>
                      ) : (
                        <span>↕</span>
                      )}
                    </div>
                  </div>
                </th>

                <th>
                  <select className='header-filter'
                    value={selectedStatus}
                    onChange={(e) => {
                      setSelectedStatus(e.target.value);
                      fetchProjects(e.target.value); // Fetch projects with the selected status
                    }}
                  >
                    <option value="">All Statuses</option>
                    {statuses.map((status) => (
                      <option key={status.id} value={status.status_name}>
                        {status.status_name}
                      </option>
                    ))}
                  </select>
                </th>
                <th>Task Points</th>
                <th>Clients</th>
                <th>Members</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredProjects.map((project, index) => (
                <tr key={project.project_id} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                  <td >
                    <input
                      type="checkbox"
                      checked={selectedProjects.includes(project.project_id)}
                      onChange={() => handleCheckboxChange(project.project_id)}
                    />
                  </td>
                  <td>{project.code || 'N/A'}</td>
                  <td
                    className="clickable-project-name"
                    onClick={() => handleProjectClick(
                      project.project_id,
                      project.project_name ? toTitleCase(project.project_name) : 'N/A'
                    )}
                    style={{ cursor: 'pointer', fontWeight: '600' }}
                  >
                    {project.project_name ? toTitleCase(project.project_name) : 'N/A'}
                  </td>
                  <td >{project.start_date || 'N/A'}</td>
                  <td style={{ color: 'red' }}>{project.deadline || 'N/A'}</td>

                  <td className="status-cell">
                    <select
                      id="status"
                      name="status"
                      value={project.status || ''}
                      onChange={(event) => handleDropdownChange(event, project.project_id)}
                      className='table-filter'
                    >
                      <option value="" disabled>Select Status</option>
                      {statuses.map((status) => (
                        <option key={status.id} value={status.status_name}>
                          {status.status_name ? (
                            status.status_name.charAt(0).toUpperCase() + status.status_name.slice(1).toLowerCase()
                          ) : 'N/A'}
                        </option>
                      ))}
                    </select>
                    <div
                      style={styles.statusBarContainer}
                      onMouseEnter={() => setHoveredProjectId(project.project_id)}
                      onMouseLeave={() => setHoveredProjectId(null)}
                    >
                      <div style={styles.statusBarWrapper}>
                        <div style={styles.statusBar(project.completion_percentage)} />
                        <span
                          style={{
                            ...styles.tooltip,
                            ...(hoveredProjectId === project.project_id ? styles.tooltipVisible : {}),
                          }}
                        >
                          {project.completion_percentage}%
                        </span>
                      </div>
                    </div>
                  </td>

                  <td >{project.total_task_points || 'N/A'}</td>
                  <td>
                    {project.clients && project.clients.length > 0 ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          src={project.clients[0]?.client_image || defaultimage}
                          alt={project.clients[0]?.client_name}
                          className="image"

                        />

                      </div>
                    ) : (
                      <p className="no-data">No client available</p>
                    )}
                  </td>
                  <td>
                    {project.members && project.members.length > 0 ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {project.members.slice(0, 3).map((member, index) => (
                          <Tooltip key={index} title={member.member_name || 'No Name'} arrow>
                            <img
                              src={member.member_image || defaultimage}
                              alt={member.member_name || 'No Name'}
                              className="image"
                              style={{ margin: '0px' }}
                            />
                          </Tooltip>
                        ))}
                        {project.members.length > 3 && (
                          <span
                            style={{
                              backgroundColor: '#e0e0e0',
                              borderRadius: '50%',
                              padding: '0 8px',
                              lineHeight: '30px',
                              height: '30px',
                            }}
                          >
                            +{project.members.length - 3}
                          </span>
                        )}
                      </div>
                    ) : (
                      <p className="no-data">No members available</p>
                    )}
                  </td>
                  <td>
                    <div className="action-container">
                      <div className="action-dots" onClick={() => toggleActionMenu(project.project_id)}>
                        <GridMoreVertIcon />
                      </div>
                      {actionMenuVisible[project.project_id] && (
                        <div className="action-menu" ref={actionMenuRef} >
                          <div className="action-item" onClick={() => handleProjectClick(
                            project.project_id,
                            project.project_name ? toTitleCase(project.project_name) : 'N/A'
                          )}>
                            View
                          </div>
                          {/* Conditionally render Edit action */}
                          {permissions.edit_project === 'all' && (
                            <div className="action-item" onClick={() => handleActionMenuClick('edit', project.project_id)}>
                              Edit
                            </div>
                          )}

                          {/* Conditionally render Delete action */}
                          {permissions.delete_project === 'all' && (
                            <div className="action-item" onClick={() => handleActionMenuClick('delete', project.project_id)}>
                              Delete
                            </div>
                          )}

                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>

          </table>

          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              style={{ marginTop: '20px' }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Projects;


const styles = {
  statusBarContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  statusBarWrapper: {
    position: 'relative',
    width: '100%',
    backgroundColor: '#f0f0f0',
    borderRadius: '4px',
    height: '5px',
    marginBottom: '5px',
    cursor: 'pointer',
  },
  statusBar: (completion_percentage) => ({
    width: `${completion_percentage}%`,
    backgroundColor:
      completion_percentage >= 75
        ? '#4BC0C0'  // Green
        : completion_percentage >= 50
          ? '#ffce9e'  // Yellow
          : '#FF6384', // Red
    height: '100%',
    borderRadius: '4px',
  }),

  tooltip: {
    visibility: 'hidden',
    opacity: 0,
    backgroundColor: '#999',
    color: '#fff',
    textAlign: 'center',
    padding: '4px',
    borderRadius: '4px',
    position: 'absolute',
    top: '-25px',
    left: '50%',
    transform: 'translateX(-50%)',
    transition: 'opacity 0.3s ease',
    fontSize: '12px',
    whiteSpace: 'nowrap',
  },
  tooltipVisible: {
    visibility: 'visible',
    opacity: 1,
  },
};










