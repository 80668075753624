import React, { useState, useEffect } from 'react';
import { Modal, Box } from '@mui/material';
import iconImage from '../assets/Icon.png';
import './../Screens/css/Designation.css';
import Swal from "sweetalert2";

const VendorSetting = () => {
  const [categories, setCategories] = useState([]); // To store vendor category options
  const [error, setError] = useState(""); // To handle errors during API calls
  const [modalOpen, setModalOpen] = useState(false);
  const [newCategory, setNewCategory] = useState("");

  const fetchCategories = async () => {
    const token = localStorage.getItem("authToken");

    try {
      const response = await fetch('https://api.myntask.com/vendor/category/list', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`, // Add your token here
          'Content-Type': 'application/json', // Optional
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      setCategories(data); // Assuming the API response is a JSON array of categories
    } catch (err) {
      setError(err.message);
    }
  };


  useEffect(() => {
    fetchCategories();
  }, []);


  const handleCategorySave = async () => {
    if (!newCategory) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a category name.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    const token = localStorage.getItem("authToken");

    try {
      const response = await fetch("https://api.myntask.com/vendor/category/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ name: newCategory }),
      });

      if (!response.ok) {
        throw new Error("Failed to save category.");
      }
      const savedCategory = await response.json();
      setCategories((prevCategories) => [...prevCategories, savedCategory]);
      setNewCategory("");
      fetchCategories();
      setModalOpen(false); // Close modal after saving

      Swal.fire({
        icon: "success",
        title: "Category saved successfully!",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to add category. Please try again.",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    const token = localStorage.getItem('authToken');

    try {
      // Ask for confirmation using SweetAlert
      const willDelete = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (!willDelete) {
        return; // User canceled the deletion
      }

      const response = await fetch(`https://api.myntask.com/vendor/category_delete/${categoryId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete category');
      }

      fetchCategories();
      // Show success alert
      Swal.fire({
        title: 'Deleted!',
        text: 'Category deleted successfully!',
        icon: 'success',
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
      });

    } catch (error) {
      console.error('Error deleting category:', error);

      // Show error alert
      Swal.fire({
        title: 'Error',
        text: 'Error deleting category. Please try again.',
        icon: 'error',
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <div className="permissions-container">
      <div className="top-controls">
        <h1 className="title">
          Vendor Category Setting
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button onClick={() => setModalOpen(true)} className="color-button">
            Add Vendor Category
          </button>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category.id}>
              <td>{category.id}</td>
              <td>{category.name}</td>
              <td>
                <span onClick={() => handleDeleteCategory(category.id)} style={{ cursor: "pointer", color: "red" }}>Delete</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Box className="designation-modal">
          {/* Header with title and close button */}
          <div className="designation-header">
            <h1 className="designation-title">Add New Category</h1>
            <img
              src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
              alt="Close"
              className="designation-close"
              onClick={() => setModalOpen(false)}
            />
          </div>

          {/* Form Section */}
          <div className="designation-form">
            <label className="designation-label" >
              Category Name
            </label>
            <input
              type="text"
              id="category-name"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              placeholder="Enter category name"
              className="designation-input"
            />
          </div>

          <button className="designation-save" onClick={handleCategorySave}>Save</button>

        </Box>
      </Modal>

    </div>
  );
};

export default VendorSetting;
