import React, { useEffect, useState } from 'react';
import './TaskInfo.css'; // Assuming we are using an external CSS file for styles

function TaskInfo({ userId }) {
  const [tasks, setTasks] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchTasks = async () => {
      const token = localStorage.getItem('authToken');
      const taskUrl = `https://api.myntask.com/task/list/${userId}`;

      console.log('Fetching Task URL:', taskUrl);

      if (!token) {
        console.error('No auth token found');
        setError('No token found');
        return;
      }

      try {
        const response = await fetch(taskUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Fetched tasks:', data);
        setTasks(data);
      } catch (err) {
        console.error('Error fetching tasks:', err);
        setError('Failed to fetch tasks. Please try again later.');
      }
    };

    fetchTasks();
  }, [userId]);

  return (
    <div className="task-details-container">
      <h2>Tasks List</h2>
      {error && <p className="error-text">{error}</p>}
      {tasks.length > 0 ? (
        <table >
          <thead>
            <tr>
              <th>Select</th>
              <th>Code</th>
              <th>Task Name</th>
              <th>Start Date</th>
              <th>Due Date</th>
              <th>Status</th>
              <th>Project Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task, index) => (
              <tr key={task.task_id}>
                <td><input type="checkbox" /></td>
                <td>{task.code || 'No Code'}</td>
                <td>{task.task || 'Untitled Task'}</td>
                <td>{task.start_date || 'N/A'}</td>
                <td style={{ color: '#d9534f' }}>{task.due_date || 'No Deadline'}</td>
                <td>{task.status || 'No Status'}</td>
                <td>{task.project_name || 'No Project'}</td>
                <td>
                  <button className="task-action">
                    <span>⋮</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : !error ? (
        <p>No tasks available.</p>
      ) : null}
    </div>
  );
}

export default TaskInfo;
