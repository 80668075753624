// src/screens/SuperAdminCompanies.js


import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, Card, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';


// Styles for company card
const CompanyCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative', // Add relative positioning
}));


const CompanyLogo = styled('img')({
  width: 40,
  height: 40,
  borderRadius: 4,
  cursor: 'pointer',
  marginRight: 16,
});


const StatusContainer = styled(Box)(({ theme, status }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  justifyContent: 'center',
  bottom: theme.spacing(1),
  right: theme.spacing(1),
  padding: theme.spacing(0.5, 1),
  borderRadius: '20px',
  backgroundColor: status === 'active' ? '#0cb378' : '#ff3939',
  width: '70px'
}));


const SuperAdminCompanies = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('authToken');
  const navigate = useNavigate();


  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch('http://api.myntask.com/company/list', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });


        if (!response.ok) {
          throw new Error('Network response was not ok');
        }


        const data = await response.json();
        setCompanies(data);
      } catch (error) {
        console.error('Error fetching companies:', error);
      } finally {
        setLoading(false);
      }
    };


    fetchCompanies();
  }, [token]);


  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }


  const handleRowClick = (id) => {
    navigate(`/CompanyDetail/${id}`);
  };


  const handleAddCompanyClick = () => {
    navigate('/AddCompanies');
  };


  return (
    <Box padding={3} position="relative">
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-start"
        position="absolute"
        top={16}
        right={16}
        width="calc(100% - 32px)"
        zIndex={1}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddCompanyClick}
        >
          Add Company
        </Button>
      </Box>


      <Box marginTop={6}>
        <Typography variant="h5" gutterBottom>
          Company List
        </Typography>
        {companies.length > 0 ? (
          companies.map((company) => (
            <CompanyCard key={company.id}>
              <CompanyLogo
                src={company.company_logo}
                alt="Company Logo"
                onClick={() => handleRowClick(company.id)}
              />
              <Box flexGrow={1}>
                <Typography
                  variant="body1"
                  onClick={() => handleRowClick(company.id)}
                  style={{ cursor: 'pointer', fontWeight: 'bold' }}
                >
                  {company.company_name}
                </Typography>
                <Typography variant="body2">{company.package}</Typography>
              </Box>
              <StatusContainer status={company.status.toLowerCase()}>
                <Typography variant="body2" style={{ color: '#fff' }}>
                  {company.status}
                </Typography>
              </StatusContainer>
            </CompanyCard>
          ))
        ) : (
          <Typography variant="body2">No companies found.</Typography>
        )}
      </Box>
    </Box>
  );
};


export default SuperAdminCompanies;






