import { GridMoreVertIcon } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import iconImage from '../assets/Icon.png';




function BusinessAddress() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form, setForm] = useState({
    country_id: '',
    address: '',
    tax_name: '',
    tax_number: '',
    location: '',
  });
  const [countryList, setCountryList] = useState([]);
  const [showActions, setShowActions] = useState(null); // Track which row's actions menu is open


  const fetchData = async () => {
    const token = localStorage.getItem('authToken'); // Retrieve the token
    if (!token) {
      setError('No token found. Please log in.');
      setLoading(false);
      return;
    }




    try {
      // Fetch business address data
      const addressResponse = await fetch('https://api.myntask.com/setting/business_address_list', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });




      if (!addressResponse.ok) {
        const errorDetails = await addressResponse.json();
        console.error('API Error Details:', errorDetails);
        throw new Error(`Failed to fetch data: ${addressResponse.statusText}`);
      }




      const addressData = await addressResponse.json();
      setData(addressData);




      // Fetch country list
      const countryResponse = await fetch('https://api.myntask.com/home/country_list', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });




      if (!countryResponse.ok) {
        const errorDetails = await countryResponse.json();
        console.error('Country API Error Details:', errorDetails);
        throw new Error(`Failed to fetch country list: ${countryResponse.statusText}`);
      }




      const countryData = await countryResponse.json();
      setCountryList(countryData);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };






  useEffect(() => {


    fetchData();
  }, []);




  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);




  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };




  const handleSave = async () => {
    const token = localStorage.getItem('authToken'); // Retrieve the token
    if (!token) {
      alert('No token found. Please log in.');
      return;
    }




    try {
      const response = await fetch('https://api.myntask.com/setting/business_address_save', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          country_id: parseInt(form.country_id),
          address: form.address,
          tax_name: form.tax_name,
          tax_number: form.tax_number,
          location: form.location,
        }),
      });




      if (!response.ok) {
        const errorDetails = await response.json();
        console.error('Error Saving Address:', errorDetails);
        Swal.fire('Error', 'Failed to save the business address.', 'error');
        return;
      }




      const newAddress = await response.json();
      setData([...data, newAddress]);
      setForm({
        country_id: '',
        address: '',
        tax_name: '',
        tax_number: '',
        location: '',
      });
      setIsModalOpen(false);
      fetchData();
      Swal.fire('Success', 'Business address added successfully!', 'success');
    } catch (err) {
      console.error('Error saving data:', err);
      Swal.fire('Error', 'Failed to save the business address.', 'error');
    }
  };




  const handleDelete = async (addressId) => {
    const token = localStorage.getItem('authToken'); // Retrieve the token
    if (!token) {
      Swal.fire('Error', 'No token found. Please log in.', 'error');
      return;
    }




    try {
      const response = await fetch(`https://api.myntask.com/setting/business_address_delete/${addressId}`, {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });




      if (!response.ok) {
        const errorDetails = await response.json();
        console.error('Error Deleting Address:', errorDetails);
        Swal.fire('Error', 'Failed to delete the business address.', 'error');
        return;
      }




      // Remove deleted address from the table
      setData(data.filter((item) => item.address_id !== addressId));
      fetchData();
      Swal.fire('Success', 'Business address deleted successfully!', 'success');
    } catch (err) {
      console.error('Error deleting data:', err);
      Swal.fire('Error', 'Failed to delete the business address.', 'error');
    }
  };




  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;




  return (
    <div className="permissions-container">


      <div className="top-controls">
        <h1 className='title'>Business Address
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className='color-button' onClick={handleOpenModal}>
            Add Business Address
          </button> </div>
      </div>


      <table >
        <thead>
          <tr>
            <th>ID</th>
            <th>Location</th>
            <th>Address</th>
            <th>Tax Name</th>
            <th>Country</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.address_id}>
              <td >{item.address_id}</td>
              <td >{item.location}</td>
              <td >{item.address}</td>
              <td >{item.tax_name}</td>
              <td >{item.country}</td>
              <td >
                <div className="action-container">
                  <div className="action-dots"
                    onClick={() => setShowActions(item.address_id === showActions ? null : item.address_id)}
                  >
                    <GridMoreVertIcon />
                  </div>
                  {showActions === item.address_id && (
                    <div className="action-menu">
                      <div className="action-item" onClick={() => handleDelete(item.address_id)}>
                        Delete
                      </div>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>




      {isModalOpen && (
        <div className="modal-overlay-deal">
          <div className="modal-content-deal">
            <img
              src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
              alt="Close"
              style={{
                position: "absolute",
                top: "16px",
                right: "16px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={handleCloseModal}
            />
            <h1 className="modal-title-deal">Add Business Address</h1>
            <div className="form-grid-deal" >
              {/* Country */}
              <div className="form-field-group-deal" style={{ display: 'flex', flexDirection: 'column' }}>
                <label className="form-label-deal">Country :</label>
                <select
                  className="form-input-deal"
                  id="country_id"
                  name="country_id"
                  value={form.country_id}
                  onChange={handleInputChange}
                >
                  <option value="">Select Country</option>
                  {countryList.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>


              {/* Tax Name */}
              <div className="form-field-group-deal" style={{ display: 'flex', flexDirection: 'column' }}>
                <label className="form-label-deal">Tax Name :</label>
                <input
                  className="form-input-deal"
                  id="tax_name"
                  name="tax_name"
                  placeholder="Tax Name"
                  value={form.tax_name}
                  onChange={handleInputChange}
                />
              </div>
              {/* Tax Number */}
              <div className="form-field-group-deal" style={{ display: 'flex', flexDirection: 'column' }}>
                <label className="form-label-deal">Tax Number :</label>
                <input
                  className="form-input-deal"
                  id="tax_number"
                  name="tax_number"
                  placeholder="Tax Number"
                  value={form.tax_number}
                  onChange={handleInputChange}
                />
              </div>
              {/* Location */}
              <div className="form-field-group-deal" style={{ display: 'flex', flexDirection: 'column' }}>
                <label className="form-label-deal" >Location :</label>
                <input
                  className="form-input-deal"
                  id="location"
                  name="location"
                  placeholder="Location"
                  value={form.location}
                  onChange={handleInputChange}
                />
              </div>
            </div>


            {/* Address */}
            <div className="form-field-group-deal" style={{ display: 'flex', flexDirection: 'column' }}>
              <label className="form-label-deal">Address :</label>
              <textarea
                className="form-input-deal"
                id="address"
                name="address"
                placeholder="Address"
                value={form.address}
                onChange={handleInputChange}
              />
            </div>


            {/* Buttons */}
            <div className="form-button-group-deal">
              <button className="submit-button-deal" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}


    </div>
  );
}


export default BusinessAddress;

