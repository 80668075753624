import React, { useEffect, useState } from 'react';
import {

  CircularProgress,
  Typography,
  Paper,
  Avatar,
  Box,
  Button,
  Modal,
  Fade,
  Backdrop,
  Stack,
  Grid,
} from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Vortex } from 'react-loader-spinner';


// Register the necessary ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend);


const AttendanceReport = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const fetchAttendanceReport = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No authentication token found.');
        setIsLoading(false);
        return;
      }


      try {
        const response = await fetch('https://api.myntask.com/report/attendance', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });


        if (!response.ok) {
          throw new Error(`Network response was not ok. Status: ${response.status}`);
        }


        const data = await response.json();
        setAttendanceData(data);
        setIsLoading(false);
      } catch (error) {
        setError('Failed to fetch attendance report. Please try again later.');
        setError("Failed to Fetch Attendance Report");
      } finally {
        setIsLoading(false);
      }
    };


    fetchAttendanceReport();
  }, []);


  const handleOpenModal = (employee) => {
    setSelectedEmployee(employee);
    setOpenModal(true);
  };


  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedEmployee(null);
  };


  const generateChartData = (employee) => {
    return {
      labels: ['Present Days', 'Absent Days', 'Half Days', 'Late Days'],
      datasets: [
        {
          label: 'Attendance Summary',
          data: [
            employee.total_present_days,
            employee.total_absent_days,
            employee.total_half_days,
            employee.total_late_days,
          ],
          backgroundColor: ['#4caf50', '#f44336', '#ff9800', '#2196f3'],
          hoverOffset: 4,
        },
      ],
    };
  };


  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }


  return (
    <div className='main-container'>
      <div className="top-controls">
        <h1 className='title'>Attendance Report
          <div className="underline"></div>
        </h1>
      </div>
      <table>
        <thead>
          <tr>
            <th >User</th>
            <th >Present Days</th>
            <th >Absent Days</th>
            <th >Half Days</th>
            <th >Late Days</th>
            <th >Total Time Spent</th>
            <th >Actions</th>
          </tr>
        </thead>
        <tbody>
          {attendanceData.map((employee) => (
            <tr key={employee.user_id}>
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={employee.user_image}
                    alt={employee.user_name}
                    className='image'
                  />
                  <div>
                    <span style={{ fontWeight: 'bold', cursor: 'pointer' }} >
                      {employee.user_name}
                    </span>
                    <p style={{ margin: 0, fontSize: '12px', color: '#777' }}>
                      {employee.designation}
                    </p>
                  </div>
                </div>
              </td>
              <td >{employee.total_present_days}</td>
              <td >{employee.total_absent_days}</td>
              <td >{employee.total_half_days}</td>
              <td >{employee.total_late_days}</td>
              <td >{employee.total_time_spent}</td>
              <td >
                <span onClick={() => handleOpenModal(employee)} style={{ fontWeight: '600' }}>View </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>



      {/* Modal for Viewing Employee Details */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90%', // Default responsive width
              maxWidth: 600, // Maximum width for larger screens
              bgcolor: 'background.paper',
              borderRadius: '8px',
              boxShadow: 24,
              p: 4,
              overflow: 'auto',
              '@media (max-width:600px)': {
                width: '75%', // Adjust width for very small screens
                maxWidth: 'none', // Remove maximum width restriction on very small screens
              },
            }}
          >
            {/* Close Button */}
            <Button
              onClick={handleCloseModal}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                minWidth: 'auto',
                padding: '4px',
              }}
            >
              X
            </Button>


            {selectedEmployee && (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Avatar
                      src={selectedEmployee.user_image}
                      alt={selectedEmployee.user_name}
                      sx={{
                        width: 150, // Avatar width
                        height: 150, // Avatar height
                        marginBottom: '16px', // Space between the image and text
                      }}
                    />
                    <Typography variant="h6" align="center">
                      {selectedEmployee.user_name}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" align="center">
                      {selectedEmployee.designation}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Pie
                    data={generateChartData(selectedEmployee)}
                    options={{
                      plugins: {
                        legend: {
                          position: 'bottom',
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};


export default AttendanceReport;








