import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';

const EditPayroll = ({ slipId, open, onClose }) => {
  const [slipData, setSlipData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]); // State to store payment methods
  const [formData, setFormData] = useState({
    slip_id: slipId,
    status: '',
    paid_on: '',
    payment_method_id: '',
  });

  useEffect(() => {
    if (open) {
      fetchSlipDetails();
      fetchPaymentMethods();
    }
  }, [open, slipId]);

  useEffect(() => {
    if (slipData) {
      setFormData({
        slip_id: slipId,
        status: slipData.status || 'unpaid',
        paid_on: slipData.paid_on ? new Date(slipData.paid_on).toISOString().substr(0, 10) : '',
        payment_method_id: slipData.salary_payment_method_id || '',
      });
    }
  }, [slipData, slipId]);
  const fetchSlipDetails = () => {
    setLoading(true);
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('Authorization token not found.');
      setLoading(false);
      return;
    }

    fetch(`https://api.myntask.com/payroll/slip_edit/${slipId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch payroll slip details');
        }
        return response.json();
      })
      .then((data) => {
        setSlipData(data[0]); // Assuming the data is in the first index of the array
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  // Fetch payment methods from the API
  const fetchPaymentMethods = () => {
    const token = localStorage.getItem('authToken');
    fetch('https://api.myntask.com/payroll/payment_method', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch payment methods');
        }
        return response.json();
      })
      .then((data) => setPaymentMethods(data))
      .catch((error) => setError(error.message));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('authToken');

    console.log('Submitting form data:', formData); // Debugging line to check values

    const response = await fetch('https://api.myntask.com/payroll/slip_update', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      alert('Data saved successfully');
    } else {
      alert('Failed to save data');
    }
  };
  const renderSlipDetails = () => {
    if (!slipData) return null;

    return (
      <div>
        <div style={styles.header}>
          <h3>Payslip for the duration {new Date(slipData.salary_from).toLocaleString('default', { month: 'long' })} {new Date(slipData.salary_to).getFullYear()}</h3>
        </div>
        <div style={styles.detailsContainer}>
          <div style={styles.row}>
            <div style={styles.col}>
              <p><span style={styles.label}>Full Name:</span> {slipData.user_name}</p>
            </div>
            <div style={styles.col}>
              <p><span style={styles.label}>Joining Date:</span> {new Date(slipData.joining_date).toLocaleDateString()}</p>
            </div>
          </div>
          <div style={styles.row}>
            <div style={styles.col}>
              <p><span style={styles.label}>Employee ID:</span> {slipData.employee_id}</p>
            </div>
            <div style={styles.col}>
              <p><span style={styles.label}>Salary Group:</span> -</p>
            </div>
          </div>
          <div style={styles.row}>
            <div style={styles.col}>
              <p><span style={styles.label}>Designation:</span> {slipData.designation}</p>
            </div>
          </div>
        </div>

        {/* Salary Details */}
        <div style={styles.detailsContainer}>
          <div style={styles.row}>
            <div style={styles.col}>
              <p><span style={styles.label}>Net Salary:</span> {slipData.net_salary}</p>
            </div>
            <div style={styles.col}>
              <p><span style={styles.label}>Gross Salary:</span> {slipData.gross_salary}</p>
            </div>
          </div>

          <div style={styles.row}>
            <div style={styles.col}>
              <p><span style={styles.label}>Salary From:</span> {new Date(slipData.salary_from).toLocaleDateString()}</p>
            </div>
            <div style={styles.col}>
              <p><span style={styles.label}>Salary To:</span> {new Date(slipData.salary_to).toLocaleDateString()}</p>
            </div>
          </div>
        </div>


        <div style={styles.detailsContainer}>
          <form onSubmit={handleSubmit}>
            <div style={styles.row}>
              <div style={styles.col}>
                <label>Paid On:</label>
                <input
                  type="date"
                  name="paid_on"
                  value={formData.paid_on}
                  onChange={handleInputChange}
                  className='input'
                />
              </div>
              <div style={styles.col}>
                <label>Salary Payment Method:</label>
                <select
                  name="payment_method_id"
                  value={formData.payment_method_id}
                  onChange={handleInputChange}
                  className='input'
                >
                  <option value="">-- Select Payment Method --</option>
                  {paymentMethods.map((method) => (
                    <option key={method.id} value={method.id}>
                      {method.payment_method}
                    </option>
                  ))}
                </select>
              </div>
              <div style={styles.col}>
                <label>Status:</label>
                <select
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  className='input'
                >
                  <option value="paid">Paid</option>
                  <option value="generated">Generated</option>
                  <option value="review">Review</option>
                  <option value="locked">Locked</option>
                </select>
              </div>
            </div>
            <button type="submit">Save</button>
          </form>

        </div>
      </div>
    );
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div style={styles.modalContent}>
        {loading ? (
          <div style={styles.loader}>
            <CircularProgress />
          </div>
        ) : error ? (
          <p style={{ color: 'red' }}>{error}</p>
        ) : (
          renderSlipDetails()
        )}
        <button style={styles.closeButton} onClick={onClose}>Close</button>
      </div>
    </Modal>
  );
};

export default EditPayroll;

// Inline styles
const styles = {
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    minWidth: '900px',
    minHeight: '600px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Arial, sans-serif',
  },
  header: {
    textAlign: 'left',
    marginBottom: '20px',
  },
  detailsContainer: {
    borderTop: '1px solid #ddd',
    borderBottom: '1px solid #ddd',
    paddingTop: '15px',
    paddingBottom: '15px',
    marginBottom: '20px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '10px',
  },
  col: {
    flex: '1',
    textAlign: 'left',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
  },
  closeButton: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  saveButton: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    marginRight: '1vw', // Adjust this value to control the gap between label and data
  },
};


