// src/screens/CompanyDetail.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, Card, CardContent, Divider } from '@mui/material';

const CompanyDetail = () => {
  const { id } = useParams(); // Retrieve the company ID from the URL parameters
  const [companyData, setCompanyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await fetch(`https://api.myntask.com/company/${id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('API Response:', data); // Log the response data

        setCompanyData(data); // Set both company_detail and statistics
      } catch (error) {
        console.error('Error fetching company data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyData();
  }, [id, token]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box padding={3}>
        <Typography variant="h6" color="error">
          Error fetching company data. Please try again later.
        </Typography>
      </Box>
    );
  }

  if (!companyData) {
    return (
      <Box padding={3}>
        <Typography variant="body1">No company data available.</Typography>
      </Box>
    );
  }

  // Destructure company_detail and statistics with default empty objects
  const { company_detail = {}, statistics = {} } = companyData;

  return (
    <Box padding={3}>
      <Typography variant="h4" gutterBottom>Company Detail</Typography>

      {/* Company Detail Card */}
      <Card variant="outlined" sx={{ padding: 2, boxShadow: 3, borderRadius: 2, mb: 3 }}>
        <CardContent>
          <Box display="flex" alignItems="center" mb={2}>
            <img
              src={company_detail.logo || 'default_logo.png'}
              alt="Company Logo"
              style={{ width: 100, height: 100, borderRadius: '4px', marginRight: 16 }}
            />
            <Box>
              <Typography variant="h6">{company_detail.company_name || 'N/A'}</Typography>
              <Typography variant="body2">Email: {company_detail.company_email || 'N/A'}</Typography>
              <Typography variant="body2">Phone: {company_detail.company_phone || 'N/A'}</Typography>
              <Typography variant="body2">Address: {company_detail.company_address || 'N/A'}</Typography>
              <Typography variant="body2">Website: {company_detail.company_website ? <a href={company_detail.company_website} target="_blank" rel="noopener noreferrer">{company_detail.company_website}</a> : 'N/A'}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>

      {/* Additional Information Card */}
      <Card variant="outlined" sx={{ padding: 2, boxShadow: 3, borderRadius: 2, mb: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>Additional Information</Typography>
          <Typography variant="body2">Package: {company_detail.package_name || 'N/A'}</Typography>
          <Typography variant="body2">Package Type: {company_detail.package_type || 'N/A'}</Typography>
          <Typography variant="body2">Status: {company_detail.status || 'N/A'}</Typography>
          <Typography variant="body2">Default Currency: {company_detail.default_currency || 'N/A'}</Typography>
          <Typography variant="body2">Default Timezone: {company_detail.default_timezone || 'N/A'}</Typography>
          <Typography variant="body2">Created On: {company_detail.created ? new Date(company_detail.created).toLocaleDateString() : 'N/A'}</Typography>
          <Typography variant="body2">Last Login: {company_detail.last_login ? new Date(company_detail.last_login).toLocaleDateString() : 'N/A'}</Typography>
          <Typography variant="body2">License Expires On: {company_detail.licence_expires_On ? new Date(company_detail.licence_expires_On).toLocaleDateString() : 'N/A'}</Typography>
        </CardContent>
      </Card>

      {/* User Information Card */}
      <Card variant="outlined" sx={{ padding: 2, boxShadow: 3, borderRadius: 2, mb: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>User Information</Typography>
          <Box display="flex" alignItems="center">
            <img
              src={company_detail.user_image || 'default_user_image.png'}
              alt="User Avatar"
              style={{ width: 50, height: 50, borderRadius: '50%', marginRight: 16 }}
            />
            <Box>
              <Typography variant="body2">User Name: {company_detail.user_name || 'N/A'}</Typography>
              <Typography variant="body2">Last Login: {company_detail.user_last_login ? new Date(company_detail.user_last_login).toLocaleDateString() : 'N/A'}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>

      {/* Statistics Card */}
      <Card variant="outlined" sx={{ padding: 2, boxShadow: 3, borderRadius: 2 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>Statistics</Typography>
          <Typography variant="body2">Clients: {statistics.clients || 0}</Typography>
          <Typography variant="body2">Contracts: {statistics.contracts || 0}</Typography>
          <Typography variant="body2">Employees: {statistics.employees || 0}</Typography>
          <Typography variant="body2">Estimates: {statistics.estimates || 0}</Typography>
          <Typography variant="body2">Invoices: {statistics.invoices || 0}</Typography>
          <Typography variant="body2">Leads: {statistics.leads || 0}</Typography>
          <Typography variant="body2">Orders: {statistics.orders || 0}</Typography>
          <Typography variant="body2">Projects: {statistics.projects || 0}</Typography>
          <Typography variant="body2">Storage: {statistics.storage || 0}</Typography>
          <Typography variant="body2">Tasks: {statistics.tasks || 0}</Typography>
          <Typography variant="body2">Tickets: {statistics.tickets || 0}</Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CompanyDetail;
