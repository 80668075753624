import React, { useState, useEffect } from 'react';
// import './components/EstimateForm.css';
import './../Components/EstimateForm.css';
import Swal from 'sweetalert2';
import { Box, Modal, Stack, Typography } from "@mui/material";
import { Vortex } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

const AddOrder = () => {
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState(null);
  const [orderDate, setOrderDate] = useState(null);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState('');
  const [unitTypes, setUnitTypes] = useState([]);
  const [taxes, setTaxes] = useState([]); // State for taxes

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [rows, setRows] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [productDetails, setProductDetails] = useState(null);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [estimateNumber, setEstimateNumber] = useState('');


  const token = localStorage.getItem('authToken');
  const navigate = useNavigate();


  useEffect(() => {
    const token = localStorage.getItem('authToken');

    const fetchVendors = async () => {
      try {
        const response = await fetch('https://api.myntask.com/purchase_order/vendor_company', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (Array.isArray(data)) {
          setVendors(data);
        } else {
          console.error('API response is not an array:', data);
          setVendors([]); // Fallback
        }
      } catch (error) {
        console.error('Error fetching vendors:', error);
        // setError('Failed to fetch vendors. Please try again later.');
      } finally {
        // setIsLoading(false);
      }
    };

    fetchVendors();
  }, []);

  useEffect(() => {

    fetch('https://api.myntask.com/purchase_order/shipping_address', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setShippingAddresses(data);
      })
      .catch(error => console.error('Error fetching shipping addresses:', error));


  }, [token]);



  useEffect(() => {
    const fetchCategories = async () => {
      console.log('Fetching categories...');
      try {
        const response = await fetch('https://api.myntask.com/product/category', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        console.log('Categories fetched:', data);
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, [token]);

  useEffect(() => {
    const fetchSubcategories = async () => {
      console.log('Fetching subcategories for category:', selectedCategory);
      if (selectedCategory) {
        try {
          const response = await fetch(
            `https://api.myntask.com/product/sub_category/${selectedCategory}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          const data = await response.json();
          console.log('Subcategories fetched:', data);
          setSubcategories(data);
        } catch (error) {
          console.error('Error fetching subcategories:', error);
        }
      }
    };
    fetchSubcategories();
  }, [selectedCategory, token]);

  useEffect(() => {
    const fetchProducts = async () => {
      console.log('Fetching products for category and subcategory:', selectedCategory, selectedSubcategory);
      if (selectedCategory && selectedSubcategory) {
        try {
          const response = await fetch(
            `https://api.myntask.com/product/product_dropdown?category_id=${selectedCategory}&subcategory_id=${selectedSubcategory}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          const data = await response.json();
          console.log('Products fetched:', data);
          setProducts(data);
        } catch (error) {
          console.error('Error fetching products:', error);
        }
      }
    };
    fetchProducts();
  }, [selectedCategory, selectedSubcategory, token]);



  const fetchProductDetails = async (productId) => {
    console.log('Fetching product details for product ID:', productId);
    setLoadingProduct(true);
    try {
      const response = await fetch(
        `https://api.myntask.com/purchase_order/product_dropdown_info/${productId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = await response.json();
      console.log('Product details fetched:', data);

      if (data && data.length > 0) {
        setProductDetails(data[0]);
      } else {
        console.error('No product data available');
      }
    } catch (error) {
      console.error('Error fetching product details:', error);
    } finally {
      setLoadingProduct(false); // Stop loading
    }
  };


  // Fetch unit types
  useEffect(() => {
    const fetchUnitTypes = async () => {
      try {
        const response = await fetch('https://api.myntask.com/product/unit_types', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        setUnitTypes(data);
      } catch (error) {
        console.error('Error fetching unit types:', error);
      }
    };
    fetchUnitTypes();
  }, [token]);


  // Fetch tax data
  useEffect(() => {
    const fetchTaxes = async () => {
      try {
        const response = await fetch('https://api.myntask.com/product/taxes', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        setTaxes(data);
      } catch (error) {
        console.error('Error fetching taxes:', error);
      }
    };
    fetchTaxes();
  }, [token]);


  const handleProductSelect = (productId) => {
    console.log('Product selected:', productId);
    setSelectedProduct(productId);
    fetchProductDetails(productId);
  };

  const addProductRow = () => {
    console.log('Adding product row with details:', productDetails);
    if (!productDetails) {
      alert('Please select a product.');
      return;
    }

    const unitPrice = parseFloat(productDetails.purchase_price) || 0;
    const qty = 1;
    // const taxPercentage = productDetails.taxes && productDetails.taxes.length > 0
    //   ? parseFloat(productDetails.taxes[0].split('@')[1])
    //   : 0;

    const newRow = {
      id: rows.length + 1,
      product: productDetails.name,
      description: productDetails.description || '',
      qty,
      unitPrice,
      discount: 0,
      tax: "",
      taxAmount: 0,
      totalAmount: unitPrice * qty,
      hsn_code: productDetails.hsn_sac_code, // Add hsn_sac_code here
      unitTypeId: '',
    };
    setRows([...rows, newRow]);
    calculateSummary([...rows, newRow]);
    setSelectedProduct('');
    setProductDetails(null);
  };

  const addEmptyRow = () => {
    console.log('Adding empty row...');
    const newRow = {
      id: rows.length + 1,
      product: '',
      description: '',
      qty: 1,
      unitPrice: 0.0,
      discount: 0,
      tax: 0,
      taxAmount: 0,
      totalAmount: 0,
    };
    setRows([...rows, newRow]);
  };

  const deleteRow = (id) => {
    console.log('Deleting row with ID:', id);
    const updatedRows = rows.filter((row) => row.id !== id).map((row, index) => ({
      ...row,
      id: index + 1,
    }));
    setRows(updatedRows);
    // Recalculate the summary
    calculateSummary(updatedRows);
  };

  // Handle Input Changes
  const handleRowChange = (id, field, value) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        // Safely parse the input fields and assign defaults
        const qty = field === "qty" ? parseFloat(value) || 0 : parseFloat(row.qty) || 0;
        const unitPrice = field === "unitPrice" ? parseFloat(value) || 0 : parseFloat(row.unitPrice) || 0;
        const discount = field === "discount" ? parseFloat(value) || 0 : parseFloat(row.discount) || 0;
        // const tax = parseFloat(row.tax) || 0; // Default to 0 if not a number
        const taxId = field === "tax" ? value : row.tax;
        // Find the selected tax object to get the tax percentage
        const selectedTax = taxes.find(tax => tax.id === parseInt(taxId));
        const taxPercentage = selectedTax ? parseFloat(selectedTax.tax_name.split('@')[1]) : 0;

        // Calculate the discounted price per item
        const discountedPrice = unitPrice * (1 - discount / 100);

        // Calculate the tax amount
        const taxAmount = (taxPercentage / 100) * (discountedPrice * qty);

        // Calculate the total amount
        const totalAmount = discountedPrice * qty + taxAmount;

        return {
          ...row,
          [field]: value, // Update the specific field
          taxAmount: Number.isFinite(taxAmount) ? taxAmount : 0, // Ensure taxAmount is a number
          totalAmount: Number.isFinite(totalAmount) ? totalAmount : 0, // Ensure totalAmount is a number
        };
      }
      return row; // No changes for other rows
    });

    setRows(updatedRows);
    calculateSummary(updatedRows); // Update summary totals
  };



  const calculateSummary = (rowsData) => {
    console.log('Calculating summary for rows:', rowsData);
    const total = rowsData.reduce((sum, row) => sum + row.totalAmount, 0);
    const tax = rowsData.reduce((sum, row) => sum + row.taxAmount, 0);
    setTotalAmount(total);
    setTotalTax(tax);
  };

  const handleSave = async () => {

    // Sample data to send; replace these variables with your actual state values.

    const items = rows.map((row) => ({
      item_name: row.product || '',
      quantity: parseFloat(row.qty) || 0,
      product_id: selectedProduct || null,
      hsn_sac_code: row.hsn_code || '',
      unit_price: parseFloat(row.unitPrice) || 0,
      unit_id: row.unitTypeId || null,
      item_summary: row.description || '',
      amount: parseFloat(row.totalAmount) || 0,
      tax_id: row.tax,
    }));

    const dataToSend = {
      vendor_company_id: selectedVendor, // Replace with your dynamic value
      purchase_date: orderDate ? orderDate.toISOString().split('T')[0] : '',
      expected_delivery_date: expectedDeliveryDate ? expectedDeliveryDate.toISOString().split('T')[0] : '',
      address_id: parseInt(selectedShippingAddress),
      calculate_tax: "after_discount", // Replace with your dynamic value
      delivery_status: "not_started", // Replace with your dynamic value
      note: "asdf", // Replace with your dynamic value
      sub_total: rows.reduce((sum, row) => sum + parseFloat(row.unitPrice) * parseFloat(row.qty), 0),
      total: totalAmount,
      discount: rows.reduce((dis, row) => parseFloat(row.discount), 0), // Replace with your dynamic value
      discount_type: "percent", // Replace with your dynamic value
      items,
    };


    // Send the POST request using fetch
    console.log('Purchase Order Data:', dataToSend); // Debug before sending

    fetch('https://api.myntask.com/purchase_order/save', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataToSend)
    })
      .then(response => response.json())
      .then(data => {
        // Display SweetAlert toast notification on success
        Swal.fire({
          icon: 'success',
          title: 'Purchase order saved successfully!',
          toast: true,
          position: 'top-right',
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
          // Navigate to purchase-order page after the toast disappears
          navigate('/purchase-order');
        });
        console.log('API Response:', data);
      })
      .catch(error => {
        // Display SweetAlert toast notification on error
        Swal.fire({
          icon: 'error',
          title: 'Failed to save purchase order!',
          toast: true,
          position: 'top-right',
          showConfirmButton: false,
          timer: 3000
        });
        console.error('Error saving order:', error);
      });
  };


  const resetForm = () => {
    console.log('Resetting form...');
    setSelectedClient('');
    setSelectedCategory('');
    setSelectedSubcategory('');
    setSelectedProduct('');
    setRows([]);
    setTotalAmount(0);
    setTotalTax(0);
    setProductDetails(null);
  };

  return (
    <div className="add-order-screen">
      <h2> Add Purchase Order</h2>
      <form>
        {/* <div className="form-container"> */}
        <div className="form-row">
          {/* Vendor Dropdown */}
          <div className='formgroup'>
            <label>Vendor</label>
            <select
              className='expenseInput'
              value={selectedVendor}
              onChange={(e) => setSelectedVendor(e.target.value)}
              style={{ width: '100%' }}
            >
              <option value="">Select Vendor</option>
              {vendors.map((vendor) => (
                <option key={vendor.id} value={vendor.id}>
                  {vendor.company_name}
                </option>
              ))}
            </select>
          </div>

          {/* Shipping Address Dropdown */}
          <div className="formgroup">
            <label>Shipping Address</label>
            {/* Dropdown for Shipping Address */}
            <select
              className='expenseInput'
              value={selectedShippingAddress}
              onChange={(e) => setSelectedShippingAddress(e.target.value)}

            >
              <option value="">Select Address</option>
              {shippingAddresses.length > 0 ? (
                shippingAddresses.map((address) => (
                  <option key={address.id} value={address.id}>
                    {address.address}
                  </option>
                ))
              ) : (
                <option value="">No addresses available</option>
              )}
            </select>
          </div>

        </div>

        <div className="form-row">
          <div className="formgroup">
            <label>Order Date</label>
            <input
              type="date"
              id='orderDate'
              value={orderDate ? orderDate.toISOString().split('T')[0] : ""}
              onChange={(e) => setOrderDate(new Date(e.target.value))}
              className='expenseInput'
            // placeholder="Order Date"
            />
          </div>
          {/* Expected Delivery Date */}
          <div className="formgroup">
            <label>Delivery Date</label>
            <input
              type="date"
              id='expectedDeliveryDate'
              value={expectedDeliveryDate ? expectedDeliveryDate.toISOString().split('T')[0] : ""}
              onChange={(e) => setExpectedDeliveryDate(new Date(e.target.value))}
              className='expenseInput'
            // placeholder="Expected Delivery Date"
            />
          </div>
        </div>


        <div className="form-row">
          <div className="formgroup">
            <label>Category:</label>
            <select className='expenseInput' onChange={(e) => setSelectedCategory(e.target.value)} value={selectedCategory}>
              <option value="">Select Category</option>
              {categories.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.category_name}
                </option>
              ))}
            </select>
          </div>
          <div className="formgroup">
            <label>Subcategory:</label>
            <select className='expenseInput' onChange={(e) => setSelectedSubcategory(e.target.value)} value={selectedSubcategory}>
              <option value="">Select Subcategory</option>
              {subcategories.map((sub) => (
                <option key={sub.id} value={sub.id}>
                  {sub.category_name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="formgroup">
            <label>Product:</label>
            <select className='expenseInput' onChange={(e) => handleProductSelect(e.target.value)} value={selectedProduct}>
              <option value="">Select Product</option>
              {products.map((prod) => (
                <option key={prod.id} value={prod.id}>
                  {prod.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* </div> */}
        <div className="est-actions">
          <button
            className='color-button'
            type="button"
            onClick={addProductRow}
            disabled={!productDetails || loadingProduct} // Disable button during loading
          >
            {loadingProduct ? "Loading..." : "Add Product"}
          </button>
          <button type="button" className='color-button' onClick={addEmptyRow}>
            + Add Empty Row
          </button>
        </div>
      </form>

      <table className='estimate-table'>
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Item Name</th>
            <th>Description</th>
            <th>Qty</th>
            <th>Unit Type</th>
            <th>Unit Price</th>
            <th>Discount (%)</th>
            <th>Tax (%)</th>
            {/* <th>Tax Amount</th> */}
            <th>Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className='estimate-body'>
          {rows.map((row) => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td contentEditable={row.product === ""}>{row.product}</td>
              <td>
                <textarea
                  className='estimateInput'
                  rows="1"
                  value={row.description}
                  onChange={(e) => handleRowChange(row.id, "description", e.target.value)}
                />
              </td>
              <td>
                <input
                  className='estimateInput'
                  type="number"
                  value={row.qty}
                  onChange={(e) => handleRowChange(row.id, "qty", parseFloat(e.target.value))}
                />
              </td>
              <td>
                <select
                  className='estimateInput'
                  value={row.unitTypeId}
                  onChange={(e) => handleRowChange(row.id, "unitTypeId", e.target.value)}
                >
                  <option value="">Select Unit Type</option>
                  {unitTypes.map((unit) => (
                    <option key={unit.id} value={unit.id}>
                      {unit.unit_type}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input
                  className='estimateInput'
                  type="number"
                  value={row.unitPrice}
                  onChange={(e) => handleRowChange(row.id, "unitPrice", parseFloat(e.target.value))}
                />
              </td>
              <td>
                <input
                  className='estimateInput'
                  type="number"
                  value={row.discount}
                  onChange={(e) => handleRowChange(row.id, "discount", parseFloat(e.target.value))}
                />
              </td>
              <td>
                <select
                  className='estimateInput'
                  value={row.tax}
                  onChange={(e) => handleRowChange(row.id, "tax", e.target.value)}
                >
                  <option value="">Select Tax</option>
                  {taxes.map((tax) => (
                    <option key={tax.id} value={tax.id}>
                      {tax.tax_name}
                    </option>
                  ))}
                </select>
              </td>
              {/* <td>{row.taxAmount.toFixed(2)}</td> */}
              <td>{row.totalAmount.toFixed(2)}</td>
              <td>
                <button onClick={() => deleteRow(row.id)}>➖</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="estimate-summary">
        <div>
          <label>Total Tax :</label> {totalTax.toFixed(2)}
        </div>
        <div>
          <label>Total Amount :</label> {totalAmount.toFixed(2)}
        </div>
      </div>


      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '40px', gap: '20px' }}>
        <button type="button" className='color-button' onClick={resetForm}>
          Cancel
        </button>
        <button type="button" className='color-button' onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default AddOrder;
