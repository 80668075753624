import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';
import Swal from 'sweetalert2';




const DailyUpdateList = () => {
  const [dailyReports, setDailyReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);




  const fetchDailyReports = async (date) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error('No auth token found.');
      return;
    }




    try {
      let apiUrl = 'https://api.myntask.com/home/daily_report_list';
      if (date) {
        apiUrl += `?&date=${date}`;
      }




      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });




      if (!response.ok) {
        throw new Error('Network response was not ok');
      }




      const data = await response.json();
      setDailyReports(data);
      setFilteredReports(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching daily reports:', error);
      toast.error('Failed to fetch daily reports. Please try again.');
      setError("Failed to fetch daily reports. Please try again.");
      setIsLoading(false);
    }
  };


  useEffect(() => {

    fetchDailyReports(selectedDate);
  }, [selectedDate]);




  const handleDeleteReport = async (reportId) => {
    const token = localStorage.getItem('authToken');
    try {
      // Ask for confirmation using SweetAlert
      const willDelete = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (!willDelete) {
        return; // User canceled the deletion
      }
      const response = await fetch(`https://api.myntask.com/home/daily_report_delete/${reportId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to delete category');
      }

      fetchDailyReports();
      // Show success alert
      Swal.fire({
        title: 'Deleted!',
        text: 'report deleted successfully!',
        icon: 'success',
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
      });

    } catch (error) {
      console.error('Error deleting report:', error);

      // Show error alert
      Swal.fire({
        title: 'Error',
        text: 'Error deleting report. Please try again.',
        icon: 'error',
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };


  const handleOpenModal = (report) => {
    setModalContent(report);
    setOpenModal(true);
  };




  const handleCloseModal = () => {
    setOpenModal(false);
    setModalContent({});
  };




  const handleExport = () => {
    const worksheetData = filteredReports.map((report) => ({
      'Team Member': report.user_name,
      Project: report.project_name,
      Summary: report.summary,
      Challenges: report.challenges,
      Mood: report.mood,
      Date: new Date(report.created_at).toLocaleString(),
    }));




    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Daily Reports');
    XLSX.writeFile(workbook, 'Daily_Updates_List.xlsx');
  };




  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }




  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className="title">
          Daily Update List
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={handleExport}>
            Export to Excel
          </button>
        </div>
      </div>




      {/* Filters */}
      <div className="top-controls">
        <div className="filters-and-search">
          <input
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            className="border-button"
            placeholder="Select Date"
          />
        </div>
      </div>




      <table>
        <thead>
          <tr>
            <th>Team Member</th>
            <th>Project</th>
            <th>Summary</th>
            <th>Challenges</th>
            <th>Mood</th>
            <th>Date</th>
            <th>Location</th>
            <th>Report Files</th>
          </tr>
        </thead>
        <tbody>
          {filteredReports.length > 0 ? (
            filteredReports.map((report, index) => (
              <tr key={index}>
                <td>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                      src={report.image}
                      alt={report.user_name}
                      className="image"
                    />
                    {report.user_name}
                  </div>
                </td>
                <td>{report.project_name}</td>
                <td
                  style={{ cursor: 'pointer', color: '#1976d2' }}
                  onClick={() => handleOpenModal(report)}
                >
                  {report.summary}
                </td>
                <td
                  style={{ cursor: 'pointer', color: '#1976d2' }}
                  onClick={() => handleOpenModal(report)}
                >
                  {report.challenges}
                </td>
                <td
                  style={{
                    color:
                      report.mood === 'happy' ? '#4caf50' : report.mood === 'neutral' ? '#ff9800' : '#f44336',
                    fontWeight: 'bold',
                  }}
                >
                  {report.mood}
                </td>
                <td>{new Date(report.created_at).toLocaleString()}</td>
                <td>
                  <a
                    href={report.location}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'underline', color: '#1976d2' }}
                  >
                    View Location
                  </a>
                </td>
                <td>
                  {report.report_files && report.report_files.length > 0 ? (
                    <a
                      href={report.report_files[0]}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'underline', color: '#1976d2' }}
                    >
                      View Files
                    </a>
                  ) : (
                    <span>No Data</span>
                  )}
                </td>
                <td>
                  <span onClick={() => handleDeleteReport(report.id)} style={{ cursor: "pointer", color: "red" }}>Delete</span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8} style={{ textAlign: 'center' }}>
                No data available.
              </td>
            </tr>
          )}
        </tbody>
      </table>




      {/* Modal to display detailed content */}
      {openModal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '24px',
              borderRadius: '8px',
              width: '100%',
              maxWidth: '600px',
              textAlign: 'left',
            }}
          >
            <h2 style={{ color: '#1976d2', marginBottom: '16px' }}>Daily Update Detail View</h2>
            <p><strong>Employee Name:</strong> {modalContent.user_name}</p>
            <p><strong>Date of Update:</strong> {new Date(modalContent.created_at).toLocaleDateString()}</p>
            <p><strong>Mood:</strong> {modalContent.mood}</p>




            <h3 style={{ marginTop: '16px' }}>Project Updates</h3>
            <p><strong>Project:</strong> {modalContent.project_name}</p>
            <p><strong>Today's Work Update:</strong></p>
            <p>{modalContent.summary}</p>




            <p><strong>Plan for Tomorrow:</strong></p>
            <p>{modalContent.plan_for_tomorrow}</p>




            <h3 style={{ marginTop: '16px' }}>Comments or Issues</h3>
            <p>{modalContent.challenges || 'No significant issues today. Everything is on track.'}</p>




            <h3 style={{ marginTop: '16px' }}>Attached Files</h3>
            {modalContent.report_files && modalContent.report_files.length > 0 ? (
              modalContent.report_files.map((file, index) => (
                <div key={index}>
                  <a
                    href={file}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'underline', color: '#1976d2' }}
                  >
                    {`File ${index + 1}`}
                  </a>
                </div>
              ))
            ) : (
              <p>No files attached.</p>
            )}




            <button
              onClick={handleCloseModal}
              style={{
                marginTop: '24px',
                padding: '8px 16px',
                backgroundColor: '#3f51b5',
                color: '#fff',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
                fontWeight: 'bold',
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};




export default DailyUpdateList;













