import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AnalyticsIcon from "@mui/icons-material/Insights";
import EditIcon from "@mui/icons-material/Edit";
import MapIcon from "@mui/icons-material/Map";
import AttachFileIcon from "@mui/icons-material/AttachFile";


const ClientDetail = () => {
  const { id } = useParams();
  const [clientDetails, setClientDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);


  const fetchClientDetails = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      setError("No authentication token found. Please log in again.");
      setLoading(false);
      return;
    }


    try {
      const response = await fetch(`https://api.myntask.com/client/profile/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });


      if (!response.ok) {
        throw new Error("Failed to fetch client details");
      }


      const data = await response.json();
      console.log("Client Data:", data);


      setClientDetails(data); // Set the full API response
    } catch (error) {
      console.error("Error fetching client details:", error.message);
      setError("Unable to fetch client details. Please try again later.");
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchClientDetails();
  }, [id]);


  // if (error) {
  //   return <p>Error: {error}</p>;
  // }


  if (loading) {
    return <p>Loading client details...</p>;
  }


  const { profile, invoice, due_invoices, project_by_status, total_earnings, total_project } =
    clientDetails;


  const profileDetails = profile && profile.length > 0 ? profile[0] : {};


  const getStatusChipColor = (status) => {
    switch (status?.toLowerCase()) {
      case "pending":
        return "orange";
      case "done":
        return "green";
      case "draft":
        return "gray";
      case "failed":
        return "red";
      default:
        return "blue";
    }
  };


  return (
    <Grid container spacing={3} style={{ padding: "20px" }}>
      {/* Left Column: Client Details */}
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Avatar
                  src={profileDetails.image || "https://via.placeholder.com/150"}
                  alt={profileDetails.user_name || "N/A"}
                  sx={{ width: 80, height: 80 }}
                />
              </Grid>
              <Grid item>
                <Typography variant="h5">{profileDetails.user_name || "N/A"}</Typography>
                <Typography variant="body2" color="textSecondary">
                  @{profileDetails.user_name?.toLowerCase() || "username"}
                </Typography>
                <Typography variant="body2">
                  Email: {profileDetails.email || "N/A"}
                </Typography>
                <Typography variant="body2">
                  Phone: {profileDetails.mobile || "N/A"}
                </Typography>
              </Grid>
            </Grid>
            <Button
              startIcon={<SendIcon />}
              variant="contained"
              color="primary"
              sx={{ mt: 2, mr: 1 }}
            >
              Send message
            </Button>

            <Typography variant="h6" sx={{ mt: 3 }}>
              Notes
            </Typography>
            <Typography variant="body2">
              We discussed about startups last time. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit.
            </Typography>
            <Typography variant="h6" sx={{ mt: 3 }}>
              Address
            </Typography>
            <Typography variant="body2">
              {profileDetails.address || "Address not available"}
            </Typography>
            <Typography variant="body2">
              City: {profileDetails.city || "N/A"}
            </Typography>
            <Typography variant="body2">
              State: {profileDetails.state || "N/A"}
            </Typography>
            <Typography variant="body2">
              Postal Code: {profileDetails.postal_code || "N/A"}
            </Typography>
            <Typography variant="body2">
              Website: {profileDetails.website || "N/A"}
            </Typography>
            <Typography variant="body2">
              Added By: {profileDetails.added_by_user || "N/A"}
            </Typography>
            {/* <Button startIcon={<MapIcon />} variant="outlined" sx={{ mt: 2, mr: 1 }}>
              View map
            </Button>
            <Button startIcon={<AttachFileIcon />} variant="outlined" sx={{ mt: 2 }}>
              Add file      
            </Button> */}
          </CardContent>
        </Card>
      </Grid>


      {/* Right Column: Invoice & Financial Information */}
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h6">Invoices</Typography>
            <Typography variant="h4">{invoice || 0} Invoices total</Typography>
            <Typography variant="h5" sx={{ color: "green", mt: 1 }}>
              {due_invoices || 0} Due invoices
            </Typography>
            <div style={{ maxHeight: "400px", overflowY: "auto", marginTop: "20px" }}>
              {clientDetails.invoices?.map((inv, index) => (
                <Grid
                  container
                  key={index}
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    padding: "10px",
                    borderBottom: "1px solid #eee",
                  }}
                >
                  <Grid item>
                    <Typography>
                      <strong>Invoice #{inv.number}</strong>
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(inv.date).toDateString()}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <span
                      style={{
                        backgroundColor: getStatusChipColor(inv.status),
                        color: "white",
                        borderRadius: "12px",
                        padding: "5px 10px",
                      }}
                    >
                      {inv.status}
                    </span>
                  </Grid>
                </Grid>
              ))}
            </div>


            <Typography variant="h6" sx={{ mt: 3 }}>
              Financial Information
            </Typography>
            <Typography variant="body2">
              Total Earnings: ${total_earnings || 0}
            </Typography>
            <Typography variant="body2">
              Total Projects: {total_project || 0}
            </Typography>


            <Typography variant="h6" sx={{ mt: 3 }}>
              Project Information
            </Typography>
            {project_by_status && (
              <ul>
                <li>Not Started: {project_by_status["not started"] || 0}</li>
                <li>In Progress: {project_by_status["in progress"] || 0}</li>
                <li>On Hold: {project_by_status["on hold"] || 0}</li>
                <li>Finished: {project_by_status.finished || 0}</li>
                <li>Canceled: {project_by_status.canceled || 0}</li>
              </ul>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};


export default ClientDetail;






