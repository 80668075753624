import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ViewExpense.css'; // Assuming you want to manage the styling in a separate CSS file
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';


const ViewExpense = () => {
  const { expenseId } = useParams(); // Get the expenseId from the URL
  const [expenseDetails, setExpenseDetails] = useState(null);
  const navigate = useNavigate(); // For navigation
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (expenseId) {
      const fetchExpenseDetails = async () => {
        const token = localStorage.getItem('authToken');
        if (!token) {
          return;
        }


        try {
          const response = await fetch(`https://api.myntask.com/expenses/view/${expenseId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });


          if (!response.ok) {
            throw new Error('Failed to fetch expense details');
          }


          const data = await response.json();
          setExpenseDetails(data);
        } catch (err) {
          console.error('Error fetching expense details:', err);
          setError('Failed to fetch expense details');
        }
      };


      fetchExpenseDetails();
    }
  }, [expenseId]);

  useEffect(() => {
    // Adjust the timer duration here (e.g., 3000ms = 3 seconds)
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  return (
    <div className="expense-container">
      <h2 className="expense-title">Expense Details</h2>
      {expenseDetails ? (
        <div className="expense-details-grid">
          <div className="expense-detail">
            <strong>Employee Image:</strong>
            <img
              src={expenseDetails.employee_image}
              alt={expenseDetails.employee_name}
              className="expense-avatar"
            />
          </div>
          <div className="expense-detail"><strong>Item Name:</strong><div>{expenseDetails.item_name}</div></div>
          <div className="expense-detail"><strong>Price:</strong><div>{expenseDetails.price}</div></div>


          <div className="expense-detail"><strong>Purchase From:</strong><div>{expenseDetails.purchase_from}</div></div>
          <div className="expense-detail"><strong>Purchase Date:</strong><div>{expenseDetails.purchase_date}</div></div>
          <div className="expense-detail"><strong>Project:</strong><div>{expenseDetails.project_name}</div></div>


          <div className="expense-detail"><strong>Category:</strong><div>{expenseDetails.category_name}</div></div>
          <div className="expense-detail"><strong>Employee Name:</strong><div>{expenseDetails.employee_name}</div></div>
          <div className="expense-detail"><strong>Employee Designation:</strong><div>{expenseDetails.employee_designation}</div></div>


          <div className="expense-detail"><strong>Approved By:</strong><div>{expenseDetails.approved_user_name}</div></div>
          <div className="expense-detail"><strong>Approved User Designation:</strong><div>{expenseDetails.approved_user_designation}</div></div>
          <div className="expense-detail"><strong>Description:</strong><div>{expenseDetails.description}</div></div>


          <div className="expense-detail"><strong>Bill:</strong><div><a href={expenseDetails.bill} target="_blank" rel="noopener noreferrer">View Bill</a></div></div>
          <div className="expense-detail"><strong>Approved User Image:</strong>
            <img
              src={expenseDetails.approved_user_image}
              alt={expenseDetails.approved_user_name}
              className="expense-avatar"
            />
          </div>


          {/* New Fields */}
          <div className="expense-detail"><strong>Paid Amount:</strong><div>{expenseDetails.paid_amount}</div></div>
          <div className="expense-detail"><strong>Transaction No.:</strong><div>{expenseDetails.transaction_no || "N/A"}</div></div>
          <div className="expense-detail"><strong>Receipt:</strong><div><a href={expenseDetails.receipt} target="_blank" rel="noopener noreferrer">View Receipt</a></div></div>
        </div>
      ) : (
        <div>No expense details available</div>
      )}
      <button onClick={() => navigate(-1)} className="expense-back-button">
        Go Back
      </button>
    </div>
  );
};


export default ViewExpense;




