import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, Stack } from "@mui/material";
import { Vortex } from 'react-loader-spinner';


function EmployeeSalary() {
  const [salaries, setSalaries] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showActions, setShowActions] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // To store selected user data


  useEffect(() => {
    const fetchSalaries = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setError('No token found');
        return;
      }


      try {
        const response = await fetch('https://api.myntask.com/payroll/employee_salary', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch salaries');
        }


        const data = await response.json();
        console.log('Salaries fetched:', data);
        setSalaries(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching salaries:', error);
        setError('Failed to fetch salaries');
        setIsLoading(false);
      }
    };


    fetchSalaries();
  }, []);


  const handleActionClick = (user) => {
    setSelectedUser(user); // Store the selected user's data
    setShowActions(true); // Show the action buttons and image
  };


  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }


  return (
    <div>
      <h1>Employee Salary</h1>
      {showActions && selectedUser && (
        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
          {selectedUser.image ? (
            <img
              src={selectedUser.image}
              alt={selectedUser.user_name}
              style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px' }}
            />
          ) : (
            <div
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor: '#ccc',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '10px',
              }}
            >
              No Image
            </div>
          )}


        </div>
      )}
      {error && <p>{error}</p>}
      {salaries.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Employee ID</th>
              <th>Employee Name</th>
              <th>Designation</th>
              <th>Salary</th>
              <th>Salary Circle</th>


            </tr>
          </thead>
          <tbody>
            {salaries.map((salary, index) => (
              <tr key={index}>
                <td>{salary.user_id}</td>
                <td>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {salary.image ? (
                      <img
                        src={salary.image}
                        alt={salary.user_name}
                        style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
                      />
                    ) : (
                      <div


                      >
                        No Image
                      </div>
                    )}
                    <span>{salary.user_name}</span>
                  </div>
                </td>
                <td>{salary.designation}</td>
                <td>


                  ₹{salary.salary || 'N/A'}


                </td>
                <td>


                  {salary.employee_salary_circle || 'N/A'}


                </td>




              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No salary data available</p>
      )}
    </div>
  );
}


export default EmployeeSalary;






