import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';
import Swal from 'sweetalert2';

const ClientEdit = () => {
  const { clientId } = useParams();
  const [client, setClient] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [countries, setCountries] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const navigate = useNavigate();


  console.log("client id is", clientId)


  useEffect(() => {
    const fetchClient = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch(
          `https://api.myntask.com/client/edit/${clientId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch client data");
        }

        const data = await response.json();
        console.log(data);
        setClient(data);

        // Trigger fetching of subcategories if category_id is present
        if (data.category_id) {
          fetchSubCategories(data.category_id);
        }

        setIsLoading(false);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchClient();
  }, [clientId]);



  useEffect(() => {
    // Fetch country list from the API
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          "https://api.myntask.com/home/country_list"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch country list");
        }
        const data = await response.json();
        setCountries(data); // Assuming the API returns an array of countries


        // Assuming each country object has a `phonecode` property
        const codes = data.map((country) => ({
          id: country.id,
          code: country.phonecode,
          name: country.name,
        }));
        setCountryCodes(codes);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    // Fetch client categories from the API
    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem("authToken"); // Get the token from local storage
        const response = await fetch(
          "https://api.myntask.com/client/category",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch client categories");
        }
        const data = await response.json();
        setCategories(data); // Assuming the API returns an array of categories
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };


    fetchCountries();
    fetchCategories();
  }, []);



  const fetchSubCategories = async (categoryId) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await fetch(
        `https://api.myntask.com/client/sub_category/${categoryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch client sub-categories");
      }

      const data = await response.json();
      console.log(data);
      setSubCategories(data); // Assuming the API returns an array of sub-categories
    } catch (error) {
      console.error("Error fetching sub-categories:", error);
    }
  };



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClient((prevClient) => ({
      ...prevClient,
      [name]: value,
    }));
  };


  const handleCountryChange = (e) => {
    const countryId = e.target.value;
    const selectedCountry = countryCodes.find(
      (country) => country.id === parseInt(countryId, 10)
    );


    setClient((prevState) => ({
      ...prevState,
      country_id: countryId,
      phonecode: selectedCountry ? selectedCountry.code : "", // Set phone code here
    }));
  };


  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;
    setClient((prevState) => ({
      ...prevState,
      category_id: categoryId,
    }));
    fetchSubCategories(categoryId); // Fetch subcategories for the new category
  };

  const handleSubCategoryChange = (e) => {
    const subCategoryId = e.target.value;
    setClient((prevState) => ({ ...prevState, sub_category_id: subCategoryId }));
  };


  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setClient((prevData) => ({
      ...prevData,
      [name]: parseInt(value)
    }));
  };


  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setClient((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const clientData = {
      client_id: client.client_id,
      user_id: client.user_id,
      client_name: client.client_name,
      company_name: client.company_name,
      status: client.status || "active",
      gender: client.gender,
      salutation: client.salutation,
      login: client.login,
      address: client.address,
      postal_code: client.postal_code,
      state: client.state,
      city: client.city,
      office_phone: client.office_phone,
      mobile: client.mobile,
      email_notifications: client.email_notifications,
      website: client.website,
      note: client.note,
      linkedin: client.linkedin || "",
      facebook: client.facebook || "",
      twitter: client.twitter || "",
      skype: client.skype || "",
      tax_name: client.tax_name,
      gst_number: client.gst_number,
      category_id: client.category_id,
      sub_category_id: client.sub_category_id,

    };
    console.log('client data is', clientData);
    setIsLoading(true);
    try {
      const token = localStorage.getItem("authToken");
      const response = await fetch("https://api.myntask.com/client/update", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(clientData),
      });

      console.log("Response status:", response.status);

      if (response.status !== 200 && response.status !== 204) {
        throw new Error("Failed to update client data");
      }

      let result = null;
      if (response.status !== 204) {
        result = await response.json();
      }
      console.log("Response from server:", result);

      Swal.fire({
        icon: 'success',
        title: 'Success!',
        text: 'Client updated successfully',
        timer: 1500,
        showConfirmButton: false,
      }).then(() => {
        setIsLoading(false);
        navigate("/clients");
      });
    } catch (error) {
      console.error("Error updating client:", error);
      setError(error.message);
    }
  };



  const handleCancel = () => {
    navigate("/clients");
  }




  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }


  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }








  return (


    <div className="main-container-body">
      <div className="add-lead-form">


        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Edit Client</h1>






        {client && (
          <div>


            <section className="lead-section">
              <div className="lead-section-heading">CLient Details</div>


              <div className="lead-form-row">


                <div className="lead-form-group">
                  <label className="lead-form-label">Client Name:</label>
                  <input
                    type="text"
                    name="company_name"
                    value={client.company_name}
                    onChange={handleInputChange}
                    className="lead-form-input"
                  />
                </div>


                <div className="lead-form-group">
                  <label className="lead-form-label">Official Website:</label>
                  <input
                    type="text"
                    name="official_website"
                    value={client.website}
                    onChange={handleInputChange}
                    className="lead-form-input"
                  />
                </div>


              </div>




              <div className="lead-form-row">


                <div className="lead-form-group">
                  <label className="lead-form-label">State:</label>
                  <input
                    type="text"
                    name="state"
                    value={client.state}
                    onChange={handleInputChange}
                    className="lead-form-input"
                  />
                </div>


                <div className="lead-form-group">
                  <label className="lead-form-label">City:</label>
                  <input
                    type="text"
                    name="city"
                    value={client.city}
                    onChange={handleInputChange}
                    className="lead-form-input"
                  />
                </div>
              </div>


              <div className="lead-form-row">
                <div className="lead-form-group">
                  <label className="lead-form-label">Office Phone Number:</label>
                  <input
                    type="text"
                    name="office_phone_number"
                    value={client.office_phone}
                    onChange={handleInputChange}
                    className="lead-form-input"
                  />
                </div>


                <div className="lead-form-group">
                  <label className="lead-form-label">Postal Code:</label>
                  <input
                    type="text"
                    name="postal_code"
                    value={client.postal_code}
                    onChange={handleInputChange}
                    className="lead-form-input"
                  />
                </div>
              </div>






              <div className="lead-form-row">
                <div className="lead-form-group">
                  <label className="lead-form-label">Client Category:</label>
                  <select
                    name="category_id"
                    value={client.category_id || ""}
                    onChange={handleCategoryChange}
                    className="lead-form-input"
                  >
                    <option value="">Select Category</option>
                    {categories.map((category) => (
                      <option key={category.category_id} value={category.category_id}>
                        {category.category_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="lead-form-group">
                  <label className="lead-form-label">Client Sub-Category:</label>
                  <select
                    name="sub_category_id"
                    value={client.sub_category_id || ""}
                    onChange={handleSubCategoryChange}
                    className="lead-form-input"
                  >
                    <option value="">Select Sub-Category</option>
                    {subCategories.map((subCategory) => (
                      <option key={subCategory.sub_category_id} value={subCategory.sub_category_id}>
                        {subCategory.category_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>






              <div className="lead-form-row">
                <div className="lead-form-group">
                  <label className="lead-form-label">Login Allowed:</label>
                  <div style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '60%',
                  }}>
                    <label >
                      <input
                        type="radio"
                        name="login"
                        value="enable"
                        checked={client.login === "enable"}
                        onChange={handleLoginChange}
                      />
                      Enable
                    </label>
                    <label className="lead-form-label">
                      <input
                        type="radio"
                        name="login"
                        value="disable"
                        checked={client.login === "disable"}
                        onChange={handleLoginChange}
                      />
                      Disable
                    </label>
                  </div>
                </div>


                <div className="lead-form-group">
                  <label className="lead-form-label">Receive Email Notification:</label>
                  <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '60%' }}>
                    <label className="label">
                      <input
                        type="radio"
                        name="email_notifications"
                        value="0"
                        checked={client.email_notifications === "0" || client.email_notifications === 0}
                        onChange={handleRadioChange}
                      />
                      Enable
                    </label>
                    <label className="label">
                      <input
                        type="radio"
                        name="email_notifications"
                        value="1"
                        checked={client.email_notifications === "1" || client.email_notifications === 1}
                        onChange={handleRadioChange}
                      />
                      Disable
                    </label>
                  </div>
                </div>
              </div>




              <div className="lead-form-row">
                <div className="lead-form-group">
                  <label className="lead-form-label">Company Address:</label>
                  <textarea
                    type="text"
                    name="address"
                    value={client.address}
                    onChange={handleInputChange}
                    className="lead-form-input"
                  />
                </div>
              </div>
            </section>






            <section className="lead-section">
              <div className="lead-section-heading">Contact Person Details</div>


              <div className="lead-form-row">
                <div className="lead-form-group">
                  <label className="lead-form-label">Name:</label>
                  <input
                    type="text"
                    name="client_name"
                    value={client.client_name || ""}
                    onChange={handleInputChange}
                    className="lead-form-input"
                  />
                </div>


                <div className="lead-form-group">
                  <label className="lead-form-label">Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={client.email || ""}
                    onChange={handleInputChange}
                    className="lead-form-input"
                  />
                </div>
              </div>




              <div className="lead-form-row">
                <div className="lead-form-group">
                  <label className="lead-form-label">Country:</label>
                  <select
                    name="country_id"
                    value={client.country_id || ""}
                    onChange={handleCountryChange}
                    className="lead-form-input"
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="lead-form-group">
                  <label className="lead-form-label">Phone Code:</label>
                  <input
                    type="text"
                    name="phonecode"
                    value={
                      countryCodes.find((code) => code.id === client.country_id)
                        ?.code || ""
                    }
                    onChange={handleCountryChange}
                    className="lead-form-input"
                    readOnly
                  />
                </div>
              </div>


              <div className="lead-form-row">
                <div className="lead-form-group">
                  <label className="lead-form-label">Mobile:</label>
                  <input
                    type="text"
                    name="mobile"
                    value={client.mobile || ""}
                    onChange={handleInputChange}
                    className="lead-form-input"
                  />
                </div>


                <div className="lead-form-group">
                  <label className="lead-form-label">Gender:</label>
                  <select
                    name="gender"
                    value={client.gender || ""}
                    onChange={handleInputChange}
                    className="lead-form-input"
                  >
                    <option value="">Select Gender</option>
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                    <option value="others">Others</option>
                  </select>
                </div>
              </div>

              {/* <div className="lead-form-row">
                <div className="lead-form-group">
                  <label className="lead-form-label">Language:</label>
                  <input
                    type="text"
                    name="language"
                    value={client.language || ""}
                    onChange={handleInputChange}
                    className="lead-form-input"
                  />
                </div>


                <div className="lead-form-group">


                </div>
              </div> */}
            </section>


            <section className="lead-section">
              <div className="lead-section-heading">Tax and Statutory Details</div>
              <div className="lead-form-row">
                <div className="lead-form-group">
                  <label className="lead-form-label">Tax Name:</label>
                  <input
                    type="text"
                    name="tax_name"
                    value={client.tax_name}
                    onChange={handleInputChange}
                    className="lead-form-input"
                  />
                </div>
                <div className="lead-form-group">
                  <label className="lead-form-label">GST Number:</label>
                  <input
                    type="text"
                    name="gst_number"
                    value={client.gst_number}
                    onChange={handleInputChange}
                    className="lead-form-input"
                  />
                </div>
              </div>


            </section>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px', gap: '15px' }}>
              <button className='color-button' onClick={handleCancel}>
                Cancel
              </button>
              <button className="color-button" onClick={handleSubmit}>
                Save Client
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};




export default ClientEdit;


