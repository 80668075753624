import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './AddLeadPage.css';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';



const AddLeadPage = () => {
  const [newLead, setNewLead] = useState({
    name: '',
    email: '',
    source_id: '',
    status: 'new',
    lead_value: '',
    industry: 'retail',
    company_name: '',
    website: '',
    mobile: '',
    office_phone_number: '',
    country: '',
    state: '',
    city: '',
    postal_code: '',
    address: '',
  });




  const [countries, setCountries] = useState([]);
  const [sources, setSources] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [errors, setErrors] = useState({
    email: '',
    mobile: '',
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);




  const emailRef = useRef(null);
  const mobileRef = useRef(null);






  const validateForm = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const mobileRegex = /^[0-9]{10}$/;


    let emailError = '';
    let mobileError = '';

    if (!emailRegex.test(newLead.email)) {
      emailError = 'Please enter a valid email address';
    }


    if (!mobileRegex.test(newLead.mobile)) {
      mobileError = 'Please enter a valid 10-digit mobile number';
    }

    setErrors({
      email: emailError,
      mobile: mobileError,
    });


    if (emailError) {
      emailRef.current.focus();
      return false;
    }

    if (mobileError) {
      mobileRef.current.focus();
      return false;
    }

    return true;
    // return emailError === '' && mobileError === '';
  };








  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewLead((prevLead) => ({ ...prevLead, [name]: value }));
  };





  const handleSaveLead = async () => {
    if (validateForm()) {
      setIsLoading(true);
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          throw new Error('No token found');
        }

        // Log the data being sent
        console.log('Data being sent:', newLead);

        const response = await fetch('https://api.myntask.com/leads/add_lead', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(newLead), // Send the data as a JSON string
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(errorText || 'Failed to add lead');
        }

        const data = await response.json();
        console.log('Response:', data);

        // SweetAlert success popup without confirmation, auto-dismiss after 2 seconds
        Swal.fire({
          icon: 'success',
          title: 'Lead added successfully!',
          timer: 2000, // Auto-dismiss after 2 seconds
          showConfirmButton: false, // No confirmation button
        });

        // Navigate to the lead-contact page after success
        setTimeout(() => {
          setIsLoading(false);
          navigate('/lead-contact');
        }, 2000); // Add a slight delay for better UX
      } catch (err) {
        // SweetAlert error popup without confirmation, auto-dismiss after 2 seconds
        Swal.fire({
          icon: 'error',
          title: 'Failed to add lead.',
          text: err.message || 'Please try again.',
          timer: 2000, // Auto-dismiss after 2 seconds
          showConfirmButton: false, // No confirmation button
        });
      }
      setIsLoading(false);
    }
  };




  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://api.myntask.com/home/country_list");
        if (!response.ok) {
          throw new Error("Failed to fetch country list");
        }
        const data = await response.json();
        setCountries(data);
        console.log("Countries:", data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };




    const fetchSources = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch("https://api.myntask.com/leads/lead_source", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error("Failed to fetch Sources");
        }
        const data = await response.json();
        setSources(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching Sources:", error);
      }
      finally {
        setIsLoading(false);
      }
    };




    fetchCountries();
    fetchSources();
  }, []);




  // return (
  //   <div style={formStyles.body}>
  //     <div style={formStyles.addLeadForm}>
  //       <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Lead</h1>




  //       {/* Lead Information Section */}
  //       <div style={formStyles.section}>
  //         <div style={formStyles.sectionHeading}>Lead Information</div>
  //         <div style={formStyles.formGroup}>
  //           <label style={formStyles.formLabel}>Source ID:</label>
  //           <select name="source_id" value={newLead.source_id} onChange={handleInputChange} style={formStyles.formInput}>
  //             <option value="">Select source</option>
  //             {sources.map(source => (
  //               <option key={source.id} value={source.id}>{source.type}</option>
  //             ))}
  //           </select>
  //         </div>




  //         <div style={formStyles.formGroup}>
  //           <label style={formStyles.formLabel}>Status:</label>
  //           <select name="status" value={newLead.status} onChange={handleInputChange} style={formStyles.formInput}>
  //             <option value="new">New</option>
  //             <option value="contacted">Contacted</option>
  //             <option value="qualified">Qualified</option>
  //             <option value="lost">Lost</option>
  //             <option value="closed">Closed</option>
  //           </select>
  //         </div>
  //       </div>




  //       {/* Contact Details Section */}
  //       <div style={formStyles.section}>
  //         <div style={formStyles.sectionHeading}>Contact Details</div>
  //         <div style={formStyles.formRow}>
  //           <div style={formStyles.formGroup}>
  //             <label style={formStyles.formLabel}>Name:</label>
  //             <input type="text" name="name" value={newLead.name} onChange={handleInputChange} style={formStyles.formInput} />
  //           </div>
  //           <div style={formStyles.formGroup}>
  //             <label style={formStyles.formLabel}>Email:</label>
  //             <input type="email" name="email" value={newLead.email} onChange={handleInputChange} style={formStyles.formInput} />
  //           </div>
  //         </div>




  //         <div style={formStyles.formRow}>
  //           <div style={formStyles.formGroup}>
  //             <label style={formStyles.formLabel}>Mobile:</label>
  //             <input type="text" name="mobile" value={newLead.mobile} onChange={handleInputChange} style={formStyles.formInput} />
  //           </div>
  //           <div style={formStyles.formGroup}>
  //             <label style={formStyles.formLabel}>Phone Number:</label>
  //             <input type="text" name="office_phone_number" value={newLead.office_phone_number} onChange={handleInputChange} style={formStyles.formInput} />
  //           </div>
  //         </div>
  //       </div>




  //       {/* Company Information Section */}
  //       <div style={formStyles.section}>
  //         <div style={formStyles.sectionHeading}>Company Information</div>
  //         <div style={formStyles.formGroup}>
  //           <label style={formStyles.formLabel}>Company Name:</label>
  //           <input type="text" name="company_name" value={newLead.company_name} onChange={handleInputChange} style={formStyles.formInput} />
  //         </div>
  //         <div style={formStyles.formGroup}>
  //           <label style={formStyles.formLabel}>Industry:</label>
  //           <input type="text" name="industry" value={newLead.industry} onChange={handleInputChange} style={formStyles.formInput} />
  //         </div>
  //         <div style={formStyles.formGroup}>
  //           <label style={formStyles.formLabel}>Website:</label>
  //           <input type="text" name="website" value={newLead.website} onChange={handleInputChange} style={formStyles.formInput} />
  //         </div>
  //       </div>




  //       {/* Address Details Section */}
  //       <div style={formStyles.section}>
  //         <div style={formStyles.sectionHeading}>Address Details</div>
  //         <div style={formStyles.formGroup}>
  //           <label style={formStyles.formLabel}>Address:</label>
  //           <input type="text" name="address" value={newLead.address} onChange={handleInputChange} style={formStyles.formInput} />
  //         </div>
  //         <div style={formStyles.formRow}>
  //           <div style={formStyles.formGroup}>
  //             <label style={formStyles.formLabel}>City:</label>
  //             <input type="text" name="city" value={newLead.city} onChange={handleInputChange} style={formStyles.formInput} />
  //           </div>
  //           <div style={formStyles.formGroup}>
  //             <label style={formStyles.formLabel}>State:</label>
  //             <input type="text" name="state" value={newLead.state} onChange={handleInputChange} style={formStyles.formInput} />
  //           </div>
  //         </div>
  //         <div style={formStyles.formRow}>
  //           <div style={formStyles.formGroup}>
  //             <label style={formStyles.formLabel}>Postal Code:</label>
  //             <input type="text" name="postal_code" value={newLead.postal_code} onChange={handleInputChange} style={formStyles.formInput} />
  //           </div>
  //           <div style={formStyles.formGroup}>
  //             <label style={formStyles.formLabel}>Country:</label>
  //             <select
  //               name="country"
  //               value={newLead.country}  // newLead.country should contain the selected country name
  //               onChange={handleInputChange}
  //               style={formStyles.formInput}
  //             >
  //               <option value="">Select Country</option>
  //               {countries.map(country => (
  //                 <option key={country.id} value={country.name}>{country.name}</option>
  //               ))}
  //             </select>
  //           </div>








  //         </div>
  //       </div>




  //       {/* Action Buttons */}
  //       <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', gap: '20px' }}>
  //         <button
  //           onClick={handleSaveLead}
  //           className='color-button'
  //         >
  //           Save Lead
  //         </button>
  //         <button
  //           onClick={() => window.history.back()}
  //           className='color-button'
  //         >
  //           Cancel
  //         </button>
  //       </div>
  //     </div>
  //   </div>
  // );



  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
      >
        {error}
      </Typography>
    );
  }




  return (
    <div className="main-container-body">
      <div className="add-lead-form">
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Add Lead</h1>




        {/* Lead Information Section */}
        <div className="lead-section">
          <div className="lead-section-heading">Lead Information</div>
          <div className="lead-form-group">
            <label className="form-label-deal">Source ID:</label>
            <select
              name="source_id"
              value={newLead.source_id}
              onChange={handleInputChange}
              className="lead-form-input"
            >
              <option value="">Select source</option>
              {sources.map((source) => (
                <option key={source.id} value={source.id}>{source.type}</option>
              ))}
            </select>
          </div>




          <div className='lead-form-group'>
            <label className="form-label-deal">Lead Value</label>
            <input
              type="text"
              name="lead_value"
              value={newLead.lead_value}
              onChange={handleInputChange}
              className="lead-form-input"
            />
          </div>




          <div className="lead-form-group">
            <label className="form-label-deal">Status:</label>
            <select
              name="status"
              value={newLead.status}
              onChange={handleInputChange}
              className="lead-form-input"
            >
              <option value="new">New</option>
              <option value="contacted">Contacted</option>
              <option value="qualified">Qualified</option>
              <option value="lost">Lost</option>
              <option value="closed">Closed</option>
            </select>
          </div>
        </div>




        {/* Contact Details Section */}
        <div className="lead-section">
          <div className="lead-section-heading">Contact Details</div>
          <div className="lead-form-row">
            <div className="lead-form-group">
              <label className="form-label-deal">Name:</label>
              <input
                type="text"
                name="name"
                value={newLead.name}
                onChange={handleInputChange}
                className="lead-form-input"
              />
            </div>
            <div className="lead-form-group">
              <label className="form-label-deal">
                Email:<span className="required">*</span>
              </label>
              <input
                type="email"
                name="email"
                ref={emailRef}
                value={newLead.email}
                onChange={handleInputChange}
                className="lead-form-input"
              />
              {errors.email && <div style={{ color: 'red', fontSize: '12px' }}>{errors.email}</div>}
            </div>
          </div>




          <div className="lead-form-row">
            <div className="lead-form-group">
              <label className="form-label-deal">
                Mobile:<span className="required">*</span>
              </label>
              <input
                type="text"
                name="mobile"
                ref={mobileRef}
                value={newLead.mobile}
                onChange={handleInputChange}
                className="lead-form-input"
              />
              {errors.mobile && <div style={{ color: 'red', fontSize: '12px' }}>{errors.mobile}</div>}
            </div>
            <div className="lead-form-group">
              <label className="form-label-deal">Phone Number:</label>
              <input
                type="text"
                name="office_phone_number"
                value={newLead.office_phone_number}
                onChange={handleInputChange}
                className="lead-form-input"
              />
            </div>
          </div>
        </div>




        {/* Company Information Section */}
        <div className="lead-section">
          <div className="lead-section-heading">Company Information</div>
          <div className="lead-form-group">
            <label className="form-label-deal">Company Name:</label>
            <input
              type="text"
              name="company_name"
              value={newLead.company_name}
              onChange={handleInputChange}
              className="lead-form-input"
            />
          </div>
          <div className="lead-form-group">
            <label className="form-label-deal">Industry:</label>
            <input
              type="text"
              name="industry"
              value={newLead.industry}
              onChange={handleInputChange}
              className="lead-form-input"
            />
          </div>
          <div className="lead-form-group">
            <label className="form-label-deal">Website:</label>
            <input
              type="text"
              name="website"
              value={newLead.website}
              onChange={handleInputChange}
              className="lead-form-input"
            />
          </div>
        </div>


        {/* Address Details Section */}
        <div className="lead-section">
          <div className="lead-section-heading">Address Details</div>
          <div className="lead-form-group">
            <label className="form-label-deal">Address:</label>
            <input
              type="text"
              name="address"
              value={newLead.address}
              onChange={handleInputChange}
              className="lead-form-input"
            />
          </div>
          <div className="lead-form-row">
            <div className="lead-form-group">
              <label className="form-label-deal">City:</label>
              <input
                type="text"
                name="city"
                value={newLead.city}
                onChange={handleInputChange}
                className="lead-form-input"
              />
            </div>
            <div className="lead-form-group">
              <label className="form-label-deal">State:</label>
              <input
                type="text"
                name="state"
                value={newLead.state}
                onChange={handleInputChange}
                className="lead-form-input"
              />
            </div>
          </div>
          <div className="lead-form-row">
            <div className="lead-form-group">
              <label className="form-label-deal">Postal Code:</label>
              <input
                type="text"
                name="postal_code"
                value={newLead.postal_code}
                onChange={handleInputChange}
                className="lead-form-input"
              />
            </div>
            <div className="lead-form-group">
              <label className="form-label-deal">Country:</label>
              <select
                name="country"
                value={newLead.country}
                onChange={handleInputChange}
                className="lead-form-input"
              >
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option key={country.id} value={country.name}>{country.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>


        {/* Action Buttons */}
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px', gap: '20px' }}>
          <button onClick={() => window.history.back()} className="color-button">Cancel</button>
          <button onClick={handleSaveLead} className="color-button">Save Lead</button>
        </div>
      </div>
    </div>
  );


};






export default AddLeadPage;


















