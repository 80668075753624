// src/ProfileDetail.js




import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import './ProfileDetail.css'; // Optional: add CSS for styling




ChartJS.register(Title, Tooltip, Legend, ArcElement);




const ProfileDetail = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState('');




  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await fetch('https://api.myntask.com/auth/profile_info', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });




        if (!response.ok) {
          throw new Error('Failed to fetch profile data');
        }




        const data = await response.json();
        setProfileData(data);
      } catch (err) {
        setError('Error fetching profile data');
        console.error(err);
      }
    };




    fetchProfileData();
  }, [token]);




  const handleBack = () => {
    navigate('/dashboard');
  };




  //  if (error) {
  //    return <div>Error: {error}</div>;
  //  }




  if (!profileData) {
    return <div>Loading...</div>;
  }




  // Pie chart data
  const pieChartData = {
    labels: ['Tasks', 'Projects', 'Hours Logged', 'Tickets'],
    datasets: [
      {
        data: [
          profileData.task,
          profileData.project,
          profileData.hours_logged,
          profileData.tickets
        ],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0']
      }
    ]
  };




  return (
    <div className="profile-detail-container">
      <div className="profile-detail-header">
        <h1>Profile Detail</h1>
        <button onClick={handleBack}>Back to Dashboard</button>
      </div>
      <div className="profile-detail-row">
        <div className="profile-detail-main">
          <div className="profile-info-row">
            {profileData.image ? (
              <img
                src={profileData.image}
                alt="User Avatar"
                className="profile-avatar"
              />
            ) : (
              <div className="no-image-text">No Image</div>
            )}
            <div className="profile-info">
              <h2>{profileData.name || 'User Name'}</h2>
              <p>Department: {profileData.department}</p>
              <p>Designation: {profileData.designation}</p>
              <p className="last-login">Last Login: {new Date(profileData.last_login).toLocaleString()}</p>
            </div>
          </div>
          <div className="profile-detail-content">
            <div className="profile-stat">
              <p>Tasks</p>
              <div className="profile-stat-count">{profileData.task}</div>
            </div>
            <div className="profile-stat">
              <p>Projects</p>
              <div className="profile-stat-count">{profileData.project}</div>
            </div>
            <div className="profile-stat">
              <p>Hours</p>
              <div className="profile-stat-count">{profileData.hours_logged}</div>
            </div>
            <div className="profile-stat">
              <p>Tickets</p>
              <div className="profile-stat-count">{profileData.tickets}</div>
            </div>
          </div>
        </div>
        <div className="profile-detail-side">
          <h2>Reporting Team</h2>
          {profileData.reporting_team_image ? (
            <img
              src={profileData.reporting_team_image}
              alt="Reporting Team Avatar"
              className="profile-avatar"
            />
          ) : (
            <div className="no-image-text">No Image</div>
          )}
          <p>{profileData.reporting_team}</p>
        </div>
      </div>
      <div className="profile-detail-reporting">
        <p>Email: {profileData.email || 'user@example.com'}</p>
        <p>Mobile: {profileData.mobile || '000-000-0000'}</p>
        <p>Employee ID: {profileData.employee_id}</p>
        <p>Address: {profileData.address}</p>
        <p>Date of Birth: {new Date(profileData.date_of_birth).toLocaleDateString()}</p>
        <p>Marital Status: {profileData.marital_status}</p>
        <p>Employment Type: {profileData.employment_type}</p>
        <p>Reporting To: {profileData.reporting_to}</p>
        {profileData.reporting_user_image ? (
          <img
            src={profileData.reporting_user_image}
            alt="Reporting User Avatar"
            className="profile-avatar"
          />
        ) : (
          <div className="no-image-text">No Image</div>
        )}
      </div>
      <div className="profile-detail-extra">
        <div className="profile-detail-extra-70">
          <h3>Performance Overview</h3>
          <Pie data={pieChartData} className="profile-detail-pie-chart" />
        </div>
        <div className="profile-detail-extra-30">
          {/* Additional content can be added here */}
        </div>
      </div>
    </div>
  );
};




export default ProfileDetail;


















