
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './../Components/Main.css'; // Import the CSS file
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';
import Swal from 'sweetalert2';

function Estimate() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);


  const fetchEstimates = async () => {
    const token = localStorage.getItem('authToken');

    if (!token) {
      console.error('No token found, please login first.');
      return;
    }

    try {
      const response = await fetch('https://api.myntask.com/estimate/list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch estimates');
      }

      const result = await response.json();
      console.log('Estimate data:', result);
      setData(result);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching estimates:', error);
      setError("Failed to Fetch Estimates");
      setIsLoading(false);
    }
  };


  useEffect(() => {

    fetchEstimates();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = data.filter((estimate) =>
    estimate.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    estimate.estimate_number.toLowerCase().includes(searchTerm.toLowerCase())
  );




  const toTitleCase = (str) => {
    if (!str) return ""; // Return an empty string if the input is null or undefined
    return str
      .toLowerCase() // Convert to lowercase
      .split(' ') // Split the string into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words back into a string
  };


  const deleteEstimate = async (id) => {

    setIsLoading(true);
    try {
      const authToken = localStorage.getItem("authToken"); // Get the authToken from local storage

      if (!authToken) {
        console.error("Auth token not found");
        return;
      }

      const url = `https://api.myntask.com/estimate/delete/${id}`;

      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authToken}`, // Include the authToken in the headers
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Estimate deleted successfully", data);
        fetchEstimates();
        Swal.fire("Deleted!", "The estimate has been deleted.", "success");
        // Add any additional logic, such as updating the UI
      } else {
        console.error("Failed to delete the estimate");
        Swal.fire("Error", "Failed to delete the estimate.", "error");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error deleting estimate:", error);
      Swal.fire("Error", "Something went wrong!", "error");
    }
    setIsLoading(false);
  };

  const confirmDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEstimate(id); // Call the delete function if confirmed
      }
    });
  };




  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }




  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className='title'>Estimates
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">
          <button className="color-button" onClick={() => navigate('/addestimate')}>
            Create Estimate
          </button>

        </div>
      </div>
      <div className="top-controls">
        <div className="filters-and-search">

          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
            className="searchbar"
          />

        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Estimate Number</th>
            <th>Customer Name</th>
            <th>Total</th>
            <th>Status</th>
            <th>Valid Till</th>
            <th>Image</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((estimate) => (
            <tr key={estimate.id}>
              <td>{estimate.estimate_number}</td>
              <td>{toTitleCase(estimate.name)}</td>
              <td>{estimate.total}</td>
              <td>{toTitleCase(estimate.status)}</td>
              <td>{new Date(estimate.valid_till).toLocaleDateString()}</td>
              <td>
                {estimate.image ? (
                  <img
                    src={estimate.image}
                    alt="Estimate"
                    className="image"
                  />
                ) : (
                  <img
                    src="/path/to/default/image.jpg"
                    alt="Default"
                    className="image"
                  />
                )}
              </td>
              <td>
                <span
                  onClick={() => confirmDelete(estimate.id)}
                  style={{ color: "red", cursor: "pointer" }}
                >
                  Delete
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

    </div>
  );
}

export default Estimate;
