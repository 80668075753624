import React, { useState, useEffect } from 'react';
import {
  Box,
  Pagination,
  Stack, Typography




} from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Vortex } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';




const TaskReport = () => {
  const [taskReports, setTaskReports] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(null); // Track which status dropdown is open
  const tasksPerPage = 10;
  const navigate = useNavigate(); // Create navigate function
  const token = localStorage.getItem('authToken');




  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.myntask.com/report/task', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        console.log('Response:', data);
        setTaskReports(data.task_list || []);
        setFilteredTasks(data.task_list || []);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching task report:', error);
        setError("Failed to Fetch Data");
        setIsLoading(false);
      }
    };




    fetchData();
  }, [token]);




  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const filtered = taskReports.filter(
      (task) =>
      (task.task?.toLowerCase().includes(event.target.value.toLowerCase()) ||
        task.code?.toLowerCase().includes(event.target.value.toLowerCase()))
    );
    setFilteredTasks(filtered);
  };




  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };




  const totalTasks = filteredTasks.length;
  const displayedTasks = filteredTasks.slice((currentPage - 1) * tasksPerPage, currentPage * tasksPerPage);




  const taskStatusCount = {
    Completed: taskReports.filter(task => task.status === 'Completed').length,
    Incomplete: taskReports.filter(task => task.status === 'Incomplete').length,
    ToDo: taskReports.filter(task => task.status === 'To Do').length,
  };




  const pieData = [
    { name: 'Completed', value: taskStatusCount.Completed },
    { name: 'Incomplete', value: taskStatusCount.Incomplete },
    { name: 'To Do', value: taskStatusCount.ToDo },
  ];




  const COLORS = ['#00C49F', '#FFBB28', '#FF8042'];




  const toTitleCase = (str) => {
    if (!str) return 'N/A';  // Check for undefined or null values
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };




  const truncateWords = (str, wordLimit) => {
    const words = str.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...'; // Show only first 10 words with "..."
    }
    return str; // If less than 10 words, show full string
  };




  const toggleDropdown = (userName, status) => {
    setDropdownOpen((prev) =>
      prev && prev.userName === userName && prev.status === status
        ? null
        : { userName, status }
    );
  };






  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }




  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }












  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className='title'>Task Report
          <div className="underline"></div>
        </h1>
        <div className="buttons-row">








          <button className="color-button" onClick={() => navigate('/user-performance')}>
            User Performance
          </button>








        </div>
      </div>






      {/*
     <Box sx={{ marginBottom: '20px', backgroundColor: '#f9f9f9', padding: '20px', borderRadius: '8px' }}>
       <ResponsiveContainer width="100%" height={300}>
         <PieChart>
           <Pie
             data={pieData}
             dataKey="value"
             nameKey="name"
             cx="50%"
             cy="50%"
             outerRadius={80}
             fill="#8884d8"
             label
           >
             {pieData.map((entry, index) => (
               <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
             ))}
           </Pie>
           <Tooltip />
           <Legend />
         </PieChart>
       </ResponsiveContainer>
     </Box>


*/}




      <div className="top-controls">
        <div className="filters-and-search">




          <input
            type="text"
            placeholder="Search by name or email..."
            value={searchTerm}
            onChange={handleSearch}
            className="searchbar"
          />
        </div>
      </div>
      <table >
        <thead>
          <tr>
            <th >User</th>
            <th >Status</th>
          </tr>
        </thead>
        <tbody>
          {taskReports.map((user) => (
            <React.Fragment key={user.user_name}>
              <tr>
                <td >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={user.user_image}
                      alt={user.user_name}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                    />
                    {user.user_name}
                  </div>
                </td>
                <td >
                  <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                    {Object.entries(user.tasks).map(([status, details]) => (
                      <div key={status} style={{ position: "relative" }}>
                        <button
                          onClick={() => toggleDropdown(user.user_name, status)}
                          style={{
                            padding: "5px 10px",
                            background: "#007bff",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                        >
                          {status} ({details.count})
                        </button>
                        {dropdownOpen &&
                          dropdownOpen.userName === user.user_name &&
                          dropdownOpen.status === status && (
                            <div
                              style={{
                                position: "absolute",
                                top: "100%",
                                left: "0",
                                backgroundColor: "#fff",
                                border: "1px solid #ccc",
                                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                                zIndex: 10,
                                width: "500px",
                                padding: "10px",
                                maxHeight: "300px", // Set the maximum height
                                overflowY: "auto", // Enable vertical scrolling
                              }}
                            >
                              <table

                              >
                                <thead>
                                  <tr>
                                    <th

                                    >
                                      Task Code
                                    </th>
                                    <th

                                    >
                                      Task
                                    </th>
                                    <th

                                    >
                                      Due Date
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {details.list.map((task) => (
                                    <tr key={task.task_id}>
                                      <td

                                      >
                                        {task.code}
                                      </td>
                                      <td

                                      >
                                        {task.task}
                                      </td>
                                      <td

                                      >
                                        {task.due_date}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>


      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', paddingBottom: '20px' }}>
        <Pagination
          count={Math.ceil(totalTasks / tasksPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </div>
  );
};




export default TaskReport;















