import React, { useEffect, useState } from 'react';
import { Modal, Box } from '@mui/material';
import iconImage from '../assets/Icon.png';
import Swal from "sweetalert2";
const UnitSetting = () => {

    const [unitTypeOptions, setUnitTypeOptions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newUnitName, setNewUnitName] = useState("");
    // Fetch Unit Type Options

    const fetchUnitTypes = async () => {
        const token = localStorage.getItem("authToken");
        if (!token) return;


        try {
            const response = await fetch("https://api.myntask.com/product/unit_types", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) throw new Error("Failed to fetch unit types");

            const data = await response.json();
            setUnitTypeOptions(data);
        } catch (error) {
            console.error("Error fetching unit types:", error);
        }
    };

    useEffect(() => {
        fetchUnitTypes();
    }, []);


    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setNewUnitName("");
    };


    const handleAddUnit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("authToken");

        const data = {
            unit_name: newUnitName,
        }

        try {
            const response = await fetch("https://api.myntask.com/home/unit_save", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                throw new Error("Failed to add category");
            }

            fetchUnitTypes();

            closeModal();

            Swal.fire({
                title: "Success!",
                text: "Unit added successfully.",
                icon: "success",
                toast: true,
                position: "top-right",
                timer: 2000,
                showConfirmButton: false,
            });

        } catch (error) {
            console.error("Error adding unit:", error);

            // Error alert
            Swal.fire({
                title: "Error",
                text: "Failed to add Unit. Please try again.",
                icon: "error",
                toast: true,
                position: "top-right",
                timer: 2000,
                showConfirmButton: false,
            });
        }
    }


    const handleDleteUnit = async (unitId) => {
        const token = localStorage.getItem("authToken");

        // Confirmation dialog
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            try {
                const response = await fetch(`https://api.myntask.com/home/unit_delete/${unitId}`, {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                });
                if (!response.ok) {
                    throw new Error("Failed to delete Unit");
                }
                fetchUnitTypes();

                // Success alert
                Swal.fire({
                    title: "Deleted!",
                    text: "Unit deleted successfully.",
                    icon: "success",
                    toast: true,
                    position: "top-right",
                    timer: 2000,
                    showConfirmButton: false,
                });
            } catch (error) {
                console.error("Error deleting Unit:", error);

                // Error alert
                Swal.fire({
                    title: "Error",
                    text: "Failed to delete Unit. Please try again.",
                    icon: "error",
                    toast: true,
                    position: "top-right",
                    timer: 2000,
                    showConfirmButton: false,
                });
            }
        }
    }


    return (
        <div className="permissions-container">
            <div className="top-controls">
                <h1 className="title">
                    Unit Setting
                    <div className="underline"></div>
                </h1>
                <div className="buttons-row">
                    <button className="color-button" onClick={openModal}>
                        Add Unit
                    </button>
                </div>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Unit Categories</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {unitTypeOptions.map((unit) => (
                        <tr key={unit.id}>
                            <td>{unit.id}</td>
                            <td>{unit.unit_type}</td>
                            <td><span onClick={() => handleDleteUnit(unit.id)} style={{ cursor: "pointer", color: "red" }}>
                                Delete
                            </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>


            <Modal
                open={isModalOpen}
                onClose={closeModal}
            >
                <Box className="designation-modal">
                    {/* Header with title and close button */}
                    <div className="designation-header">
                        <h1 className="designation-title">Add New Unit</h1>
                        <img
                            src={iconImage} // Replace 'iconImage' with the path or import for Icon.png
                            alt="Close"
                            className="designation-close"
                            onClick={closeModal}
                        />
                    </div>

                    {/* Form Section */}
                    <div className="designation-form">

                        <label className="designation-label" htmlFor="newUnitName">
                            Unit Name:
                        </label>
                        <input
                            type="text"
                            id="newUnitName"
                            name="newUnitName"
                            value={newUnitName}
                            className="designation-input"
                            onChange={(e) => setNewUnitName(e.target.value)}
                        />

                        <button onClick={handleAddUnit} className="designation-save">
                            Add Unit
                        </button>
                    </div>
                </Box>
            </Modal>


        </div>
    )
}

export default UnitSetting;