import React, { useEffect, useState } from 'react';
import { Vortex } from 'react-loader-spinner';
import { Box, Stack, Typography } from '@mui/material';

const Bill = () => {
  const [bills, setBills] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');


  useEffect(() => {
    const fetchBills = async () => {
      setIsLoading(true);
      try {
        const token = localStorage.getItem('authToken'); // Make sure you have an auth token
        const response = await fetch('https://api.myntask.com/bill/list', {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });


        if (!response.ok) {
          throw new Error('Failed to fetch bills');
        }


        const data = await response.json();
        setBills(data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching bills:', error);
        setError('Failed to load bills');
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };


    fetchBills();
  }, []);



  //..........Loading Spinner ..........................................
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Stack alignItems="center">
          <Vortex height="80" width="80" radius="9" color="blue" ariaLabel="loading" />
          <Box component="span" sx={{ fontSize: "1.2rem", color: "#000" }}>
            Loading....
          </Box>
        </Stack>
      </Box>
    );
  }

  // if (error) {
  //   return (
  //     <Typography
  //       color="error"
  //       sx={{ textAlign: "center", marginTop: "20px", fontSize: "18px" }}
  //     >
  //       {error}
  //     </Typography>
  //   );
  // }



  const toTitleCase = (str) => {
    if (!str) return ""; // Return an empty string if the input is null or undefined
    return str
      .toLowerCase() // Convert to lowercase
      .split(' ') // Split the string into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words back into a string
  };


  return (
    <div className="main-container">
      <div className="top-controls">
        <h1 className='title'>Bills
          <div className="underline"></div>
        </h1>
      </div>

      <table>
        <thead>
          <tr>
            <th>Bill Number</th>
            <th>Name</th>
            <th>Date</th>
            <th>Total</th>
            <th>Total Paid</th>
            <th>Unpaid Amount</th>
          </tr>
        </thead>
        <tbody>
          {bills.map((bill) => (
            <tr key={bill.purchase_bill_number}>
              <td>{bill.purchase_bill_number}</td>
              <td>{toTitleCase(bill.primary_name)}</td> {/* Apply title case */}
              <td >
                {new Date(bill.bill_date).toLocaleDateString()}
              </td>
              <td>{toTitleCase(bill.total.toString())}</td> {/* Convert total to string and apply title case */}
              <td>{toTitleCase(bill.total_paid.toString())}</td> {/* Convert total_paid to string and apply title case */}
              <td>{toTitleCase(bill.unpaidAmount.toString())}</td> {/* Convert unpaidAmount to string and apply title case */}
            </tr>
          ))}
        </tbody>
      </table>

    </div>
  );
};





export default Bill;




