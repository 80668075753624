import React, { useState } from 'react';
import swal from 'sweetalert';


const AddFollowUpForm = ({ dealId, onFollowUpAdded }) => {
    const [remark, setRemark] = useState('');
    const [nextFollowUpDate, setNextFollowUpDate] = useState('');
    const [sendReminder, setSendReminder] = useState(false);
    const [remindTime, setRemindTime] = useState('');
    const [remindType, setRemindType] = useState('');




    const resetFields = () => {
        setRemark('');
        setNextFollowUpDate('');
        setSendReminder(false);
        setRemindTime('');
        setRemindType('');
    };




    const handleSubmit = async (e) => {
        e.preventDefault();




        const payload = {
            deal_id: dealId,
            remark,
            next_follow_up_date: nextFollowUpDate,
            send_reminder: sendReminder ? 1 : 0,
            remind_time: parseInt(remindTime),
            remind_type: remindType,
        };




        try {
            const token = localStorage.getItem('authToken');
            const response = await fetch('https://api.myntask.com/leads/add_follow_up', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });




            if (!response.ok) {
                throw new Error('Failed to add follow-up.');
            }




            const data = await response.json();
            onFollowUpAdded(data);
            resetFields(); // Reset the form fields after success
            swal({
                title: 'Success!',
                text: 'Follow-up added successfully!',
                icon: 'success',
                timer: 2000,
                buttons: false,
            });
        } catch (error) {
            console.error('Error adding follow-up:', error);
            alert('Failed to add follow-up. Please try again.');
        }
    };




    return (
        <form onSubmit={handleSubmit} style={formStyle}>
            <div>
                <label style={labelStyle}>Remark</label>
                <textarea
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                    required
                    placeholder="Enter your remark"
                    // className='input-field'
                    style={inputStyle}
                ></textarea>
            </div>
            <div>
                <label style={labelStyle}>Next Follow-Up Date</label>
                <input
                    type="date"
                    value={nextFollowUpDate}
                    onChange={(e) => setNextFollowUpDate(e.target.value)}
                    required
                    // className='input-field'
                    style={inputStyle}
                />
            </div>
            <div style={sendReminderStyle}>
                <label style={labelStyle}>Send Reminder</label>
                <input
                    type="checkbox"
                    id='sendReminder'
                    checked={sendReminder}
                    onChange={() => setSendReminder((prev) => !prev)}
                />
            </div>
            {sendReminder && (
                <>
                    <div>
                        <label style={labelStyle}>Reminder Time</label>
                        <input
                            type="number"
                            value={remindTime}
                            onChange={(e) => setRemindTime(e.target.value)}
                            required
                            placeholder="e.g., 2"
                            // className='input-field'
                            style={inputStyle}
                        />
                    </div>
                    <div>
                        <label style={labelStyle}>Reminder Type</label>
                        <select
                            value={remindType}
                            onChange={(e) => setRemindType(e.target.value)}
                            required
                            // className='input-field'
                            style={inputStyle}
                        >
                            <option value="">Select Type</option>
                            <option value="hour">Hour</option>
                            <option value="day">Day</option>
                        </select>
                    </div>
                </>
            )}
            <div style={modalActionsStyle}>
                {/* <button type="button" onClick={resetFields}>
                    Cancel
                </button> */}
                <button type="submit" className='color-button'>Save</button>
            </div>
        </form>
    );
};




export default AddFollowUpForm;


const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    backgroundColor: '#ffffff',
    padding: '20px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    maxWidth: '50%',
    margin: 'auto',
    marginTop: '20px',
};


const labelStyle = {
    fontWeight: 'bold',
    marginBottom: '5px',
};


const inputStyle = {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
};


const sendReminderStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
};


const modalActionsStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
};
















