import React, { useEffect, useState } from 'react';
import { Box, Modal } from '@mui/material';
import Swal from 'sweetalert2';
import iconImage from '../assets/Icon.png';
import './../Screens/css/Designation.css';


const DealFile = ({ id }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dealFile, setDealFile] = useState(null);
    const [description, setDescription] = useState('');
    const [fileList, setFileList] = useState([]); // State to store the list of files fetched from the API


    // Function to fetch existing files
    const fetchFiles = async () => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            console.error('No auth token found.');
            return;
        }




        const url = `https://api.myntask.com/leads/file_list/${id}`;




        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });




            if (!response.ok) {
                throw new Error('Failed to fetch files');
            }




            const data = await response.json();
            setFileList(data);
        } catch (error) {




        }
    };


    // Fetch files when the component mounts
    useEffect(() => {
        fetchFiles();
    }, [id]);


    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setDealFile(null);
        setDescription('');
    };


    const handleFileChange = (event) => {
        setDealFile(event.target.files[0]);
    };


    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };


    const handleSubmit = async () => {
        if (!dealFile || !description) {
            Swal.fire({
                icon: 'error',
                title: 'Missing Fields',
                text: 'All fields are required.',
                toast: true,
                position: 'top-end',
                timer: 1500,
                showConfirmButton: false,
            });
            return;
        }


        const token = localStorage.getItem('authToken');
        if (!token) {
            Swal.fire({
                icon: 'error',
                title: 'Authentication Error',
                text: 'No token found. Please log in.',
                toast: true,
                position: 'top-end',
                timer: 1500,
                showConfirmButton: false,
            });
            return;
        }


        const formData = new FormData();
        formData.append('deal_file', dealFile);
        formData.append('deal_id', id);
        formData.append('description', description);


        try {
            const response = await fetch('https://api.myntask.com/leads/file_upload', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });


            if (!response.ok) {
                throw new Error(`Failed to upload file. Status code: ${response.status}`);
            }


            fetchFiles();

            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'File uploaded successfully.',
                toast: true,
                position: 'top-end',
                timer: 1500,
                showConfirmButton: false,
            });

            handleCloseModal(); // Close modal on success
        } catch (error) {
            console.error('Error uploading file:', error);
            Swal.fire({
                icon: 'error',
                title: 'Upload Failed',
                text: 'Error uploading file. Please try again.',
                toast: true,
                position: 'top-end',
                timer: 1500,
                showConfirmButton: false,
            });
        }
    };


    const handleDeleteFile = async (fileId) => {
        const token = localStorage.getItem('authToken');


        try {
            const willDelete = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            });


            if (!willDelete.isConfirmed) {
                return; // User canceled the deletion
            }


            const response = await fetch(`https://api.myntask.com/leads/file_delete/${fileId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });


            if (!response.ok) {
                throw new Error('Failed to delete file');
            }


            fetchFiles(); // Refresh the file list


            Swal.fire({
                title: 'Deleted!',
                text: 'File deleted successfully!',
                icon: 'success',
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1500,
            });
        } catch (error) {
            console.error('Error deleting file:', error);


            Swal.fire({
                title: 'Error',
                text: 'Error deleting file. Please try again.',
                icon: 'error',
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    };




    return (
        <Box padding={4}>
            <div className="top-controls">
                <h1 className="title">Files</h1>
                <div className="buttons-row">
                    <button className="color-button" onClick={handleOpenModal}>
                        Upload Files
                    </button>
                </div>
            </div>


            {/* File List Table */}
            <table className="file-table">
                <thead>
                    <tr>
                        <th>File Name</th>
                        <th>Description</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {fileList.map((file) => (
                        <tr key={file.id}>
                            <td>
                                <a href={file.file} target="_blank" rel="noopener noreferrer">
                                    {file.filename}
                                </a>
                            </td>
                            <td>{file.description}</td>
                            <td>
                                <span onClick={() => handleDeleteFile(file.id)} style={{ cursor: 'pointer', color: 'red' }}>
                                    Delete
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>


            {/* Modal for file upload */}
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="add-document-modal"
                aria-describedby="modal-for-adding-document"
            >
                <Box className="designation-modal">
                    <div className="designation-header">
                        <h1 className="designation-title">Add Document</h1>
                        <img
                            src={iconImage} // Replace 'iconImage' with the correct image path or import
                            alt="Close"
                            className="designation-close"
                            onClick={handleCloseModal}
                        />
                    </div>


                    <div className="designation-form">
                        <label className="designation-label">Description:</label>
                        <input
                            type="text"
                            id="description"
                            name="description"
                            className="designation-input"
                            value={description}
                            onChange={handleDescriptionChange}
                            placeholder="Enter description"
                            required
                        />


                        <label className="designation-label">Upload File:</label>
                        <input
                            type="file"
                            id="file"
                            name="deal_file"
                            className="designation-input"
                            onChange={handleFileChange}
                            required
                        />
                    </div>


                    <button onClick={handleSubmit} className="designation-save">
                        Save
                    </button>
                </Box>
            </Modal>
        </Box>
    );
};


export default DealFile;






